import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { asyncGetSettings } from 'store';

export const PartnerRegisterFaq = () => {
  const [contractText, setContractText] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [language, setLanguage] = useState('');

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const getSettings = async () => {
    const settings = await dispatch(asyncGetSettings()).unwrap();

    setContractText({
      CZ: settings.textContractCZ,
      RU: settings.textContractRU,
      US: settings.textContractUS
    });
    setPersonalInfo({
      CZ: settings.personalInfoCZ,
      RU: settings.personalInfoRU,
      US: settings.personalInfoUS
    });
    setLanguage(settings.defaultLang);
  };

  useEffect(() => {
    getSettings();
  }, []);

  if (!contractText) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 p-2">
        <p className="text-xl font-bold">{t('contract info')}</p>
        <div dangerouslySetInnerHTML={{ __html: contractText[language] }} />
      </div>
      <div className="flex flex-col gap-2 p-2">
        <p className="text-xl font-bold">{t('personal info')}</p>
        <div dangerouslySetInnerHTML={{ __html: personalInfo[language] }} />
      </div>
    </div>
  );
};
