import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import { AppRoutes } from 'constants/index.js';
import { getPaymentMethods } from 'constants/payment-methods.js';

import { ordersApi } from 'api';

import { downloadFile, downloadXmlFile, getOrderClientRoute } from 'utils';

import {
  asyncApprovePayment,
  asyncDownloadFacture,
  asyncDownloadSampleOrder,
  asyncGetPartner,
  asyncGetPopulatedOrder,
  asyncGetSettings,
  asyncOrderPayLater,
  asyncOrderRetryPayment,
  asyncSendPaymentDetails,
  asyncSendValidateClient,
  asyncUpdateOrder,
  asyncUpdatePaymentType,
  selectFileIsDownloading,
  selectIsUpdatedPayment,
  selectOrder,
  selectOrderIsLoading,
  selectPartner,
  selectSendDetailsIsLoading,
  selectUser,
  selectValidations
} from 'store';
import { asyncGetOrder } from 'store';
import { unsetOrder } from 'store';

import { useModal } from 'hooks';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';

import {
  Button,
  ButtonVariants,
  GoBack,
  Link,
  LinkVariants,
  Table,
  TooltipTable
} from 'components';

import { YesNoModal } from 'modules';

import AttentionSrc from 'svg/attention.svg';
import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';
import LoadingSrc from 'svg/loading.svg';

import ViewSrc from 'png/view.png';

const currency = 'CZK';

const downloadRoles = ['admin', 'cashier', 'partner-agent'];

export const OrderPayment = () => {
  const [clientIndex, setClientIndex] = useState(-1);

  const { isModalOpened, handleOnCloseModal, handleOnOpenModal } = useModal();

  const dispatch = useDispatch();

  const order = useSelector(selectOrder);
  const user = useSelector(selectUser);

  const isOrderSynced =
    order?.isSync !== null && order?.isSync !== undefined && !order?.isSync;
  const isCorrectStatus =
    order?.status === 'axa-pay-error' ||
    order?.status === 'bad-axa-connection' ||
    order?.status === 'axa-id-exists' ||
    order?.status === 'negotiation-number-error';

  const isPaymentSelectedForMediator =
    (order?.status === 'unpaid' || order?.status === 'pay-later') &&
    order?.partner?._id !== user?._id;

  const isUpdatedPayment = useSelector(selectIsUpdatedPayment);
  const isDownloading = useSelector(selectFileIsDownloading);
  const isLoading = useSelector(selectOrderIsLoading);
  const isSendDetailsLoading = useSelector(selectSendDetailsIsLoading);

  const partner = useSelector(selectPartner);

  const validations = useSelector(selectValidations);

  const isInvalid = validations?.some(
    (el) => Object.keys(el || {}).length !== 0
  );

  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isFirstClientHasEmail = !!(order?.clients?.[0]?.isSamePersons
    ? order?.clients?.[0]?.insurer.email
    : order?.clients?.[0]?.insured.email);

  const payByStripe = async () => {
    const { data } = await ordersApi.payByStripe(id);
    const stripe = await loadStripe(
      `${import.meta.env.VITE_STRIPE_PUBLISHABLE}`
    );

    await stripe.redirectToCheckout({
      sessionId: data.session.id
    });
  };

  const updatePaymentType = (paymentType) => async () => {
    const isInvalid = validations.some(
      (el) => Object.keys(el || {}).length !== 0
    );

    if (!isInvalid && order?.clients?.length) {
      if (paymentType === 'stripe') {
        await payByStripe();
      } else {
        await dispatch(
          asyncUpdatePaymentType({
            id,
            payload: { paymentType, mediator: order?.mediatorFather }
          })
        )
          .unwrap()
          .finally(() => {
            dispatch(asyncGetOrder(id));
          });

        navigate(`/orders/payment-process/${id}`);
      }
    }
  };

  const approvePayment = async () => {
    dispatch(asyncApprovePayment(id));
  };

  const deleteClient = async () => {
    await dispatch(
      asyncUpdateOrder({
        id,
        payload: {
          clients: order.clients
            .filter((_, idx) => idx !== clientIndex)
            .map((el) => {
              let { insurance } = el;

              return {
                ...el,
                insurance: {
                  ...(insurance.company && { company: insurance.company._id }),
                  ...(insurance.product && { company: insurance.product._id }),
                  ...insurance
                }
              };
            })
        }
      })
    );

    await dispatch(asyncGetPopulatedOrder(id));
    handleOnCloseModal();
  };

  const ClientActions = ({ index }) => {
    const handleDeleteClient = () => {
      setClientIndex(index);
      handleOnOpenModal();
    };

    const handleEditClient = () => {
      sessionStorage.setItem('clientIndex', index);
      navigate(`/orders/${id}`);
    };

    return (
      <div className="flex flex-row gap-2 items-center">
        <Link href={getOrderClientRoute(id, index)}>
          <img className="cursor-pointer w-[20px]" src={ViewSrc} alt="view" />
        </Link>
        {user?.role === 'partner-agent' ||
          (user.role === 'partner-member' && order.status === 'draft' && (
            <>
              <Button
                onClick={handleEditClient}
                variant={ButtonVariants.CLEAR}
                className="!px-0">
                <img className="cursor-pointer" src={EditSrc} alt="edit" />
              </Button>
              <img
                onClick={handleDeleteClient}
                className="cursor-pointer"
                src={DeleteSrc}
                alt="delete"
              />
            </>
          ))}
      </div>
    );
  };

  const cfg = () => [
    {
      valueFunc: (_, el) => {
        const field =
          el?.isSamePersons && !el?.insurer?.isLegalEntity
            ? 'insurer'
            : 'insured';
        const { firstName, lastName } = el?.[field] || {};

        return `${firstName || ''} ${lastName || ''}`;
      },
      key: 'name',
      header: t('name'),
      cellClassName: (idx) =>
        Object.keys(validations[idx] || {}).length !== 0
          ? 'border-l-[4px] border-l-error'
          : 'border-l-[4px] border-l-green-1'
    },
    {
      valueFunc: (_, el) => {
        const field =
          el?.isSamePersons && !el?.insurer?.isLegalEntity
            ? 'insurer'
            : 'insured';
        const { phone } = el?.[field] || {};

        return phone;
      },
      key: 'phone',
      header: t('phone')
    },
    {
      valueFunc: (_, el) => el?.insured?.contractId || t('n/a'),
      key: 'axa-contract',
      header: t('axa contract')
    },
    {
      valueFunc: (_, el) => `${el?.prices?.withDiscount} CZK`,
      key: 'price',
      header: t('price')
    },
    {
      header: t('actions'),
      component: ClientActions,
      propKeys: ['index']
    }
  ];

  const isPaymentFailed = searchParams.get('canceled') === 'true';

  const handleDownloadFacture = async () => {
    const data = await dispatch(asyncDownloadFacture(order.facture));

    downloadFile({
      data: data.payload || data,
      fileName: `faktura-${id}.xlsx`,
      fileType: 'application/vnd.ms-excel'
    });
  };

  const handleDownloadSampleOrder = async () => {
    const data = await dispatch(asyncDownloadSampleOrder(id));

    downloadFile({
      data: data.payload || data,
      fileName: `vzorová-objednávka-${id}.xlsx`,
      fileType: 'application/vnd.ms-excel'
    });
  };

  const onPayLater = () => {
    dispatch(
      asyncOrderPayLater({
        id,
        ...(order?.mediatorFather ? { mediator: order?.mediatorFather } : {})
      })
    )
      .unwrap()
      .then(() => {
        toast.success(t('order - status - pay-later'));
        navigate(AppRoutes.ORDERS);
      })
      .finally(() => {
        dispatch(asyncGetOrder(id));
      });
  };

  const onSendPaymentDetails = () => {
    dispatch(asyncSendPaymentDetails(id));
  };

  const handleRetryPayment = () => {
    dispatch(
      asyncOrderRetryPayment({
        id,
        ...(order?.mediatorFather ? { mediator: order?.mediatorFather } : {})
      })
    )
      .unwrap()
      .then(() => dispatch(asyncGetPopulatedOrder(id)));
  };

  const handleDownloadXml = () => {
    if (order?.axaXML) downloadXmlFile(order.axaXML, 'AXA.xml');
  };

  useEffect(() => {
    if (
      isOrderSynced &&
      (user?.role === 'partner-agent' || user?.role === 'partner-member')
    ) {
      navigate(AppRoutes.ORDERS);
    }
  }, [order]);

  useEffect(() => {
    if (user) {
      dispatch(asyncGetPopulatedOrder(id));
      dispatch(asyncGetSettings());
      dispatch(asyncSendValidateClient({ orderId: id }));
      dispatch(asyncGetPartner(user._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (isUpdatedPayment) {
      navigate(`/orders/payment-process/${id}`);
    }
  }, [isUpdatedPayment]);

  useBeforeRouteChange(() => {
    dispatch(unsetOrder());
  });

  if (!order || !validations) return null;
  return (
    <div className="flex flex-col gap-2">
      {isPaymentFailed && (
        <p className="text-error">{t('stripe - order payment - failed')}</p>
      )}
      <div className="flex gap-2 items-center">
        {t(`order #`, { id: order?.id || order?._id })}
        <span data-tooltip-id={order.id} className="text-red-500">
          {(isOrderSynced || isCorrectStatus) && (
            <img className="w-4 h-4" src={AttentionSrc} alt=" (!)" />
          )}
        </span>
        <Tooltip place="right" className="z-30" id={order?.id}>
          {t(!order?.isSync ? 'axa-sync-error' : order?.status)}
        </Tooltip>
      </div>
      <div className="flex flex-col gap-2">
        {order?.clients && (
          <Table
            items={order.clients}
            config={cfg()}
            tooltipChildren={(selectedIndex) => {
              if (
                validations[selectedIndex] &&
                Object.keys(validations[selectedIndex]).length > 1
              )
                return <TooltipTable item={validations[selectedIndex]} />;
            }}
          />
        )}
        <div className="text-grey-1 mt-8 text-md flex flex-row justify-between gap-4">
          <span>
            {t('full price:', { price: order?.prices?.full, currency })}{' '}
          </span>
          <span>
            {t('discount price:', {
              price: order?.prices?.withDiscount,
              currency
            })}
          </span>
          <span>
            {t('comission price:', {
              price: order?.prices?.comission,
              currency
            })}
          </span>
        </div>
        <div>
          <p>
            {t('payment type')}:{' '}
            {t(`order - payment method - ${order.paymentType || '_'}`)}
          </p>
          <p>
            {t('payment status')}: {t(`order - status - ${order.status}`)}
          </p>
          <p>
            {t(`for payment`, {
              price:
                (order?.prices?.full ?? 0) -
                (order.paymentType === 'office' ||
                order.paymentType === 'deposit'
                  ? (order?.prices?.comission ?? 0)
                  : 0),
              currency
            })}
          </p>
        </div>
      </div>
      {!order.paymentType &&
        (order?.isSync !== undefined || order?.isSync) &&
        (order.status === 'draft' ||
          order.status === 'pay-later' ||
          order.status === 'axa-pay-error' ||
          order?.status === 'bad-axa-connection' ||
          order?.status === 'negotiation-number-error') &&
        !isInvalid &&
        order?.prices?.full > 0 &&
        (user?.role === 'partner-agent' || user?.role === 'partner-member') && (
          <div className="flex flex-col gap-2">
            <p>{t('payment info')}</p>
            <div className="flex gap-2 flex-wrap">
              {getPaymentMethods(order, partner, updatePaymentType).map(
                ({ condition, label, onClick }) =>
                  condition && (
                    <Button key={label} onClick={onClick}>
                      {label}
                    </Button>
                  )
              )}
              {(order?.mediatorFather
                ? order?.mediatorFather?.isTrustedPartner
                : user?.isTrustedPartner) &&
                order.status !== 'pay-later' && (
                  <Button
                    disabled={isLoading}
                    onClick={onPayLater}
                    className="relative">
                    {isLoading && (
                      <div className="absolute inset-0 bg-green-1 flex items-center justify-center">
                        <img
                          src={LoadingSrc}
                          className="w-6 h-6"
                          alt={t('loading')}
                        />
                      </div>
                    )}
                    {t('order - payment method - pay - later')}
                  </Button>
                )}
            </div>
          </div>
        )}
      <div className="flex justify-between">
        <div className="flex flex-row gap-4 flex-1 flex-wrap">
          <GoBack />
          {user?.role?.includes('partner') &&
            order?.paymentType &&
            order.status !== 'paid' && (
              <Link
                variant={LinkVariants.SECONDARY}
                href={`/orders/payment-process/${id}`}>
                {t('payment details')}
              </Link>
            )}
          {user?.role === 'cashier' &&
            order?.paymentType &&
            (order.status === 'unpaid' ||
              order.status === 'pay-later' ||
              order?.status === 'bad-axa-connection' ||
              order.status === 'axa-pay-error') && (
              <Button
                className="relative"
                onClick={approvePayment}
                disabled={isDownloading}>
                {isDownloading && (
                  <div className="absolute inset-0 bg-green-1 flex items-center justify-center">
                    <img
                      src={LoadingSrc}
                      className="w-6 h-6"
                      alt={t('loading')}
                    />
                  </div>
                )}
                {t('approve payment')}
              </Button>
            )}
          {order.status === 'paid' && order.facture && (
            <Button onClick={handleDownloadFacture}>
              {t('download facture')}
            </Button>
          )}
          {order.status === 'paid' && downloadRoles.includes(user?.role) && (
            <Button onClick={handleDownloadSampleOrder}>
              {t('download sample order')}
            </Button>
          )}
          {order.status === 'paid' &&
            user?.role === 'admin' &&
            order?.axaXML && (
              <Button onClick={handleDownloadXml}>
                {t('download axa xml')}
              </Button>
            )}
          {order.status === 'axa-id-exists' &&
            (user?.role.includes('partner') || user?.role === 'cashier') && (
              <Button
                onClick={handleRetryPayment}
                disabled={isLoading}
                className="relative">
                {isLoading && (
                  <div className="absolute inset-0 bg-green-1 flex items-center justify-center">
                    <img
                      src={LoadingSrc}
                      className="w-6 h-6"
                      alt={t('loading')}
                    />
                  </div>
                )}
                {t('retry payment')}
              </Button>
            )}
        </div>
        {order?.clients?.length === 1 &&
          isFirstClientHasEmail &&
          user?.role?.includes('partner') &&
          order?.status === 'draft' && (
            <Button
              isLoading={isSendDetailsLoading}
              loaderClassName="!bg-blue-2"
              onClick={onSendPaymentDetails}
              className="self-end transition-all !bg-blue-2 border-none hover:!bg-blue-1 hover:text-white whitespace-nowrap">
              {t('send payment details')}
            </Button>
          )}
      </div>

      <YesNoModal
        isOpen={isModalOpened}
        onApprove={deleteClient}
        question={t('delete client')}
        approveLabel={t('yes')}
        declineLabel={t('no')}
        handleClose={handleOnCloseModal}
        onDecline={handleOnCloseModal}
      />
    </div>
  );
};
