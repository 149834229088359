import cs from 'classnames';
import { useTranslation } from 'react-i18next';

export const TooltipTable = ({ item = {}, className }) => {
  const { t } = useTranslation();

  let errorHeaders = Object.keys(item);
  let errorNames = Object.keys(item).map((key) =>
    Object.keys(item[key]).map((val) => val)
  );
  const errorMessages = Object.keys(item).map((key, itemIndex) =>
    Object.values(item[key]).map((message) => {
      if (typeof message === 'object') {
        if (Object.keys(message).length) {
          return Object.values(message);
        } else {
          errorNames[itemIndex] = errorNames[itemIndex].filter(
            (name) => name !== 'correspondent'
          );
          return [];
        }
      }
      return message;
    })
  );

  return (
    <div className="flex gap-8 border-amber-50">
      {!errorHeaders.length ? (
        <p>{t('missing items')}</p>
      ) : (
        errorHeaders.map((errorHeader, tableIndex) =>
          !errorNames[tableIndex].length ? null : (
            <div className="flex flex-col gap-2" key={errorHeader}>
              <span className="text-center flex items-center justify-center">
                {t(errorHeader)}
              </span>
              <table className={cs('table-auto', className)}>
                <tbody>
                  {errorNames[tableIndex].map((errorName, index) => {
                    const message = errorMessages[tableIndex][index];

                    if (Array.isArray(message) && !message.length) {
                      return null;
                    }

                    return (
                      <tr className="text-xs " key={index}>
                        <td className="pr-4">{t(errorName)}</td>
                        <td>
                          {Array.isArray(message)
                            ? message.map((item) => t(item))
                            : t(message)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        )
      )}
    </div>
  );
};
