import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { cz, ru, us } from './translations/';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      caches: ['localStorage']
    },
    resources: {
      'ru-RU': { translations: ru },
      'en-US': { translations: us },
      US: { translations: us },
      RU: { translations: ru },
      CZ: { translations: cz }
    },
    fallbackLng: 'en-US',
    debug: false,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
