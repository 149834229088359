import { createSelector } from '@reduxjs/toolkit';

const selectPartnersState = (state) => state.partners;

export const selectPartners = createSelector(
  selectPartnersState,
  (state) => state.partners
);
export const selectPartner = createSelector(
  selectPartnersState,
  (state) => state.partner
);
export const selectPartnersCount = createSelector(
  selectPartnersState,
  (state) => state.partnersCount
);
export const selectPartnerProducts = createSelector(
  selectPartnersState,
  (state) => state.products
);

export const selectPartnerCompanies = createSelector(
  selectPartnersState,
  (state) => state.companies
);
export const selectPartnersVouchers = createSelector(
  selectPartnersState,
  (state) => state.vouchers
);

export const selectPartnersIsLoading = createSelector(
  selectPartnersState,
  (state) => state.isLoading
);
