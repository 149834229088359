import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { companiesApi } from 'api';

import i18n from 'i18n';

export const asyncGetCompanies = createAsyncThunk(
  `${SliceNames.COMPANIES}/GET_COMPANIES`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await companiesApi.getCompanies(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetCompany = createAsyncThunk(
  `${SliceNames.COMPANIES}/GET_COMPANY`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await companiesApi.getCompany(payload);
      return data.company;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateCompany = createAsyncThunk(
  `${SliceNames.COMPANIES}/CREATE_COMPANY`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await companiesApi.createCompany(payload);
      toast.success(i18n.t('created successfully'));
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateCompany = createAsyncThunk(
  `${SliceNames.COMPANIES}/UPDATE_COMPANY`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await companiesApi.updateCompany(id, payload);
      toast.success(i18n.t('updated successfully'));
      return data.updated;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteCompanyPdf = createAsyncThunk(
  `${SliceNames.COMPANIES}/UPDATE_COMPANY_PDF`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await companiesApi.deleteCompanyPdf(id, payload);
      toast.success(i18n.t('updated successfully'));
      return data.company;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteCompany = createAsyncThunk(
  `${SliceNames.COMPANIES}/DELETE_COMPANY`,
  async (payload, { rejectWithValue }) => {
    try {
      await companiesApi.deleteCompany(payload);
      toast.success(i18n.t('deleted successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);
