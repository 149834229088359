import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  approvePaymentReducer,
  calculateClientReducer,
  cancelClientReducer,
  createOrderReducer,
  downloadInvoiceDraft,
  downloadPaymentProtocol,
  downloadSampleOrderReducer,
  getGovUserReducer,
  getOrderPricesReducer,
  getOrderReducer,
  getOrderXMLReducer,
  getOrdersReducer,
  getPopulatedOrderReducer,
  orderPayLaterReducer,
  orderRetryPaymentReducer,
  sendPaymentDetailsReducer,
  unsetGovUserReducer,
  unsetIsCancelSendReducer,
  unsetOrderReducer,
  updateOrderReducer,
  updateOrderVoucherReducer,
  updatePaymentTypeReducer,
  validateClientReducer
} from './reducers.js';

const initialState = {
  isLoading: null
};

const slice = createSlice({
  name: SliceNames.ORDERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getOrdersReducer(builder);
    getOrderReducer(builder);
    getPopulatedOrderReducer(builder);
    approvePaymentReducer(builder);
    downloadInvoiceDraft(builder);
    downloadPaymentProtocol(builder);
    downloadSampleOrderReducer(builder);
    updateOrderVoucherReducer(builder);
    createOrderReducer(builder);
    calculateClientReducer(builder);
    validateClientReducer(builder);
    cancelClientReducer(builder);
    updatePaymentTypeReducer(builder);
    updateOrderReducer(builder);
    unsetIsCancelSendReducer(builder);
    orderPayLaterReducer(builder);
    getGovUserReducer(builder);
    unsetGovUserReducer(builder);
    getOrderPricesReducer(builder);
    unsetOrderReducer(builder);
    orderRetryPaymentReducer(builder);
    sendPaymentDetailsReducer(builder);
    getOrderXMLReducer(builder);
  }
});

export const ordersReducer = slice.reducer;
