import { api } from 'config';

class NewsApi {
  constructor(url) {
    this.url = url;
  }

  getNews(queryParams, config) {
    return api.get(
      `${this.url}/list/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&status=${queryParams?.status ?? ''}&author=${
        queryParams?.author ?? ''
      }`,
      config
    );
  }

  getNewsItem(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  createNews(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  updateNews(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  deleteNews(id, config) {
    return api.delete(`${this.url}/${id}`, config);
  }
}

export const newsApi = new NewsApi('/news');
