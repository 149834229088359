import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  asyncGetUsers,
  selectUsers,
  selectUsersCount,
  selectUsersIsLoading
} from 'store';

import { usePagination, useQueryParams, useSearch } from 'hooks';

import { Input, Link, LinkVariants, Pagination } from 'components';

import { PartnersList } from 'modules';

export const RolesList = ({ createRoute, roles, getCustomRoute }) => {
  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const list = useSelector(selectUsers);
  const isLoading = useSelector(selectUsersIsLoading);
  const count = useSelector(selectUsersCount);

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      dispatch(asyncGetUsers({ page, search, roles }));
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useQueryParams({ page: page + 1, search });

  useEffect(() => {
    dispatch(asyncGetUsers({ page, search, roles }));
  }, [page]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        <div className="flex flex-1 justify-end gap-2">
          <Link href={createRoute} variant={LinkVariants.SECONDARY}>
            {t('create user')}
          </Link>
        </div>
      </div>
      {list?.length ? (
        <>
          <PartnersList
            list={list}
            getCustomRoute={getCustomRoute}
            isLoading={isLoading}
          />
          <Pagination
            onPageChange={handlePageClick}
            count={count}
            page={page}
          />
        </>
      ) : (
        <p className="flex items-center justify-center">{t('missing items')}</p>
      )}
    </div>
  );
};
