import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { EMAIL_OPTIONS, PHONE_OPTIONS } from 'constants/index.js';

import { maxLen, phone } from 'normalizers';

import {
  asyncCreateUser,
  asyncGetUser,
  asyncUpdateUser,
  selectRoleUser
} from 'store';

import { Button, Input, TextArea } from 'components';

export const RoleForm = ({ id, role }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const roleUser = useSelector(selectRoleUser);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: async () => {
      const fields = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        comment: ''
      };

      if (id) {
        const user = await dispatch(asyncGetUser(id)).unwrap();

        Object.keys(fields).forEach((key) => {
          if (user[key]) fields[key] = user[key];
        });
      }

      return fields;
    }
  });

  const onSubmit = async (data) => {
    const result = await dispatch(
      id
        ? asyncUpdateUser({ id, payload: data })
        : asyncCreateUser({ role, ...data })
    ).unwrap();

    if (result && id) {
      toast.success(t('succesfully updated'), { toastId: 'update' });
      navigate(-1);
    }
  };

  const handleChangeStatus = async () => {
    dispatch(
      asyncUpdateUser({
        id,
        payload: {
          status: roleUser?.status === 'active' ? 'stopped' : 'active'
        }
      })
    );

    toast.success(
      t(
        roleUser?.status === 'active'
          ? 'freezed successfully'
          : 'activated successfully'
      )
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-2 gap-y-4 gap-x-16">
      <Input
        normalize={[phone, maxLen(15)]}
        required
        error={errors['phone']?.message}
        label={t('phone')}
        {...register('phone', PHONE_OPTIONS)}
      />
      <Input
        required
        error={errors['email']?.message}
        label={t('email')}
        {...register('email', EMAIL_OPTIONS)}
      />
      <Input
        required
        error={errors['firstName']?.message}
        label={t('first name')}
        {...register('firstName', { required: t('error - required') })}
      />
      <Input
        required
        error={errors['lastName']?.message}
        label={t('last name')}
        {...register('lastName', { required: t('error - required') })}
      />
      <TextArea
        wrapperClassname="col-span-full"
        rows={8}
        name="comment"
        label={t('comment')}
        error={errors['comment']?.message}
        {...register('comment')}
      />
      <div className="flex flex-row gap-4">
        <Button>{t('save')}</Button>
        {id && (
          <Button type="button" onClick={handleChangeStatus}>
            {t(roleUser?.status === 'active' ? 'stop' : 'activate')}
          </Button>
        )}
      </div>
    </form>
  );
};
