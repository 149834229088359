import { Log } from 'pages/Log.jsx';
import { Logs } from 'pages/Logs.jsx';
import { PaymentQR } from 'pages/PaymentQR.jsx';
import { SuccessfulPayment } from 'pages/SuccessfulPayment.jsx';

import { AppRoutes } from 'constants/index.js';

import {
  Cashiers,
  Clients,
  Comission,
  Comissions,
  Companies,
  Company,
  ForgotPassword,
  Home,
  Login,
  News,
  NewsCreate,
  NewsItem,
  Order,
  OrderClientInfo,
  OrderPaid,
  OrderPayment,
  OrderProcess,
  Orders,
  Partner,
  PartnerFaq,
  PartnerInfo,
  PartnerRegister,
  Partners,
  Product,
  Products,
  Profile,
  Reports,
  ResetPassword,
  SalesManager,
  SalesManagers,
  Settings,
  Voucher,
  Vouchers
} from 'pages';

import { Cashier } from './pages/Cashier.jsx';

export const PROTECTED_ROUTES = [
  { path: AppRoutes.HOME, element: Home, breadcrumb: 'home' },
  {
    path: AppRoutes.ORDERS,
    element: Orders,
    breadcrumb: 'orders'
  },
  {
    onClick: (e) => {
      e.preventDefault();
      const orderId = sessionStorage.getItem('orderId');

      return orderId && orderId !== 'null'
        ? `/orders/${orderId}`
        : '/orders/create';
    },
    path: AppRoutes.ORDER_CREATE,
    element: Order,
    sidebar: false,
    breadcrumb: 'new order',
    roles: ['partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.ORDER,
    element: Order,
    sidebar: false,
    breadcrumb: 'edit',
    roles: ['partner-member', 'partner-agent', 'admin']
  },
  {
    path: AppRoutes.ORDER_CLIENT,
    element: OrderClientInfo,
    sidebar: false,
    breadcrumb: 'client info'
  },
  {
    path: AppRoutes.ORDER_PAYMENT,
    element: OrderPayment,
    sidebar: false,
    breadcrumb: 'payment'
  },
  {
    path: AppRoutes.ORDER_PROCESS,
    element: OrderProcess,
    sidebar: false,
    breadcrumb: 'payment',
    roles: ['partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.ORDER_PAID,
    element: OrderPaid,
    sidebar: false,
    breadcrumb: 'payment',
    roles: ['partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.NEWS,
    element: News,
    breadcrumb: 'news',
    roles: ['admin', 'sales-manager', 'partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.NEWS_CREATE,
    sidebar: false,
    element: NewsCreate,
    breadcrumb: 'create',
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.NEWS_EDIT,
    sidebar: false,
    element: NewsCreate,
    breadcrumb: 'edit',
    roles: ['admin', 'sales-manager']
  },
  {
    sidebar: false,
    path: AppRoutes.NEWS_ITEM,
    element: NewsItem,
    breadcrumb: 'news item',
    roles: ['admin', 'sales-manager', 'partner-member', 'partner-agent']
  },

  { path: AppRoutes.PROFILE, element: Profile, breadcrumb: 'profile' },
  {
    path: AppRoutes.VOUCHERS,
    element: Vouchers,
    breadcrumb: 'vouchers',
    roles: ['admin', 'sales-manager', 'partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.VOUCHER_CREATE,
    element: Voucher,
    breadcrumb: 'create',
    sidebar: false,
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.VOUCHER,
    element: Voucher,
    breadcrumb: 'edit',
    sidebar: false,
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.COMMISSIONS,
    element: Comissions,
    breadcrumb: 'comissions'
  },
  {
    path: AppRoutes.COMMISSION,
    element: Comission,
    breadcrumb: 'comission',
    sidebar: false
  },
  {
    path: AppRoutes.REPORTS,
    element: Reports,
    breadcrumb: 'reports',
    roles: ['admin']
  },
  {
    path: AppRoutes.CLIENTS,
    element: Clients,
    breadcrumb: 'clients',
    roles: ['admin', 'partner-member', 'partner-agent']
  },
  {
    path: AppRoutes.PARTNERS,
    element: Partners,
    breadcrumb: 'partners',
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.PARTNER_CREATE,
    element: Partner,
    breadcrumb: 'create',
    sidebar: false,
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.PARTNER,
    element: Partner,
    breadcrumb: 'edit',
    sidebar: false,
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.COMPANIES,
    element: Companies,
    breadcrumb: 'companies',
    roles: ['admin', 'sales-manager']
  },
  {
    path: AppRoutes.COMPANY_CREATE,
    element: Company,
    breadcrumb: 'create',
    roles: ['admin', 'sales-manager'],
    sidebar: false
  },
  {
    path: AppRoutes.COMPANY,
    element: Company,
    breadcrumb: 'edit',
    roles: ['admin', 'sales-manager'],
    sidebar: false
  },

  {
    path: AppRoutes.PRODUCTS,
    element: Products,
    breadcrumb: 'products',
    roles: ['admin']
  },
  {
    path: AppRoutes.PRODUCT_CREATE,
    element: Product,
    sidebar: false,
    breadcrumb: 'create',
    roles: ['admin']
  },
  {
    path: AppRoutes.PRODUCT,
    element: Product,
    sidebar: false,
    breadcrumb: 'edit',
    roles: ['admin']
  },
  {
    path: AppRoutes.CASHIERS,
    element: Cashiers,
    breadcrumb: 'cashiers',
    roles: ['admin']
  },
  {
    path: AppRoutes.CASHIER,
    element: Cashier,
    breadcrumb: 'cashier',
    sidebar: false,
    roles: ['admin']
  },
  {
    path: AppRoutes.LOGS,
    element: Logs,
    breadcrumb: 'logs',
    roles: ['admin']
  },
  {
    path: AppRoutes.LOG,
    element: Log,
    sidebar: false,
    roles: ['admin']
  },
  {
    path: AppRoutes.CASHIER_CREATE,
    element: Cashier,
    breadcrumb: 'cashier',
    sidebar: false,
    roles: ['admin']
  },
  {
    path: AppRoutes.SALES_MANAGERS,
    element: SalesManagers,
    breadcrumb: 'sales-managers',
    roles: ['admin']
  },
  {
    path: AppRoutes.SALES_MANAGER,
    element: SalesManager,
    sidebar: false,
    breadcrumb: 'sales-manager',
    roles: ['admin']
  },
  {
    path: AppRoutes.SALES_MANAGERS_CREATE,
    element: SalesManager,
    sidebar: false,
    breadcrumb: 'sales-manager',
    roles: ['admin']
  },
  {
    path: AppRoutes.SETTINGS,
    element: Settings,
    breadcrumb: 'settings',
    roles: ['admin']
  }
];

export const UNPROTECTED_ROUTES = [
  { path: AppRoutes.LOGIN, element: Login },
  { path: AppRoutes.FORGET_PASSWORD, element: ForgotPassword },
  { path: AppRoutes.RESET_PASSWORD, element: ResetPassword },
  { path: AppRoutes.PARTNER_REGISTER, element: PartnerRegister },
  { path: AppRoutes.PRIVACY_POLICY, element: PartnerFaq },
  { path: AppRoutes.PAYMENT_QR, element: PaymentQR },
  { path: AppRoutes.STRIPE_SUCCESSFUL, element: SuccessfulPayment }
];

export const PARTNER_PROTECTED_ROUTES = [
  { path: AppRoutes.PARTNER_INFO, sidebar: false, element: PartnerInfo }
];
