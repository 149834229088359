import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { settingsApi } from 'api';

import i18n from 'i18n';

export const asyncGetSettings = createAsyncThunk(
  `${SliceNames.SETTINGS}/GET_SETTINGS`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingsApi.getSettings();
      return data.settings;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetPaymentTypes = createAsyncThunk(
  `${SliceNames.SETTINGS}/GET_PAYMENT_TYPES`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await settingsApi.getPaymentTypes();
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncSendTestEmail = createAsyncThunk(
  `${SliceNames.SETTINGS}/SEND_TEST_EMAIL`,
  async (payload, { rejectWithValue }) => {
    try {
      await settingsApi.sendTestEmail(payload);
      toast.success(i18n.t('email sent'));
    } catch (error) {
      if (error.library === 'SSL routines')
        toast.error(
          'Something wrong with SMTP configuration. Check port, ssl.'
        );

      if (error.message) toast.error(error.message);
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateSettings = createAsyncThunk(
  `${SliceNames.SETTINGS}/UPDATE_SETTINGS`,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await settingsApi.updateSettings(payload);
      toast.success(i18n.t('succesfully updated'));
      return response.data?.settings;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const unsetSettings = createAction('UNSET_COMMISSION');
