import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import AttentionSrc from 'svg/attention.svg';

export const OrderStatus = ({
  orderId,
  isSync,
  isOrderSynced,
  isCorrectStatus
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-2 items-center">
      {t('order #', { id: orderId ?? '_' })}
      <span className="text-red-500">
        {(isOrderSynced || isCorrectStatus) && (
          <>
            <span data-tooltip-id={orderId} className="text-red-500">
              <img className="w-4 h-4" src={AttentionSrc} alt=" (!)" />
            </span>
            <Tooltip place="right" className="z-40" id={orderId}>
              {t(!isSync ? 'axa-sync-error' : status)}
            </Tooltip>
          </>
        )}
      </span>
    </div>
  );
};
