import { api } from 'config';

class UsersApi {
  constructor(url) {
    this.url = url;
  }

  getUsers(queryParams, config) {
    return api.get(
      `${this.url}/list/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&roles=${
        queryParams?.roles?.length ? queryParams?.roles.join(',') : ''
      }&active=${queryParams?.active ?? ''}`,
      config
    );
  }

  getSelf(config) {
    return api.get(`${this.url}/self`, config);
  }

  updateSelf(payload, config) {
    return api.patch(`${this.url}/`, payload, config);
  }

  getUser(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  updateUser(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  createUser(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  changePassword(payload, config) {
    return api.patch(`${this.url}/change-password`, payload, config);
  }
}

export const usersApi = new UsersApi('/users');
