import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  getLogsItemReducer,
  getLogsReducer,
  unsetLogReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.LOGS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getLogsItemReducer(builder);
    getLogsReducer(builder);
    unsetLogReducer(builder);
  }
});

export const logsReducer = slice.reducer;
