import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  createPartnerReducer,
  getDownloadCsvReducer,
  getPartnerProductsAndCompaniesReducer,
  getPartnerReducer,
  getPartnerVouchersReducer,
  getPartnersReducer,
  unsetIsUpdatedPartnerReducer,
  updatePartnerReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.PARTNERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getPartnersReducer(builder);
    getPartnerReducer(builder);
    getPartnerProductsAndCompaniesReducer(builder);
    getPartnerVouchersReducer(builder);
    getDownloadCsvReducer(builder);
    createPartnerReducer(builder);
    updatePartnerReducer(builder);
    unsetIsUpdatedPartnerReducer(builder);
  }
});

export const partnersReducer = slice.reducer;
