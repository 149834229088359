import axios from 'axios';

export const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL + '/api'
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      if (!window.location.href.includes('login')) {
        window.location.href = '/login';
      }
    }

    if (error?.response?.status === 418) {
      localStorage.clear();
      if (!window.location.href.includes('login')) {
        window.location.href = '/login?logged=true';
      }
    }

    return Promise.reject(error.response.data);
  }
);
