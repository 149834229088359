import { api } from 'config';

class OrdersApi {
  constructor(url) {
    this.url = url;
  }

  getOrders(queryParams, config) {
    return api.get(
      `${this.url}/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&partner=${queryParams?.partner ?? ''}&date=${
        queryParams?.date ?? ''
      }&status=${queryParams?.status ?? ''}`,
      config
    );
  }

  getOrder(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  getOrderPrices(id, config) {
    return api.get(`${this.url}/get-order-prices/${id}`, config);
  }

  getOrderXML({ orderId, sendStartDate, adminPatchData }, config) {
    return api.post(
      `${this.url}/generate-xml/${orderId}`,
      {
        dateStart: sendStartDate,
        adminPatchData: adminPatchData
       },
      {
        ...config,
        responseType: 'blob'
      }
    );
  }

  getPopulatedOrder(id, config) {
    return api.get(`${this.url}/populated/${id}`, config);
  }

  downloadInvoiceDraft(orderId, clientIdx, config) {
    return api.get(
      `${this.url}/download-contract-draft/${orderId}?clientIdx=${clientIdx}`,
      {
        responseType: 'blob',
        ...config
      }
    );
  }

  downloadPaymentProtocol(orderId, clientIdx, config) {
    return api.get(`${this.url}/download-payment-protocol/${orderId}`, {
      responseType: 'blob',
      ...config
    });
  }

  downloadSampleOrder(orderId, config) {
    return api.get(`${this.url}/download-objednavka-vzor/${orderId}`, {
      responseType: 'blob',
      ...config
    });
  }

  updateOrderVoucher(payload, config) {
    return api.patch(`${this.url}/update-voucher`, payload, config);
  }

  createOrder(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  sendCalculateClient(payload, config) {
    return api.post(`${this.url}/calculate-client`, payload, config);
  }

  sendValidateClient(payload, clientIdx = undefined, config) {
    const index = clientIdx ? `?index=${clientIdx}` : '';
    return api.post(
      `${this.url}/validate-clients/${payload.orderId}${index}`,
      payload,
      config
    );
  }

  sendCancelClient(id, payload, config) {
    return api.post(`${this.url}/cancel-client/${id}`, payload, config);
  }

  retryPayment(id, payload, config) {
    return api.post(`${this.url}/retry-payment/${id}`, payload, config);
  }

  updatePaymentType(id, payload, config) {
    return api.patch(`${this.url}/update-payment-type/${id}`, payload, config);
  }

  orderPayLater(id, body, config) {
    return api.post(`${this.url}/pay-later/${id}`, body, config);
  }

  approvePayment(id, payload, config) {
    return api.patch(`${this.url}/approve-payment/${id}`, payload, config);
  }

  sendPaymentDetails(id, payload, config) {
    return api.post(`${this.url}/send-payment-details/${id}`, payload, config);
  }

  updateOrder(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  payByStripe(id, payload, config) {
    return api.patch(`${this.url}/online-payment/${id}`, payload, config);
  }
}

export const ordersApi = new OrdersApi('/orders');
