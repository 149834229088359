import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { partnersApi } from 'api';

import i18n from 'i18n';

export const asyncGetPartners = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_PARTNERS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await partnersApi.getPartners(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetPartner = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_PARTNER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await partnersApi.getPartner(payload);
      return data.partner;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetPartnerProductsCompanies = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_PARTNER_PRODUCTS_AND_COMPANIES`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await partnersApi.getPartnerCompaniesAndProducts();
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetPartnerVouchers = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_PARTNER_VOUCHERS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await partnersApi.getPartnerVouchers(payload);
      return data.vouchers;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetDownloadCsv = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_DOWNLOAD_CSV`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await partnersApi.getDownloadCsv();
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreatePartner = createAsyncThunk(
  `${SliceNames.PARTNERS}/CREATE_PARTNER`,
  async (payload, { rejectWithValue }) => {
    try {
      await partnersApi.createPartner(payload);
      toast.success(i18n.t('created successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdatePartner = createAsyncThunk(
  `${SliceNames.PARTNERS}/UPDATE_PARTNER`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      await partnersApi.updatePartner(id, data);
      toast.success(i18n.t('successfully updated'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const unsetIsUpdatedPartnerAction = createAction(
  `${SliceNames.PARTNERS}/UNSET_IS_UPDATED_PARTNER`
);
