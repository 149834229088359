import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppRoutes } from 'constants/index.js';

import { downloadFile } from 'utils';

import {
  asyncGetDownloadCsv,
  asyncGetPartners,
  selectPartners,
  selectPartnersCount,
  selectPartnersIsLoading
} from 'store';

import { usePagination, useQueryParams, useSearch } from 'hooks';

import { Button, Input, Link, LinkVariants, Pagination } from 'components';

import { PartnersList } from 'modules';

export const Partners = () => {
  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const list = useSelector(selectPartners);
  const isLoading = useSelector(selectPartnersIsLoading);
  const count = useSelector(selectPartnersCount);

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      dispatch(asyncGetPartners({ page, search }));
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleDownload = async () => {
    const data = await dispatch(asyncGetDownloadCsv()).unwrap();

    downloadFile({
      data: data,
      fileName: 'partners.csv',
      fileType: 'text/csv'
    });
  };

  useQueryParams({ page: page + 1, search });

  useEffect(() => {
    dispatch(asyncGetPartners({ page, search }));
  }, [page]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        <div className="flex gap-2">
          <Button onClick={handleDownload}>{t('export partners')}</Button>
          <Link
            href={AppRoutes.PARTNER_CREATE}
            variant={LinkVariants.SECONDARY}>
            {t('create partner')}
          </Link>
        </div>
      </div>
      <PartnersList list={list} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
