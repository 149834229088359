import cn from 'classnames';
import { useEffect, useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

import { ChartCustomLabel } from './ChartCustomLabel.jsx';

export const RoundChart = ({ data, colors, className }) => {
  const [uniqueKey, setUniqueKey] = useState(0);

  useEffect(() => {
    setUniqueKey((prev) => prev + 1);
  }, [colors]);

  return (
    <div className={cn('w-full h-[500px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={1000} height={1000} key={uniqueKey}>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={200}
            labelLine={false}
            label={ChartCustomLabel}
          >
            {data.map((dataItem, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend layout="horizontal" verticalAlign="top" align="center" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
