import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { downloadFile } from 'utils';

import {
  asyncApproveCommissionPayment,
  asyncCancelCommissionPayment,
  asyncDownloadApprovePayment,
  selectCommission,
  selectUser
} from 'store';

import { Button, Radio } from 'components';

export const CommissionPaymentForm = ({ id }) => {
  const user = useSelector(selectUser);
  const role = user?.role;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const comission = useSelector(selectCommission);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: { paymentType: comission.paymentType ?? 'cash' }
  });

  const formData = [
    {
      label: t('office cash'),
      value: 'cash'
    },
    {
      label: t('to bank account', { card: '00023123312' }),
      value: 'bank-account'
    }
  ];

  const onSubmit = async (payload) => {
    if (comission.status === 'paid') {
      await dispatch(asyncCancelCommissionPayment(id));
    } else {
      await dispatch(asyncApproveCommissionPayment({ id, payload }));

      const data = await dispatch(asyncDownloadApprovePayment(id));

      if (payload.paymentType === 'cash') {
        downloadFile({
          data: data.payload || data,
          fileName: 'VPD k PL.xlsx',
          fileType: 'application/vnd.ms-excel'
        });
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn(
        'flex justify-between items-center border p-4',
        {
          '[&>*]:pointer-events-none [&>*]:opacity-50':
            !role?.includes('cashier') ||
            comission.status === 'refund-requested'
        },
        {
          hidden: ['partner', 'admin'].some((item) => item === role)
        }
      )}>
      <div className="flex flex-col gap-2">
        <p>{t('payment methods')}</p>
        <Radio
          defaultValue={comission?.paymentType}
          wrapperClassname={cn(
            '[&>*:first-child]:hidden [&>*:nth-child(2)]:flex-col [&>*:nth-child(2)]:gap-1 select-none',
            {
              'pointer-events-none opacity-50': comission.status === 'paid'
            }
          )}
          required
          options={formData}
          name="paymentType"
          {...register('paymentType', { required: true })}
          error={errors['paymentType']?.message}
        />
      </div>
      <Button
        className={cn(
          {
            'pointer-events-none opacity-0':
              comission.status === 'paid' &&
              (comission.orders[0].paymentType === 'deposit' ||
                comission.orders[0].paymentType === 'office')
          },
          {
            hidden:
              role?.includes('partner') ||
              role?.includes('sales-manager') ||
              comission.status === 'refund-requested'
          }
        )}>
        {t(comission.status === 'paid' ? 'cancel payment' : 'confirm payment')}
      </Button>
    </form>
  );
};
