import { useEffect } from 'react';

import { Button, ButtonVariants, Modal, Portal } from 'components';

export const YesNoModal = ({
  handleClose,
  isOpen,
  onApprove,
  onDecline,
  approveLabel,
  declineLabel,
  question
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && isOpen) {
        onApprove();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onApprove]);

  return (
    <Portal wrapperId="portal-modal">
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <div className="p-4 flex flex-col gap-8">
          <div className="text-center">{question}</div>
          <div className="flex flex-row gap-4 justify-between">
            <Button
              className="flex-1"
              variant={ButtonVariants.DECLINE}
              onClick={onApprove}>
              {approveLabel}
            </Button>
            <Button className="flex-1" onClick={onDecline}>
              {declineLabel}
            </Button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};
