import { api } from 'config';

class ClientsApi {
  constructor(url) {
    this.url = url;
  }

  getClients(queryParams, config) {
    return api.get(
      `${this.url}/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&status=${queryParams?.status ?? ''}`,
      config
    );
  }
}

export const clientsApi = new ClientsApi('/clients');
