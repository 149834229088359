import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import {
  asyncGetCompanies,
  selectCompanies,
  selectCompaniesCount,
  selectCompaniesIsLoading
} from 'store';

import { useFilter, usePagination, useQueryParams, useSearch } from 'hooks';

import { Input, Link, LinkVariants, Pagination, Select } from 'components';

import { CompaniesList } from 'modules';

export const Companies = () => {
  const { filterOptions, selectedFilter, setFilterOptions, setSelectedFilter } =
    useFilter();

  const [searchParams] = useSearchParams();
  const queryFilter = searchParams.get('filter');

  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const dispatch = useDispatch();

  const list = useSelector(selectCompanies);
  const isLoading = useSelector(selectCompaniesIsLoading);
  const count = useSelector(selectCompaniesCount);

  const { t } = useTranslation();

  const getCompanies = async (filter) => {
    const isStatusAll = filter === 'all';

    const data = await dispatch(
      asyncGetCompanies({
        page,
        search,
        status: !isStatusAll ? filter : ''
      })
    ).unwrap();

    setFilterOptions(
      Object.keys(data.counts).map((key) => ({
        label: `${t(key)}(${data.counts[key]})`,
        value: key
      }))
    );
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      getCompanies(selectedFilter?.value ?? queryFilter);
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleOnFilterChange = (e) => {
    setSelectedFilter(e.target);
  };

  useQueryParams({
    page: page + 1,
    search,
    filter: selectedFilter?.value ?? queryFilter
  });

  useEffect(() => {
    getCompanies(selectedFilter?.value ?? queryFilter);
  }, [page, selectedFilter]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        {filterOptions?.length > 0 && (
          <Select
            label={t('status')}
            defaultValue={
              filterOptions?.find(({ value }) => value === queryFilter) ??
              filterOptions[0]
            }
            value={selectedFilter}
            onChange={handleOnFilterChange}
            options={filterOptions}
            wrapperClassname="w-1/5"
          />
        )}
        <Link href={AppRoutes.COMPANY_CREATE} variant={LinkVariants.SECONDARY}>
          {t('create company')}
        </Link>
      </div>
      <CompaniesList list={list} reload={getCompanies} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
