import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = (state) => state.users;

export const selectUsers = createSelector(
  selectUsersState,
  (state) => state.users
);
export const selectUser = createSelector(
  selectUsersState,
  (state) => state.user
);

export const selectUsersIsLoading = createSelector(
  selectUsersState,
  (state) => state.isLoading
);

export const selectIsAuthorized = createSelector(
  selectUsersState,
  (state) => state.isAuthorized
);

export const selectIsFirstLogin = createSelector(
  selectUsersState,
  (state) => state.isFirstLogin
);

export const selectUsersCount = createSelector(
  selectUsersState,
  (state) => state.usersCount
);
export const selectProductsByCompanies = createSelector(
  selectUsersState,
  (state) => state.productsByCompanies
);

export const selectRoleUser = createSelector(
  selectUsersState,
  (state) => state.roleUser
);
