import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Logo } from 'components/index.js';

export const NotFound = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className="h-full w-full flex flex-col items-center justify-center gap-8">
      <Logo />
      <span className="text-8xl font-bold text-green-1">{t('oops')}</span>
      <span className="font-bold text-2xl">{t('not found')}</span>
      <span className="text-xl">{t('not found sorry')}</span>
      <Button className="text-xl" onClick={goBack}>
        {t('go back')}
      </Button>
    </div>
  );
};
