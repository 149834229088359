import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { commissionsApi } from 'api';

import i18n from 'i18n';

export const asyncGetCommissions = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/GET_COMMISSIONS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commissionsApi.getCommissions(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetCommission = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/GET_COMMISSION`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commissionsApi.getCommission(payload);
      return data.comission;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncApproveCommissionPayment = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/APPROVE_COMMISSION_PAYMENT`,
  async ({ id, payload }, { rejectWithValue, dispatch }) => {
    try {
      await commissionsApi.approveCommissionPayment(id, payload);
      dispatch(asyncGetCommission(id));
      toast.success(i18n.t('payment successful'));
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCancelCommissionPayment = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/CANCEL_COMMISSION_PAYMENT`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      await commissionsApi.cancelCommissionPayment(payload);
      toast.success(i18n.t('payment cancel successful'));
      dispatch(asyncGetCommission(payload));
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadApprovePayment = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/DOWNLOAD_APPROVE_PAYMENT`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commissionsApi.downloadPaymentApprove(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadCommissionList = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/DOWNLOAD_COMMISSIONS_LIST`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commissionsApi.downloadCommissionList(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadStornoCommissionList = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/DOWNLOAD_STORNO_COMMISSIONS_LIST`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } =
        await commissionsApi.downloadStornoCommissionList(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadFacture = createAsyncThunk(
  `${SliceNames.COMMISSIONS}/DOWNLOAD_FACTURE`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await commissionsApi.downloadFacture(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const unsetCommission = createAction('UNSET_COMMISSION');
