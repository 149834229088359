import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { usersApi } from 'api';

import i18n from 'i18n';

export const asyncGetUsers = createAsyncThunk(
  `${SliceNames.USERS}/GET_USERS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.getUsers(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetSelf = createAsyncThunk(
  `${SliceNames.USERS}/GET_SELF`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.getSelf();
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateSelf = createAsyncThunk(
  `${SliceNames.USERS}/UPDATE_SELF`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.updateSelf(payload);
      toast.success(i18n.t('succesfully updated'), { toastId: 'update' });
      return data.user;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetUser = createAsyncThunk(
  `${SliceNames.USERS}/GET_USER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.getUser(payload);
      return data.partner;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateUser = createAsyncThunk(
  `${SliceNames.USERS}/UPDATE_USER`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.updateUser(id, payload);
      return data.partner;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateUser = createAsyncThunk(
  `${SliceNames.USERS}/CREATE_USER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.createUser(payload);
      toast.success(i18n.t('created successfully'), { toastId: 'update' });
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncChangePassword = createAsyncThunk(
  `${SliceNames.USERS}/CHANGE_PASSWORD`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.changePassword(payload);
      return data.user;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const unsetIsAuthorized = createAction(
  `${SliceNames.ORDERS}/UNSET_IS_AUTHORIZED`
);

export const unsetIsFirstLogin = createAction(
  `${SliceNames.ORDERS}/UNSET_IS_FIRST_LOGIN`
);

export const setSelf = createAction(`${SliceNames.ORDERS}/SET_SELF`);

export const unsetSelf = createAction(`${SliceNames.ORDERS}/UNSET_SELF`);
