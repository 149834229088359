import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { newsApi } from 'api';

import i18n from 'i18n';

export const asyncGetNews = createAsyncThunk(
  `${SliceNames.NEWS}/GET_NEWS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await newsApi.getNews(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetNewsItem = createAsyncThunk(
  `${SliceNames.NEWS}/GET_NEWS_ITEM`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await newsApi.getNewsItem(payload);
      return data.news;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateNews = createAsyncThunk(
  `${SliceNames.NEWS}/CREATE_NEWS`,
  async (payload, { rejectWithValue }) => {
    try {
      await newsApi.createNews(payload);
      toast.success(i18n.t('created successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateNews = createAsyncThunk(
  `${SliceNames.NEWS}/UPDATE_NEWS`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      await newsApi.updateNews(id, payload);
      toast.success(i18n.t('updated successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteNews = createAsyncThunk(
  `${SliceNames.NEWS}/DELETE_NEWS`,
  async (payload, { rejectWithValue }) => {
    try {
      await newsApi.deleteNews(payload);
      toast.success(i18n.t('deleted successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const unsetIsUpdatedNewsAction = createAction(
  `${SliceNames.NEWS}/UNSET_IS_UPDATED_NEWS`
);
export const unsetIsDeletedNewsAction = createAction(
  `${SliceNames.NEWS}/UNSET_IS_DELETED_NEWS`
);
