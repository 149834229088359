import { createSelector } from '@reduxjs/toolkit';

const selectCompaniesState = (state) => state.commissions;

export const selectCommissions = createSelector(
  selectCompaniesState,
  (state) => state.commissions
);

export const selectCommissionsIsLoading = createSelector(
  selectCompaniesState,
  (state) => state.isLoading
);

export const selectCommission = createSelector(
  selectCompaniesState,
  (state) => state.commission
);

export const selectCommissionsCount = createSelector(
  selectCompaniesState,
  (state) => state.commissionsCount
);
