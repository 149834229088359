import { api } from 'config';

class PartnersApi {
  constructor(url) {
    this.url = url;
  }

  getPartners(queryParams, config) {
    return api.get(
      `${this.url}/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }`,
      config
    );
  }

  getPartner(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  getPartnerCompaniesAndProducts(config) {
    return api.get(`${this.url}/permitted`, config);
  }

  getPartnerVouchers(id, config) {
    return api.get(`${this.url}/vouchers/${id}`, config);
  }

  getDownloadCsv(config) {
    return api.get(`${this.url}/download-csv`, {
      responseType: 'blob',
      ...config
    });
  }

  createPartner(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  updatePartner(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }
}

export const partnersApi = new PartnersApi('/partners');
