import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  createCompanyReducer,
  deleteCompanyPdfReducer,
  deleteCompanyReducer,
  getCompaniesReducer,
  getCompanyReducer,
  updateCompanyReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.COMPANIES,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCompaniesReducer(builder);
    getCompanyReducer(builder);
    createCompanyReducer(builder);
    updateCompanyReducer(builder);
    deleteCompanyReducer(builder);
    deleteCompanyPdfReducer(builder);
  }
});

export const companiesReducer = slice.reducer;
