import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  asyncCreateOrder,
  asyncGetOrders,
  selectOrderIsLoading,
  selectOrders,
  selectOrdersCount,
  selectOrdersCounts,
  selectUser
} from 'store';

import { useFilter, usePagination, useQueryParams, useSearch } from 'hooks';

import { Button, Datepicker, Input, Pagination, Select } from 'components';

import { OrdersList } from 'modules';

export const Orders = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queryFilter = searchParams.get('filter');

  const [date, setDate] = useState(searchParams.get('date'));

  const { filterOptions, selectedFilter, setSelectedFilter } = useFilter([
    {
      label: t('all'),
      value: 'all'
    },
    {
      label: t('unpaid commission'),
      value: 'unpaidComission'
    },
    {
      label: t('unpaid'),
      value: 'unpaid'
    },
    {
      label: t('paid'),
      value: 'paid'
    },
    {
      label: t('draft'),
      value: 'draft'
    },
    {
      label: t('pay later'),
      value: 'pay-later'
    }
  ]);

  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const commissionData = useSelector(selectOrdersCounts);
  const list = useSelector(selectOrders);
  const isLoading = useSelector(selectOrderIsLoading);
  const count = useSelector(selectOrdersCount);

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const role = user?.role;

  const getOrders = async (filter) => {
    const isStatusAll = filter === 'all';

    const queryParams = {
      page,
      search,
      date,
      partner:
        role === 'admin' || role === 'cashier' || role === 'sales-manager'
          ? ''
          : 'me',
      status: isStatusAll ? '' : filter
    };

    dispatch(asyncGetOrders(queryParams));
  };
  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = async (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      await getOrders(selectedFilter?.value ?? queryFilter);
    }
  };

  const handleOnFilterChange = (e) => {
    setSelectedFilter(e.target);
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleDateRangeChange = (e) => {
    setDate(e);
  };

  const handleOnCreateOrder = async () => {
    sessionStorage.removeItem('orderId');
    const order = await dispatch(asyncCreateOrder()).unwrap();

    sessionStorage.setItem('orderId', order._id);
    navigate(`/orders/${order._id}`);
  };

  useQueryParams({
    page: page + 1,
    search,
    filter: selectedFilter?.value ?? queryFilter,
    date
  });

  useEffect(() => {
    getOrders(selectedFilter?.value ?? queryFilter);
  }, [page, selectedFilter, date, user]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <p>
          {t('home - stats - unpaid orders', {
            value: commissionData.unpaidOrders ?? 0
          })}
        </p>
        <p>
          {t('home - stats - comissions payments sum', {
            value: commissionData.comissionsForPay ?? 0,
            currency: 'CZK'
          })}
        </p>
      </div>
      <div className="flex flex-row justify-between items-end">
        <div className="flex items-end gap-2 flex-1">
          <Input
            wrapperClassname="w-1/4"
            onKeyPress={handleSubmitSearch}
            onChange={handleSetSearch}
            value={search}
            label={t('search')}
            hideError
          />
          {filterOptions ? (
            <Select
              wrapperClassname="w-1/4"
              label={t('status')}
              defaultValue={filterOptions?.find(
                ({ value }) => value === queryFilter
              )}
              value={selectedFilter}
              onChange={handleOnFilterChange}
              options={filterOptions}
            />
          ) : (
            ''
          )}
          <Datepicker
            isClearable
            wrapperClassname="w-1/4"
            maxDate={new Date()}
            label={t('filter date')}
            onChange={handleDateRangeChange}
            value={date}
          />
        </div>
        {(role === 'partner-agent' || role === 'partner-member') && (
          <Button onClick={handleOnCreateOrder}>{t('create order')}</Button>
        )}
      </div>
      <OrdersList list={list} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
