import { api } from 'config';

class AuthApi {
  constructor(url) {
    this.url = url;
  }

  sendLogin(payload, config) {
    return api.post(`${this.url}/login`, payload, config);
  }

  sendForgotPassword(payload, config) {
    return api.post(`${this.url}/forgot-password`, payload, config);
  }

  sendResetPassword(payload, config) {
    return api.post(`${this.url}/reset-password`, payload, config);
  }

  sendBecomePartner(payload, config) {
    return api.post(`${this.url}/become-partner`, payload, config);
  }

  sendCheckRecoveryToken(payload, config) {
    return api.post(`${this.url}/check-recovery-token`, payload, config);
  }
}

export const authApi = new AuthApi('/auth');
