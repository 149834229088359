import cn from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectUser } from 'store/index.js';

import { useExpiresSoon } from 'hooks';

import { Button, Table } from 'components';

import ArrowSrc from 'svg/arrow.svg';

import { DropDownList } from './DropDownList.jsx';

export const ClientsList = ({ list, isLoading }) => {
  const user = useSelector(selectUser);

  const ordersInfo = list.map((client) => (
    <DropDownList
      key={client.orders}
      list={client.orders}
      passport={client?.passport}
    />
  ));

  const [prevIndex, setPrevIndex] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const expires = useExpiresSoon();

  const isExpiredSoon = (el) => {
    if (expires) {
      const today = moment();
      const expirationDate = moment(el.end);

      const expireSoon = expirationDate.diff(today, 'milliseconds');
      const sevenDaysInMillis = moment.duration(7, 'days').valueOf();

      if (user?.role === 'admin') {
        return expireSoon <= sevenDaysInMillis && expireSoon > 0;
      }

      switch (user?.expiresSoon) {
        case '7 days': {
          return expireSoon <= sevenDaysInMillis && expireSoon > 0;
        }
        case '1 month': {
          const oneMonthInMillis = moment.duration(1, 'months').valueOf();
          return expireSoon <= oneMonthInMillis && expireSoon > 0;
        }
        case '3 months': {
          const threeMonthsInMillis = moment.duration(3, 'months').valueOf();
          return expireSoon <= threeMonthsInMillis && expireSoon > 0;
        }
      }
    }
  };

  const isExpired = (el) => {
    if (expires) {
      const expirationDate = moment(el.end);
      return expirationDate.diff(moment(), 'milliseconds') < 0;
    }
  };

  const onActionClick = (id) => () => {
    if (prevIndex !== id) {
      searchParams.set('dropId', id);
      setPrevIndex(id);
    } else {
      searchParams.set('dropId', '-1');
      setPrevIndex('-1');
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    searchParams.delete('dropId');
    setSearchParams(searchParams);
  }, []);

  const Actions = ({ index }) => {
    return (
      <div className="flex justify-center items-center">
        <Button
          onClick={onActionClick(index)}
          className="!px-3 hover:bg-green-2/60">
          <img
            src={ArrowSrc}
            className={cn(
              'transition-all duration-700',
              { '-rotate-90': prevIndex === index },
              { 'rotate-90': prevIndex !== index }
            )}
            alt="arrow"
          />
        </Button>
      </div>
    );
  };

  const cfg = [
    {
      valueFunc: (_, el) => `${el.firstName} ${el.lastName}`,
      key: `name`,
      header: t('name')
    },
    {
      key: `phone`,
      header: t('phone')
    },
    {
      key: `passport`,
      header: t('passport')
    },
    {
      key: `citizenship`,
      header: t('citizenship')
    },
    {
      valueFunc: (_, el) => moment(el.birthday).format('DD.MM.YYYY'),
      key: `birthday`,
      header: t('birthday')
    },
    {
      valueFunc: (el) => t(el),
      key: `gender`,
      header: t('gender')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el.orders[0]),
            'text-error': isExpiredSoon(el.orders[0])
          })}>
          {moment(el.orders[0].end).format('DD.MM.YYYY')}
        </span>
      ),
      key: `insuranceEndDate`,
      header: t('next insurance expires')
    },
    {
      header: t('actions'),
      component: Actions,
      propsKeys: ['index']
    }
  ];

  return (
    <Table
      config={cfg}
      items={list}
      isLoading={isLoading}
      dropDownContent={ordersInfo}
      hasDropDown
    />
  );
};
