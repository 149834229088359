import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { max, min, num } from 'normalizers';

import {
  asyncCreateProduct,
  asyncGetCompanies,
  asyncGetProduct,
  asyncGetSettings,
  asyncImportFromCsv,
  asyncUpdateProduct,
  selectCompanies,
  selectIsUpdatedProduct,
  selectSettings,
  unsetIsUpdatedProductAction
} from 'store';

import { Button, FileUploader, Input, Select, TextArea } from 'components';

import AddSrc from 'svg/add.svg';
import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

export const ProductsForm = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();

  const dispatch = useDispatch();

  const settings = useSelector(selectSettings);
  const isUpdatedProduct = useSelector(selectIsUpdatedProduct);
  const companies = useSelector(selectCompanies);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    reset,
    control,
    handleSubmit,
    register,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      return getProduct();
    }
  });

  const getProduct = async () => {
    const product = {
      name: '',
      company: '',
      comment: '',
      types: [],
      maxPayout: '',
      axaProductCode: ''
    };

    if (id) {
      const productData = await dispatch(asyncGetProduct(id)).unwrap();
      Object.keys(product).forEach((key) => {
        if (productData[key]) product[key] = productData[key];
      });
    }

    const data = await dispatch(
      asyncGetCompanies({ status: 'active' })
    ).unwrap();
    const companies = data?.list;

    const companyId = searchParams.get('company');

    if (companyId) {
      product.company = companies?.find(
        (company) => company._id === companyId
      ).organization;
    }

    return product;
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'types',
    rules: {
      validate: (value) => {
        if (!value.length) {
          setError('types', t('error - required'));
          return false;
        }

        return true;
      }
    }
  });

  const onSubmit = async ({ types, ...data }) => {
    const body = {
      ...data,
      types: types.map((el) => ({ ...el, name: el?.name }))
    };

    dispatch(
      id ? asyncUpdateProduct({ id, payload: body }) : asyncCreateProduct(body)
    )
      .unwrap()
      .then(() => {
        navigate(AppRoutes.PRODUCTS);
      })
      .catch();
  };

  const handleEditType = (index) => () => {
    const values = getValues();

    if (isEditing === false || values?.types?.[isEditing]['name'])
      setIsEditing(index);
  };
  const handleDeleteType = (index) => () => {
    remove(index);
  };
  const handleSaveType = (index) => (e) => {
    e.preventDefault();

    const values = getValues()?.types?.[index];
    checkTableErrors(values, index);

    if (!errors.types?.[index]) setIsEditing(false);
  };
  const _companies = companies?.map(({ organization, ...rest }) => ({
    value: organization,
    label: organization,
    ...rest
  }));

  const handleDownloadFacture = async (fileUploaded) => {
    const formData = new FormData();
    formData.append('file', fileUploaded);

    await dispatch(asyncImportFromCsv({ id, payload: formData }));
    reset(getProduct());
  };

  const checkTableErrors = (values, index) => {
    Object.keys(getValues()?.types?.[index]).forEach((key) => {
      if (values[key] !== '') {
        clearErrors(`types.${index}.${key}`);
        return;
      }

      setError(`types.${index}.${key}`, {
        type: 'custom',
        message: t('error - required')
      });
    });

    const ageStart = getValues()?.types?.[index].ageStart;
    const ageEnd = getValues()?.types?.[index].ageEnd;

    if (ageStart && ageEnd && Number(ageStart) >= Number(ageEnd)) {
      setError(`types.${index}.ageStart`, {
        type: 'age',
        message: t('error - start end age')
      });
    }
    setSelectedIndex(index);
  };

  watch('company');
  watch('types');
  const selectedCompany = getValues()?.company;

  useEffect(() => {
    dispatch(asyncGetSettings());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdatedProduct) {
      dispatch(unsetIsUpdatedProductAction());
      navigate(AppRoutes.PRODUCTS);
    }
  }, [isUpdatedProduct, navigate]);

  if (!companies) return null;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="flex flex-col gap-4 flex-1">
          <Input
            name="name"
            label={t('product name')}
            required
            error={errors['name']?.message}
            {...register('name', {
              required: { value: true, message: t('error - required') }
            })}
          />
        </div>
        <Input
          normalize={[num]}
          wrapperClassname="flex-1"
          name="maxPayout"
          label={t('max-payout')}
          required
          error={errors['maxPayout']?.message}
          {...register('maxPayout', {
            required: { value: true, message: t('error - required') }
          })}
        />
      </div>
      <div className="flex gap-4">
        <Controller
          control={control}
          name="company"
          rules={{ required: t('error - required') }}
          render={({ field: { onChange, value } }) => (
            <Select
              wrapperClassname="flex-1"
              name="company"
              disabled={!companies?.length || getValues()?.types?.length}
              options={_companies}
              onChange={onChange}
              error={errors['company']?.message}
              value={value}
              label={t('company')}
              required
            />
          )}
        />
        <Input
          wrapperClassname="flex-1"
          name="axaProductCode"
          label={t('axa product code')}
          required
          error={errors['axaProductCode']?.message}
          {...register('axaProductCode', {
            required: { value: true, message: t('error - required') }
          })}
        />
      </div>
      {selectedCompany && (
        <div>
          <div className="flex items-end justify-between mb-2">
            <label className="text-grey-3 text-sm">
              {t('insurance types table')}*
            </label>
            {id && <FileUploader handleFile={handleDownloadFacture} />}
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr className="border">
                <th className="text-left text-sm p-2">{t('insurance term')}</th>
                <th className="text-left text-sm p-2">{t('age')}</th>
                <th className="text-left text-sm p-2">{t('insurance type')}</th>
                <th className="text-left text-sm p-2">{t('price')}</th>
                <th className="text-left text-sm p-2">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => {
                const selectedCompany = getValues()?.company;
                const selectedCompanyTypes =
                  companies.find((el) => el.organization === selectedCompany)
                    ?.types || [];
                const types = selectedCompanyTypes.map((type) => ({
                  value: type.name,
                  label: type.name
                }));

                return (
                  <tr className="border" key={field.id}>
                    <td className="text-left text-sm p-2">
                      <div className="flex flex-col gap-1">
                        <Input
                          normalize={[num, max(settings?.term), min(1)]}
                          disabled={isEditing !== index}
                          wrapperClassname="w-16"
                          {...register(`types.${index}.term`, {
                            required: {
                              value: true,
                              message: t('error - required')
                            }
                          })}
                        />
                      </div>
                    </td>
                    <td className="text-left text-sm p-2 flex flex-row gap-1 items-center">
                      <div className="flex flex-col gap-1">
                        <Input
                          normalize={[num, max(99), min(1)]}
                          disabled={isEditing !== index}
                          wrapperClassname="w-10"
                          {...register(`types.${index}.ageStart`, {
                            required: {
                              value: true,
                              message: t('error - required')
                            }
                          })}
                        />
                      </div>
                      -
                      <div className="flex flex-col gap-1">
                        <Input
                          normalize={[num, max(100), min(1)]}
                          disabled={isEditing !== index}
                          wrapperClassname="w-10"
                          {...register(`types.${index}.ageEnd`, {
                            required: {
                              value: true,
                              message: t('error - required')
                            }
                          })}
                        />
                      </div>
                    </td>
                    <td className="text-left text-sm p-2">
                      <div className="flex flex-col gap-1">
                        <Controller
                          control={control}
                          name={`types.${index}.name`}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Select
                                name={`types.${index}.name`}
                                disabled={!types?.length || isEditing !== index}
                                options={types}
                                onChange={onChange}
                                value={value}
                              />
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td className="text-left text-sm p-2">
                      <div className="flex flex-col gap-1">
                        <Input
                          normalize={[num, min(1)]}
                          disabled={isEditing !== index}
                          {...register(`types.${index}.price`, {
                            required: {
                              value: true,
                              message: t('error - required')
                            }
                          })}
                        />
                      </div>
                    </td>
                    <td className="text-left text-sm p-2">
                      {isEditing === index && (
                        <Button onClick={handleSaveType(index)}>
                          {t('save')}
                        </Button>
                      )}
                      {isEditing !== index && (
                        <div className="flex flex-row">
                          <img
                            onClick={handleEditType(index)}
                            className="cursor-pointer pr-2"
                            src={EditSrc}
                            alt="edit"
                          />
                          <img
                            onClick={handleDeleteType(index)}
                            className="cursor-pointer pl-2"
                            src={DeleteSrc}
                            alt="delete"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="w-1/5"></td>
                <td className="w-1/5"></td>
                <td className="w-1/5"></td>
                <td className="w-1/5"></td>
                <td
                  className={cn('w-1/5 text-left text-sm', {
                    'p-2': !isEditing
                  })}>
                  {isEditing === false && (
                    <img
                      src={AddSrc}
                      onClick={() => {
                        append({
                          term: '',
                          ageStart: '',
                          ageEnd: '',
                          name: '',
                          price: ''
                        });

                        setIsEditing(getValues()?.types?.length - 1);
                      }}
                      className="float-right cursor-pointer h-[30px]"
                      alt="add"
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {selectedIndex >= 0 &&
            errors['types'] &&
            Object.entries(errors['types'][selectedIndex] ?? {}).map(
              ([errorKey, errorValue]) => (
                <div key={errorKey} className="flex flex-col gap-2">
                  <span className="text-xs text-error">
                    {t(errorKey)}: {t(errorValue.message)}
                  </span>
                </div>
              )
            )}
        </div>
      )}
      <TextArea
        rows={8}
        name="comment"
        label={t('comment')}
        error={errors['comment']?.message}
        {...register('comment')}
      />
      <div>
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
