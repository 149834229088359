import cs from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

export const LinkVariants = {
  PRIMARY: 'text-blue-2 hover:underline',
  SECONDARY:
    'border border-green-1 py-1 px-6 rounded hover:bg-white hover:text-green-1 bg-green-1 text-white',
  SECONDARY_INVERTED:
    'border border-green-1 py-1 px-6 rounded hover:bg-green-1 hover:text-white bg-white text-green-1'
};

export const Link = ({
  children,
  className,
  href,
  variant = LinkVariants.PRIMARY
}) => {
  return (
    <RouterLink to={href} className={cs('cursor-pointer', className, variant)}>
      {children}
    </RouterLink>
  );
};
