import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  createVoucherReducer,
  deleteVoucherReducer,
  getVoucherReducer,
  getVouchersReducer,
  updateVoucherReducer
} from './reducers.js';

const initialState = {
  vouchers: null,
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.VOUCHERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getVouchersReducer(builder);
    getVoucherReducer(builder);
    createVoucherReducer(builder);
    updateVoucherReducer(builder);
    deleteVoucherReducer(builder);
  }
});

export const vouchersReducer = slice.reducer;
