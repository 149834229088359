import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  changePasswordReducer,
  createUserReducer,
  getSelfReducer,
  getUserReducer,
  getUsersReducer,
  setSelfReducer,
  unsetIsAuthorizedReducer,
  unsetIsFirstLoginReducer,
  unsetSelfReducer,
  updateSelfReducer,
  updateUserReducer
} from './reducers.js';

const initialState = {
  partners: null,
  partner: null,
  isLoading: false,
  isAuthorized: null,
  isFirstLogin: null
};

const slice = createSlice({
  name: SliceNames.USERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getUsersReducer(builder);
    getSelfReducer(builder);
    getUserReducer(builder);
    updateUserReducer(builder);
    createUserReducer(builder);
    updateSelfReducer(builder);
    changePasswordReducer(builder);
    unsetIsAuthorizedReducer(builder);
    setSelfReducer(builder);
    unsetIsFirstLoginReducer(builder);
    unsetSelfReducer(builder);
  }
});

export const usersReducer = slice.reducer;
