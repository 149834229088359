import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppRoutes } from 'constants/index.js';

import {
  asyncGetVouchers,
  selectUser,
  selectVouchers,
  selectVouchersCount,
  selectVouchersIsLoading
} from 'store';

import { usePagination, useQueryParams, useSearch } from 'hooks';

import { Input, Link, LinkVariants, Pagination } from 'components';

import { VouchersFaq, VouchersList } from 'modules';

export const Vouchers = () => {
  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const dispatch = useDispatch();

  const list = useSelector(selectVouchers);
  const isLoading = useSelector(selectVouchersIsLoading);
  const count = useSelector(selectVouchersCount);

  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = async (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      dispatch(asyncGetVouchers({ page, search }));
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useQueryParams({ page: page + 1, search });

  useEffect(() => {
    dispatch(asyncGetVouchers({ page, search }));
  }, [dispatch, page]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        {(user?.role === 'admin' || user?.role === 'sales-manager') && (
          <Link
            href={AppRoutes.VOUCHER_CREATE}
            variant={LinkVariants.SECONDARY}>
            {t('create voucher')}
          </Link>
        )}
      </div>
      <VouchersList list={list} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
      <VouchersFaq />
    </div>
  );
};
