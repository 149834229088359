import { getAllTimezones } from 'countries-and-timezones';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { validatePhoneNumber } from 'constants/phone-field.js';

import { getCountriesArr, getCurrencies } from 'utils';

import { num } from 'normalizers';

import { validateNegotiatorNumber } from 'validators/negotiator-number.jsx';

import { asyncGetUsers, asyncUpdateSettings, selectUser } from 'store';

import { Button, CreatableSelect, FlagSelect, Input, Select } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

export const MainSettingsForm = ({ settings }) => {
  const [partnersList, setPartnersList] = useState([]);
  const [negotiationsOptions, setNegorioationOptions] = useState([]);

  const dispatch = useDispatch();

  const allCountries = getAllTimezones();
  const allCountriesOptions = Object.keys(allCountries).map((key) => ({
    value: `${allCountries[key]?.name}: ${allCountries[key]?.utcOffsetStr}`,
    label: `${allCountries[key]?.name}: ${allCountries[key]?.utcOffsetStr}`
  }));

  const user = useSelector(selectUser);
  const [countries, setCountries] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const settingsData = {
        company: '',
        phone: '',
        address: '',
        term: '',
        currency: '',
        city: '',
        index: '',
        country: '',
        ITN: '',
        invoiceNumber: '',
        companyPartners: '',
        defaultLang: '',
        expiresSoon: '',
        insurersRepresentativeNumber: '',
        allowedNegotiationNumbers: '',
        timezone: '',
        iban: '',
        minTermForExpiresSoon: ''
      };

      if (settings) {
        Object.keys(settingsData).forEach((key) => {
          if (settings[key]) {
            settingsData[key] = settings[key];
            if (key === 'allowedNegotiationNumbers') {
              settingsData[key] = populateOptions(key, settings[key]);
            }
          }
        });
      }

      return settingsData;
    }
  });
  const { t } = useTranslation();

  const onSubmit = async ({
    allowedNegotiationNumbers: negotiationNumbers,
    ...data
  }) => {
    const allowedNegotiationNumbers = negotiationNumbers.map(
      (option) => option.value
    );
    dispatch(asyncUpdateSettings({ allowedNegotiationNumbers, ...data }));
  };

  const expiringOptions = [
    {
      label: t('expiring - week'),
      value: '7 days'
    },
    {
      label: t('expiring - month'),
      value: '1 month'
    },
    {
      label: t('expiring - months'),
      value: '3 months'
    }
  ];

  const isValidNewOption = (inputValue) => {
    return validateNegotiatorNumber(t).value.test(inputValue);
  };

  const getUsers = async () => {
    const { list } = await dispatch(
      asyncGetUsers({ roles: ['partner-agent', 'partner-member'] })
    ).unwrap();

    const opts = list.map(({ _id, firstName, lastName, email }) => ({
      value: _id,
      label: firstName && lastName ? `${firstName} ${lastName}` : email
    }));

    setPartnersList(opts);
  };

  const populateOptions = (key, data) => {
    const formattedData = data.map((value) => {
      if (value) {
        return {
          label: value,
          value
        };
      }
    });
    setNegorioationOptions(formattedData);
    return formattedData;
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (!user) return;
    setCountries(getCountriesArr(user.lang));
  }, [user]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-2 gap-y-4 gap-x-16"
      onKeyPress={(e) => {
        e.key === 'Enter' && e.preventDefault();
      }}>
      <Input
        required
        name="company"
        label={t('company')}
        error={errors['company']?.message}
        {...register('company', { required: t('error - required') })}
      />
      <PhoneField
        control={control}
        name="phone"
        label={t('phone')}
        required
        validation={{
          required: t('error - required'),
          validate: validatePhoneNumber
        }}
      />
      <Input
        required
        name="address"
        label={t('address')}
        error={errors['address']?.message}
        {...register('address', { required: t('error - required') })}
      />
      <Input
        required
        name="city"
        label={t('city')}
        error={errors['city']?.message}
        {...register('city', {
          required: t('error - required')
        })}
      />
      <Input
        required
        name="index"
        label={t('index')}
        error={errors['index']?.message}
        {...register('index', { required: t('error - required') })}
      />
      <Controller
        name="country"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={countries}
            required
            error={errors['country']?.message}
            label={t('country')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input
        required
        name="ITN"
        label={t('itn code')}
        error={errors['ITN']?.message}
        {...register('ITN', { required: t('error - required') })}
      />
      <Input
        required
        name="invoiceNumber"
        label={t('invoice number')}
        error={errors['invoiceNumber']?.message}
        {...register('invoiceNumber', {
          required: t('error - required')
        })}
      />
      <Controller
        name="currency"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={getCurrencies()}
            required
            label={t('currency')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input
        required
        name="term"
        label={t('term')}
        error={errors['term']?.message}
        {...register('term', { required: t('error - required') })}
      />
      <Controller
        name="defaultLang"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <FlagSelect
            placeholder={t('select language')}
            required
            label={t('standart language')}
            selected={value}
            onSelect={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="companyPartners"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={!partnersList?.length}
            options={partnersList}
            required
            label={t('company partner')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name="expiresSoon"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            options={expiringOptions}
            required
            error={errors['expiresSoon']?.message}
            label={t('expiring soon')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input
        normalize={[num]}
        required
        name="insurersRepresentativeNumber"
        label={t('representative number')}
        error={errors['insurersRepresentativeNumber']?.message}
        {...register('insurersRepresentativeNumber', {
          required: t('error - required')
        })}
      />
      <Controller
        name="timezone"
        control={control}
        defaultValue={
          allCountriesOptions.find(({ value }) => value.includes('Prague'))
            ?.value
        }
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            defaultValue={allCountriesOptions.find(({ value }) =>
              value.includes('Prague')
            )}
            options={allCountriesOptions}
            required
            error={errors['timezone']?.message}
            label={t('timezone')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input
        required
        name="iban"
        label="IBAN"
        error={errors['iban']?.message}
        {...register('iban', {
          required: t('error - required')
        })}
      />
      <div>
        <Controller
          name="allowedNegotiationNumbers"
          control={control}
          rules={{ required: t('error - required') }}
          render={({ field: { value, onChange } }) => (
            <CreatableSelect
              isMulti
              menuPlacement="top"
              name="allowedNegotiationNumbers"
              required
              options={negotiationsOptions}
              error={errors['allowedNegotiationNumbers']?.message}
              label={t('negotiator-number')}
              value={value?.length ? value : []}
              onChange={onChange}
              isValidNewOption={isValidNewOption}
            />
          )}
        />
        <span className="text-grey-3 text-sm underline cursor-default">
          {t('error - negotiator pattern')}
        </span>
      </div>
      <Input
        normalize={[num]}
        required
        name="minTermForExpiresSoon"
        label={t('min term for expires soon')}
        {...register('minTermForExpiresSoon', {
          required: t('error - required')
        })}
      />
      <div>
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
