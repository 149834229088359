import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { asyncGetUsers } from 'store/index.js';
import { asyncGetLogs } from 'store/loggs/actions.js';
import {
  selectLogs,
  selectLogsCount,
  selectLogsIsLoading
} from 'store/loggs/selectors.js';

import { useFilter, usePagination, useQueryParams } from 'hooks';

import { Button, Datepicker, Pagination, Select } from 'components';

import { LogsList } from 'modules/Logs/LogsList.jsx';

export const Logs = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const queryFilter = searchParams.get('filter');
  const queryUser = searchParams.get('user');

  const [date, setDate] = useState(searchParams.get('date'));

  const [usersOptions, setUsersOptions] = useState();
  const [selectedUserFilter, setSelectedUserFilter] = useState();

  const { filterOptions, selectedFilter, setSelectedFilter } = useFilter([
    {
      label: t('all'),
      value: ''
    },
    {
      label: t('profile'),
      value: 'profile'
    },
    {
      label: t('order'),
      value: 'order'
    }
  ]);

  const { page, setPage } = usePagination();

  const list = useSelector(selectLogs);
  const isLoading = useSelector(selectLogsIsLoading);
  const count = useSelector(selectLogsCount);

  const dispatch = useDispatch();
  const getLogs = async (filter, user) => {
    const queryParams = {
      page,
      date,
      actionType: filter,
      user
    };

    dispatch(asyncGetLogs(queryParams));
  };
  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleOnFilterChange = (e) => {
    setSelectedFilter(e.target);
  };

  const handleUserChange = (e) => {
    setSelectedUserFilter(e.target);
  };

  const handleDateRangeChange = (e) => {
    setDate(e);
  };

  useQueryParams({
    page: page + 1,
    filter: selectedFilter?.value ?? queryFilter,
    date,
    user: selectedUserFilter?.value ?? queryUser
  });

  useEffect(() => {
    getLogs(
      selectedFilter?.value ?? queryFilter,
      selectedUserFilter?.value ?? queryUser
    );
  }, [page, selectedFilter, date, selectedUserFilter]);

  useEffect(() => {
    dispatch(asyncGetUsers())
      .unwrap()
      .then(({ list }) => {
        setUsersOptions([
          {
            value: '',
            label: t('all')
          },
          ...list.map((user) => ({
            label: `${user?.firstName} ${user?.lastName}`,
            value: user?._id
          }))
        ]);
      });
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <div className="flex items-end gap-2 flex-1">
          {filterOptions ? (
            <Select
              wrapperClassname="w-1/4"
              label={t('action type')}
              defaultValue={filterOptions?.find(
                ({ value }) => value === queryFilter
              )}
              value={selectedFilter}
              onChange={handleOnFilterChange}
              options={filterOptions}
            />
          ) : (
            ''
          )}
          {usersOptions ? (
            <Select
              wrapperClassname="w-1/4"
              label={t('user')}
              defaultValue={usersOptions?.find(
                ({ value }) => value === queryUser
              )}
              value={selectedUserFilter}
              onChange={handleUserChange}
              options={usersOptions}
            />
          ) : (
            ''
          )}
          <Datepicker
            isClearable
            wrapperClassname="w-1/4"
            maxDate={new Date()}
            label={t('filter date')}
            onChange={handleDateRangeChange}
            value={date}
          />
        </div>
      </div>
      <LogsList list={list} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
