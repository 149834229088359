import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { asyncGetNewsItem, selectNewsItem } from 'store';

import { GoBack } from 'components';

export const NewsItem = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const lng = i18n.language.split('-')[0]?.toLocaleUpperCase();

  const dispatch = useDispatch();

  const item = useSelector(selectNewsItem);

  useEffect(() => {
    dispatch(asyncGetNewsItem(id));
  }, [id]);

  let _lng = '';
  switch (lng) {
    case 'EN':
    case 'UK':
      _lng = 'US';
      break;
    case 'CZ':
      _lng = 'CZ';
      break;
    default:
      _lng = 'RU';
      break;
  }

  if (!item) return null;
  return (
    <>
      <div className="flex flex-col mb-4">
        <span className="text-blue-1">{item[`title${_lng}`]}</span>
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: item[`content${_lng}`] }}
        />
      </div>
      <GoBack />
    </>
  );
};
