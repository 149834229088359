import { useTranslation } from 'react-i18next';

import { getCommissionRoute } from 'utils';

import { Link, LinkVariants, Table } from 'components';

export const ComissionsList = ({ list, isLoading }) => {
  const { t } = useTranslation();

  const Actions = ({ _id }) => {
    return (
      <Link variant={LinkVariants.SECONDARY} href={getCommissionRoute(_id)}>
        {t('view')}
      </Link>
    );
  };

  const cfg = [
    {
      valueFunc: (_, el) => `${el.partner.firstName} ${el.partner.lastName}`,
      key: 'partnerName',
      header: t('partner name')
    },
    {
      valueFunc: (_, el) => `${el.prices.comission}`,
      key: 'payout',
      header: t('payout')
    },

    {
      valueFunc: (_, el) => `${el.prices.storne}`,
      key: 'storne',
      header: t('storne')
    },
    {
      valueFunc: (_, el) => t(`comission - status - ${el.status}`),
      key: 'status',
      header: t('status')
    },
    {
      valueFunc: (orders) => orders.map(({ id }) => id).join(', '),
      key: 'orders',
      header: t('order numbers')
    },
    {
      component: Actions,
      key: 'actions',
      header: t('actions'),
      propsKeys: ['_id']
    }
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
