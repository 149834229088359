import cs from 'classnames';
import ReactSelect from 'react-select';

const styles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled && '#fff',
    borderWidth: isDisabled ? 0 : 1,
    minHeight: 'unset'
  }),
  valueContainer: (styles, { isDisabled }) => ({
    ...styles,
    padding: isDisabled ? 0 : '0 8px'
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: '4px 0',
    color: '#000',
    fontSize: '14px'
  }),
  indicatorSeparator: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled && 'none'
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled && 'none',
    padding: 1
  }),
  option: (styles) => ({
    ...styles,
    padding: '2px 8px',
    color: '#315163',
    fontSize: '14px'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
  })
};

export const Select = ({
  options,
  required,
  wrapperClassname,
  name,
  label,
  error,
  hideError = false,
  disabled,
  value,
  onChange,
  placeholder = ' ',
  ...props
}) => {
  const _value = options.find((el) => String(el.value) === String(value));

  return (
    <div className={cs('flex flex-col', wrapperClassname)}>
      <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <ReactSelect
        isOptionSelected={(option, selectValue) =>
          selectValue.some((i) => i === option)
        }
        isOptionDisabled={(option) => option.disabled}
        isDisabled={disabled}
        value={value === null ? value : _value}
        placeholder={placeholder}
        styles={styles}
        options={options}
        name={name}
        onChange={(e) => {
          onChange({ target: e });
        }}
        {...props}
      />
      {!hideError && error && (
        <span className="block text-error text-xs">{error}</span>
      )}
    </div>
  );
};
