import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutes, EMAIL_OPTIONS } from 'constants/index.js';
import { validatePhoneNumber } from 'constants/phone-field.js';

import { asyncBecomePartner } from 'store';

import { Button, Input, Link, TextArea, Toggle } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

export const PartnerRegisterForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      message: ''
    }
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    const { privacyPolicy, ...restData } = data;

    if (!privacyPolicy) {
      toast.error(t('Check privacy policy before sending request'));
      return;
    }

    await dispatch(asyncBecomePartner(restData));
    navigate(AppRoutes.LOGIN);
  };

  useEffect(() => {
    window.dispatchEvent(new Event('storage'));
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <Input
          required
          error={errors['email']?.message}
          label={t('email')}
          {...register('email', EMAIL_OPTIONS)}
        />
        <Input
          required
          error={errors['firstName']?.message}
          label={t('first name')}
          {...register('firstName', { required: t('error - required') })}
        />
        <Input
          required
          error={errors['lastName']?.message}
          label={t('last name')}
          {...register('lastName', { required: t('error - required') })}
        />
        <PhoneField
          control={control}
          name="phone"
          label={t('phone')}
          required
          validation={{
            required: t('error - required'),
            validate: validatePhoneNumber
          }}
        />
      </div>
      <TextArea
        rows={8}
        name="message"
        label={t('comment')}
        {...register('message', {})}
      />
      <div className="flex items-center gap-2">
        <Controller
          control={control}
          defaultValue=""
          name="privacyPolicy"
          rules={{ required: t('error - required') }}
          render={({ field: { value, onChange } }) => (
            <Toggle
              inlineLabel={
                <Link href={AppRoutes.PRIVACY_POLICY}>
                  GDPR + Privacy policy *
                </Link>
              }
              error={errors['privacyPolicy']?.message}
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="flex justify-between">
        <Button className="flex-1">{t('save')}</Button>
      </div>
    </form>
  );
};
