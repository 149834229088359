import { useEffect, useState } from 'react';

import { generateColor } from 'utils';

export const useRandomColor = (data = []) => {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    setColors([]);
    const uniqueColors = new Set();
    const generatedColors = [];

    data.forEach(() => {
      let color = generateColor();

      while (uniqueColors.has(color)) {
        color = generateColor();
      }

      uniqueColors.add(color);
      generatedColors.push(color);
    });

    setColors(generatedColors);
  }, [data]);

  return colors;
};
