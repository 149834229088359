import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { logsApi } from 'api/logsApi.js';

import i18n from 'i18n';

export const asyncGetLogs = createAsyncThunk(
  `${SliceNames.LOGS}/GET_LOGS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await logsApi.getLogs(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetLogsItem = createAsyncThunk(
  `${SliceNames.LOGS}/GET_LOGS_ITEM`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await logsApi.getLogsItem(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const unsetLog = createAction(`${SliceNames.LOGS}/UNSET_LOG`);
