import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'components';

export const PartnerPayment = ({ settings, control }) => {
  const { t } = useTranslation();

  if (!settings) return null;

  return (
    <div className="flex flex-col gap-0 col-span-full">
      <span className="text-md">{t('payment methods')}:</span>
      <div className="flex flex-wrap gap-4 my-4">
        {settings.map((setting, index) => {
          const [name, isControllable] = Object.entries(setting)[0];

          return (
            <Controller
              key={index}
              defaultValue=""
              control={control}
              name={name}
              render={({ field: { value, onChange } }) => (
                <Toggle
                  label={t(name)}
                  checked={value}
                  onChange={onChange}
                  wrapperClassname={!isControllable ? 'hidden' : ''}
                />
              )}
            />
          );
        })}
      </div>
    </div>
  );
};
