import cn from 'classnames';
import { Controller } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import { twMerge } from 'tailwind-merge';

import 'react-international-phone/style.css';

export const PhoneField = ({
  control,
  className,
  name,
  label,
  description,
  validation,
  placeholder,
  hideError = false,
  defaultCountry = 'cz',
  required,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
        return (
          <div className={twMerge('flex flex-col', className)}>
            <label className={cn('text-grey-3 text-sm')} htmlFor={name}>
              {label}
              {required && '*'}
            </label>
            <PhoneInput
              {...props}
              {...fieldProps}
              value={value || ''}
              placeholder={placeholder}
              countrySelectorStyleProps={{
                buttonClassName:
                  '!px-2 !w-[40px] !h-full !h-[30px] !border-grey-6 !rounded-l'
              }}
              inputClassName={twMerge(
                cn('rounded w-full !h-[30px] !border-grey-6', {
                  'border border-grey-6': !props?.disabled,
                  'border-0': props?.disabled
                })
              )}
              defaultCountry={defaultCountry}
            />

            {!hideError && error && (
              <span className="block text-error text-xs">{error.message}</span>
            )}
          </div>
        );
      }}
    />
  );
};
