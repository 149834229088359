import { createSelector } from '@reduxjs/toolkit';

const selectNewsState = (state) => state.news;

export const selectNews = createSelector(
  selectNewsState,
  (state) => state.news
);
export const selectNewsCount = createSelector(
  selectNewsState,
  (state) => state.newsCount
);
export const selectNewsItem = createSelector(
  selectNewsState,
  (state) => state.newsItem
);

export const selectNewsIsLoading = createSelector(
  selectNewsState,
  (state) => state.isLoading
);
export const selectIsUpdatedNews = createSelector(
  selectNewsState,
  (state) => state.isUpdatedNews
);
export const selectIsDeletedNews = createSelector(
  selectNewsState,
  (state) => state.isDeletedNews
);
