import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { BANK_ADDRESS_PATTERN, BANK_ITN_PATTERN } from 'constants/index.js';
import { validatePhoneNumber } from 'constants/phone-field.js';

import { usersApi } from 'api';

import { maxLen, num } from 'normalizers';

import { validateEmail } from 'validators';

import { asyncGetSelf, selectUser } from 'store';

import { Button, FlagSelect, Input, Select } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

export const ProfileForm = () => {
  const user = useSelector(selectUser);
  const role = user?.role;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const expiringOptions = [
    {
      label: t('expiring - week'),
      value: '7 days'
    },
    {
      label: t('expiring - month'),
      value: '1 month'
    },
    {
      label: t('expiring - months'),
      value: '3 months'
    }
  ];

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const usr = {
        firstName: '',
        lastName: '',
        companyName: '',
        codeITN: '',
        address: '',
        bankAddress: '',
        lang: '',
        phone: '',
        email: '',
        numberOfPartnership: '',
        expiresSoon: '',
        notificationEmail: ''
      };

      const data = await dispatch(asyncGetSelf()).unwrap();

      Object.keys(usr).forEach((key) => {
        if (data.user[key]) usr[key] = data.user[key];
      });

      return usr;
    }
  });

  const onSubmit = async (data) => {
    try {
      await usersApi.updateSelf(data);

      i18n.changeLanguage(data.lang);
      window.dispatchEvent(new Event('storage'));
      toast.success(t('succesfully updated'));
    } catch (error) {
      toast.error(t(`form error - ${error.key}`));
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-2 gap-y-4 gap-x-16">
      {user?.role.includes('partner') && (
        <span className="col-span-full -mb-2">
          {t('negotiator-number')}: {user?.negotiatorNumber ?? '_'}
        </span>
      )}
      <Input
        required
        name="firstName"
        label={t('first name')}
        error={errors['firstName']?.message}
        {...register('firstName', { required: t('error - required') })}
      />
      <Input
        required
        name="lastName"
        label={t('last name')}
        error={errors['lastName']?.message}
        {...register('lastName', { required: t('error - required') })}
      />
      {role !== 'cashier' && (
        <>
          <Input
            required
            name="companyName"
            label={t('organization name')}
            error={errors['companyName']?.message}
            {...register('companyName', { required: t('error - required') })}
          />
          <Input
            normalize={[num, maxLen(10)]}
            required
            name="codeITN"
            label={t('itn code')}
            error={errors['codeITN']?.message}
            {...register('codeITN', {
              required: t('error - required'),
              pattern: {
                value: BANK_ITN_PATTERN,
                message: t('error - invalid itn')
              }
            })}
          />
          <Input
            required
            name="address"
            label={t('organization address')}
            error={errors['address']?.message}
            {...register('address', { required: t('error - required') })}
          />
          <Input
            required
            name="bankAddress"
            label={t('bank address')}
            error={errors['bankAddress']?.message}
            {...register('bankAddress', {
              required: t('error - required'),
              pattern: {
                value: BANK_ADDRESS_PATTERN,
                message: t('error - invalid bank data')
              }
            })}
          />
        </>
      )}
      <PhoneField
        control={control}
        name="phone"
        label={t('phone')}
        required
        validation={{
          required: t('error - required'),
          validate: validatePhoneNumber
        }}
      />
      <Input
        required
        name="email"
        label={t('email')}
        error={errors['email']?.message}
        {...register('email', {
          required: t('error - required'),
          pattern: validateEmail(t)
        })}
      />
      <Controller
        name="lang"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FlagSelect
            placeholder={t('select language')}
            required
            label={t('standart language')}
            selected={value}
            onSelect={onChange}
            value={value}
          />
        )}
      />
      {role === 'partner-member' ||
        (role === 'partner-agent' && (
          <Controller
            name="expiresSoon"
            control={control}
            rules={{ required: t('error - required') }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={expiringOptions}
                required
                label={t('expiring soon')}
                error={errors['expiresSoon']?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        ))}
      {role?.includes('partner') && (
        <Input
          required
          name="notificationEmail"
          label={t('notify email')}
          error={errors['notificationEmail']?.message}
          {...register('notificationEmail', {
            required: t('error - required'),
            pattern: validateEmail(t)
          })}
        />
      )}
      <div className="mt-4 col-span-full">
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
