import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { downloadFile } from 'utils';

import {
  asyncDownloadCommissionList,
  asyncDownloadFacture,
  asyncDownloadStornoCommissionList,
  asyncGetCommission,
  selectCommission,
  unsetCommission
} from 'store';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange.jsx';

import { Button, GoBack, Link, LinkVariants, Table } from 'components';

import { ComissionInfo, CommissionPaymentForm } from 'modules';

export const Comission = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const comissionData = useSelector(selectCommission);

  const cfg = [
    {
      key: `contractId`,
      header: t('contract number')
    },
    {
      valueFunc: (el) => Math.round(isNaN(+el) ? 0 : +el),
      key: `full`,
      header: t('full')
    },
    {
      valueFunc: (comission) => `${comission} CZK`,
      key: `comission`,
      header: t('comission')
    },
    {
      valueFunc: (percent) => `${Math.floor(percent)}`,
      key: `comissionPercent`,
      header: t('comission percent')
    }
  ];

  const handleDownloadProvizniList = async () => {
    const data = await dispatch(asyncDownloadCommissionList(id)).unwrap();

    downloadFile({
      data: data.payload || data,
      fileName: `provizní-list-${id}.xlsx`,
      fileType: 'application/vnd.ms-excel'
    });
  };
  const handleDownloadStornoProvizniList = async () => {
    const data = await dispatch(asyncDownloadStornoCommissionList(id));
    downloadFile({
      data: data.payload || data,
      fileName: `storno-provizní-list-${id}.xlsx`,
      fileType: 'application/vnd.ms-excel'
    });
  };

  const handleDownloadFacture = async () => {
    const data = await dispatch(asyncDownloadFacture(id));

    downloadFile({
      data: data.payload || data,
      fileName: `faktura-${id}.xlsx`,
      fileType: 'application/vnd.ms-excel'
    });
  };

  useEffect(() => {
    dispatch(asyncGetCommission(id));
  }, []);

  useBeforeRouteChange(() => dispatch(unsetCommission()));

  if (!comissionData)
    return (
      <p className="flex items-center justify-center">{t('missing items')}</p>
    );

  return (
    <div className="flex flex-col gap-4">
      <ComissionInfo />
      <CommissionPaymentForm id={id} />
      <Table config={cfg} items={comissionData?.comissions} />
      <div className="flex gap-2 justify-between">
        <GoBack />
        {(comissionData.status === 'unpaid' ||
          comissionData.status === 'paid') && (
          <Button onClick={handleDownloadProvizniList}>Provizní list</Button>
        )}
        {(comissionData.status === 'refund-requested' ||
          comissionData.status === 'refund-completed') && (
          <Button onClick={handleDownloadStornoProvizniList}>
            Storno provizní list
          </Button>
        )}
        <Button onClick={handleDownloadFacture}>{t('download facture')}</Button>
      </div>
    </div>
  );
};
