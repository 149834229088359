import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { downloadFile, downloadXmlFile } from 'utils/index.js';

import {
  asyncGetOrder,
  asyncGetOrderXML,
  asyncSendValidateClient,
  selectOrder,
  selectOrderIsLoading,
  selectValidations
} from 'store/index.js';

import { Button } from 'components/index.js';

export const OrderAdminActions = ({
  clientIndex,
  orderId,
  hideDownloadInvoice,
  startDate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const order = useSelector(selectOrder);
  const validation = useSelector(selectValidations);
  const isLoading = useSelector(selectOrderIsLoading);
  const dispatch = useDispatch();

  const onDownloadContractClick = async () => {
    if (orderId) {
      const sendStartDate =
        startDate ?? orderData?.clients?.[clientIndex]?.insurance?.start;
      const data = await dispatch(asyncGetOrderXML({ orderId, sendStartDate }));
      const orderData = await dispatch(asyncGetOrder(orderId)).unwrap();

      await downloadFile({
        data: data.payload || data,
        fileName: `smlouva-${orderData?.clients?.[clientIndex]?.insured.contractId}.pdf`,
        fileType: 'application/pdf'
      });

      navigate(
        `${AppRoutes.ORDER_CLIENT}?orderId=${orderId}&clientId=${clientIndex}`
      );
    }
  };

  const onOpenXML = () => {
    if (order?.requestXML) downloadXmlFile(order.requestXML, 'request.xml');
  };

  useEffect(() => {
    dispatch(asyncSendValidateClient({ orderId }));
  }, []);

  if (!validation) return null;

  return (
    <>
      {Object.keys(validation[clientIndex] || {}).length === 0 && (
        <>
          {!hideDownloadInvoice && (
            <Button
              type="button"
              onClick={onDownloadContractClick}
              isLoading={isLoading}>
              {t('order backdate')}
            </Button>
          )}
          {order?.requestXML && (
            <Button type="button" onClick={onOpenXML}>
              {t('open xml')}
            </Button>
          )}
        </>
      )}
    </>
  );
};
