import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  asyncGetClients,
  asyncGetDashboard,
  selectClients,
  selectStats,
  selectUser
} from 'store';

import { useModal } from 'hooks';

import { Button, Modal, Portal } from 'components';

import { ClientsList } from '../Clients/ClientsList.jsx';
import { ContactManager } from './ContactManager.jsx';
import { DashboardCommissions } from './DashboardCommissions.jsx';

export const DashboardStatistics = ({ role = 'admin', name }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();

  const dispatch = useDispatch();

  const stats = useSelector(selectStats);
  const clients = useSelector(selectClients);

  const currency = 'CZK';

  useEffect(() => {
    if (role.includes('partner')) {
      dispatch(asyncGetClients());
    }
    dispatch(asyncGetDashboard());
  }, [dispatch]);

  const statsArr = () => [
    {
      fields: [
        {
          roles: ['partner-agent', 'partner-member', 'admin', 'sales-manager'],
          stat: 'all orders',
          value: stats.allOrders
        },
        {
          roles: ['cashier'],
          stat: 'factures for pay',
          value: stats.facturesToPay
        },
        {
          roles: [
            'partner-agent',
            'partner-member',
            'admin',
            'cashier',
            'sales-manager'
          ],
          stat: 'unpaid orders',
          value: stats.unpaidOrders
        },
        {
          roles: ['partner-agent', 'partner-member', 'admin', 'sales-manager'],
          stat: 'sold insurance',
          value: stats.soldInsurances
        }
      ]
    },
    {
      fields: [
        {
          roles: ['partner-agent', 'partner-member', 'admin', 'sales-manager'],
          stat: 'paid comissions',
          value: stats.paidCommissionsSum,
          sum: true,
          currency
        },
        {
          roles: [
            'partner-agent',
            'partner-member',
            'admin',
            'cashier',
            'sales-manager'
          ],
          stat: 'comissions payments sum',
          value: stats.comissionsForPaySum,
          sum: true,
          currency
        },
        {
          roles: ['partner-agent', 'partner-member', 'admin', 'cashier'],
          stat: 'unpaid orders sum',
          value: stats.unpaidOrdersSum,
          sum: true,
          currency
        },
        {
          roles: ['partner-agent', 'partner-member', 'admin', 'sales-manager'],
          stat: 'all orders price',
          value: stats.allOrdersSum,
          sum: true,
          currency
        }
      ]
    }
  ];

  if (!stats) return null;
  return (
    <div className="text-grey-1">
      <div className="text-xl flex justify-between items-center">
        {t('hello user', { name })}
        {role.includes('partner') && clients && (
          <Button onClick={handleOnOpenModal} className="text-base">
            {t('manager connect')}
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-6">
        <div className="mt-4 flex flex-row gap-4">
          {statsArr()?.map(({ fields }, idx) => (
            <div key={idx} className="flex flex-col flex-1">
              {fields.map(
                ({ stat, value, sum, roles, currency }) =>
                  roles.includes(role) && (
                    <span key={stat} className="text-base">
                      {t(`home - stats - ${stat}`, {
                        value: sum ? Number(value || 0).toFixed(2) : value || 0,
                        currency
                      })}
                    </span>
                  )
              )}
              {idx > 0 &&
                (user?.isTrustedPartner ||
                  role.includes('cashier') ||
                  role.includes('admin')) && (
                  <>
                    <span>
                      {t('orders to pay')}{' '}
                      <span className="text-error">
                        {stats?.ordersToPayLater}
                      </span>
                    </span>
                    <span>
                      {t('sum to pay')}{' '}
                      <span className="text-error">
                        {stats?.ordersToPayLaterSum} {currency}
                      </span>
                    </span>
                  </>
                )}
            </div>
          ))}
        </div>
        {role.includes('partner') && clients && (
          <div className="flex flex-col gap-4">
            <DashboardCommissions />
            {clients && <ClientsList list={clients} />}
            <Portal wrapperId="portal-modal">
              <Modal isOpen={isModalOpened} handleClose={handleOnCloseModal}>
                <ContactManager handleOnCloseModal={handleOnCloseModal} />
              </Modal>
            </Portal>
          </div>
        )}
      </div>
    </div>
  );
};
