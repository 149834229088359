import cs from 'classnames';
import Select from 'react-select/creatable';

const styles = {
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 8px'
  }),
  control: (styles) => ({
    ...styles,
    minHeight: '0'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0'
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '0'
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: '0'
  }),
  multiValue: (styles) => ({
    ...styles,
    padding: '0'
  })
};

export const CreatableSelect = ({
  options,
  required,
  wrapperClassname,
  name,
  label,
  error,
  hideError = false,
  disabled,
  value,
  onChange,
  ...props
}) => {
  return (
    <div className={cs('flex flex-col', wrapperClassname)}>
      <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <Select
        isOptionDisabled={(option) => option.disabled}
        isDisabled={disabled}
        value={value}
        placeholder=" "
        styles={styles}
        options={options?.includes(undefined) ? [] : options}
        name={name}
        onChange={onChange}
        {...props}
      />
      {!hideError && error && (
        <span className="block text-error text-xs">{error}</span>
      )}
    </div>
  );
};
