import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import {
  asyncGetNews,
  selectNews,
  selectNewsCount,
  selectNewsIsLoading,
  selectUser
} from 'store';

import { useFilter, usePagination, useQueryParams, useSearch } from 'hooks';

import { Button, Input, Loader, Pagination, Select } from 'components';

import { AdminNews, PartnerNews } from 'modules';

export const News = () => {
  const [searchParams] = useSearchParams();
  const queryFilter = searchParams.get('filter');

  const { filterOptions, selectedFilter, setSelectedFilter, setFilterOptions } =
    useFilter();

  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const dispatch = useDispatch();

  const list = useSelector(selectNews);
  const count = useSelector(selectNewsCount);
  const isLoading = useSelector(selectNewsIsLoading);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const role = user?.role;

  const getNews = async (filter) => {
    const isStatusAll = filter === 'all' || filter === 'mine';
    const isMine = filter === 'mine';
    const selectedFilterStatus = isStatusAll ? '' : filter;

    const queryParams = {
      page,
      search,
      status: role?.includes('partner') ? 'published' : selectedFilterStatus,
      author: isMine ? user._id : ''
    };

    const data = await dispatch(asyncGetNews(queryParams)).unwrap();

    setFilterOptions(
      Object.keys(data.counts).map((key) => ({
        label: `${t(key)}(${data.counts[key]})`,
        value: key
      }))
    );
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleOnFilterChange = (e) => {
    setSelectedFilter(e.target);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      getNews(selectedFilter?.value ?? queryFilter);
    }
  };

  const handleToCreate = () => {
    navigate(AppRoutes.NEWS_CREATE);
  };

  const chooseList = () => {
    switch (role) {
      case 'admin':
      case 'sales-manager':
        return <AdminNews reload={getNews} list={list} />;
      default:
        return <PartnerNews list={list} />;
    }
  };

  useQueryParams({
    page: page + 1,
    search,
    filter: selectedFilter?.value ?? queryFilter
  });

  useEffect(() => {
    getNews(selectedFilter?.value ?? queryFilter);
  }, [page, selectedFilter]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-row justify-between items-end">
            <div className="flex gap-2 flex-row justify-between items-end w-full">
              <Input
                onKeyPress={handleSubmitSearch}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                hideError
                label={t('search')}
              />

              {(role === 'admin' || role === 'sales-manager') && (
                <>
                  {filterOptions ? (
                    <Select
                      wrapperClassname="w-1/5"
                      label={t('status')}
                      defaultValue={filterOptions?.find(
                        ({ value }) => value === queryFilter
                      )}
                      value={selectedFilter}
                      onChange={handleOnFilterChange}
                      options={filterOptions}
                    />
                  ) : (
                    ''
                  )}

                  <Button onClick={handleToCreate}>{t('create news')}</Button>
                </>
              )}
            </div>
          </div>
          {!list?.length ? (
            <p className="flex items-center justify-center">
              {t('missing items')}
            </p>
          ) : (
            chooseList()
          )}
          <Pagination
            page={page}
            onPageChange={handlePageClick}
            count={count}
          />
        </>
      )}
    </div>
  );
};
