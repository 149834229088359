import cs from 'classnames';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUser } from 'store/index.js';

import { Card } from 'components';

export const PartnerNews = ({ list }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const handleCardClick = (id) => (e) => {
    e.preventDefault();

    navigate(`/news/${id}`);
  };

  return (
    <div className="flex flex-col gap-4 my-4">
      {list.map((el) => {
        const lng = i18n.language.split('-')[0]?.toLocaleUpperCase();

        let _lng = '';
        switch (lng) {
          case 'EN':
          case 'UK':
          case 'US':
            _lng = 'US';
            break;
          case 'CZ':
            _lng = 'CZ';
            break;
          default:
            _lng = 'RU';
            break;
        }

        let content = (el[`content${_lng}`] || '')?.slice(0, 300);
        if (content.length === 300) content = content.concat('...');

        const title = el[`title${_lng}`];

        return (
          <Fragment key={el._id}>
            <hr />
            <Card
              onClick={handleCardClick(el._id)}
              className={cs('p-6 cursor-pointer', {
                '!bg-blue-3': !el?.readenBy?.includes(user?._id)
              })}>
              <div className="text-blue-1">{title}</div>
              <div
                className="mt-4"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Card>
          </Fragment>
        );
      })}
    </div>
  );
};
