import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { max, num } from 'normalizers';

import {
  asyncGetOrder,
  asyncSendCancelClient,
  selectIsCancelSend,
  unsetIsCancelSend
} from 'store';

import { Button, Input } from 'components';

export const CancelContractForm = ({ handleClose, price }) => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const isCancelSend = useSelector(selectIsCancelSend);

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const onSubmit = async (data) => {
    const orderId = searchParams.get('orderId');
    const clientId = searchParams.get('clientId');

    if (orderId && clientId) {
      await dispatch(
        asyncSendCancelClient({
          orderId,
          client: {
            clientIdx: clientId,
            refund: data.refund
          }
        })
      );
      await dispatch(asyncGetOrder(orderId));
    }
  };

  useEffect(() => {
    if (isCancelSend) {
      dispatch(unsetIsCancelSend());
      handleClose();
    }
  }, [isCancelSend]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[500px] flex flex-col gap-4 p-12"
    >
      <Input
        error={errors['refund']?.message}
        normalize={[num, max(price)]}
        label={t('client refund')}
        required
        {...register('refund', {
          required: t('error - required')
        })}
      />
      <div className="flex gap-2">
        <Button type="button" onClick={handleClose} className="flex-1">
          {t('go back')}
        </Button>
        <Button className="flex-1">{t('request refund')}</Button>
      </div>
    </form>
  );
};
