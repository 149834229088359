import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useBeforeUnload, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { clearOrderForm } from 'constants/clear-form.js';
import { AppRoutes } from 'constants/index.js';

import {
  asyncGetOrder,
  asyncGetUsers,
  asyncOrderVoucher,
  asyncSendCalculateClient,
  asyncSendValidateClient,
  asyncUpdateOrder,
  selectIsUpdatedOrder,
  selectUser,
  selectValidations,
  unsetGovUser
} from 'store';
import { selectOrder } from 'store';
import { unsetOrder } from 'store';

import { useModal } from 'hooks';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange.jsx';

import { YesNoModal } from 'modules';
import { OrderActions } from 'modules/Orders/FormParts/OrderActions.jsx';
import { OrderAdditionalFields } from 'modules/Orders/FormParts/OrderAdditionalFields.jsx';
import { OrderClients } from 'modules/Orders/FormParts/OrderClients.jsx';
import { OrderFields } from 'modules/Orders/FormParts/OrderFields.jsx';
import { OrderStatus } from 'modules/Orders/FormParts/OrderStatus.jsx';

export const OrderForm = ({ id }) => {
  const order = useSelector(selectOrder);
  const user = useSelector(selectUser);

  const isFormDisabled = user?.role === 'admin';

  const isOrderSynced =
    order?.isSync !== null && order?.isSync !== undefined && !order?.isSync;
  const isCorrectStatus =
    order?.status === 'axa-pay-error' ||
    order?.status === 'bad-axa-connection' ||
    order?.status === 'axa-id-exists' ||
    order?.status === 'negotiation-number-error';

  const [clientIndex, setClientIndex] = useState(-1);
  const [axaError, setAxaError] = useState(false);

  const [voucherOrder, setVoucherOrder] = useState({
    voucher: '',
    orderId: '',
    clientIdx: ''
  });

  const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();

  const clientId = +sessionStorage.getItem('clientIndex');
  const [editing, setEditing] = useState(clientId ?? 0);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const validations = useSelector(selectValidations);
  const isUpdatedOrder = useSelector(selectIsUpdatedOrder);

  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const order = {
        clients: [],
        isSeal: true,
        mediator: ''
      };

      if (id) {
        const orderData = await dispatch(asyncGetOrder(id)).unwrap();

        if (orderData.status !== 'draft') {
          navigate(AppRoutes.ORDERS);
          return;
        }

        order.clients = orderData.clients.map(({ prices: _, ...el }) => el);
        order.isSeal = orderData?.isSeal ?? true;
        order.mediator = orderData?.mediatorFather?._id;
      }

      if (!order.clients.length) order.clients.push(clearOrderForm);

      return order;
    }
  });

  const { fields, append, remove, update } = useFieldArray({
    name: 'clients',
    control: methods.control
  });

  const deleteClient = async () => {
    remove(clientIndex);

    dispatch(
      asyncUpdateOrder({
        id,
        payload: {
          clients: fields
            .slice(0, clientIndex)
            .concat(fields.slice(clientIndex + 1))
        }
      })
    )
      .unwrap()
      .then(() => handleOnCloseModal());
  };

  const saveClient = async () => {
    const data = methods.getValues().clients[editing].insurance;
    if (data?.type) {
      await dispatch(asyncSendCalculateClient({ orderId: id, ...data }));
    }
  };

  const onCreateUser = async () => {
    append(clearOrderForm);
    dispatch(asyncUpdateOrder({ id, payload: methods.getValues() }))
      .unwrap()
      .then(({ data }) => setEditing(data.order.clients.length - 1));

    await saveClient();

    setTimeout(() => {
      dispatch(asyncSendValidateClient({ orderId: id }));
    }, 100);
  };

  const onSubmit = async ({ mediator, ...data }) => {
    if (voucherOrder?.voucher) {
      await dispatch(asyncOrderVoucher(voucherOrder));
    }

    await dispatch(
      asyncUpdateOrder({
        id,
        payload: {
          isSync: !axaError,
          mediator:
            typeof mediator === 'object' && 'target' in mediator
              ? null
              : mediator,
          ...data
        }
      })
    );

    await dispatch(asyncSendValidateClient({ orderId: id }));

    toast.success(t('client was successfully saved'), { autoClose: 3000 });

    const client = data.clients?.[editing] || clearOrderForm;

    update(editing, client);

    if (user?.role !== 'admin') {
      setEditing(null);
    }
  };

  useEffect(() => {
    dispatch(asyncGetUsers({ roles: ['partner-agent'], active: true }));
    if (id) {
      dispatch(asyncSendValidateClient({ orderId: id }));
    }
  }, [id, dispatch]);

  useBeforeUnload(async () => {
    dispatch(asyncUpdateOrder({ id, payload: methods.getValues() }));
    await saveClient();
  });

  useEffect(() => {
    if (isUpdatedOrder) {
      handleOnCloseModal();
    }
  }, [isUpdatedOrder]);

  useEffect(() => {
    if (clientId) {
      setEditing(+clientId);
    }
  }, [clientId]);

  useBeforeRouteChange(() => {
    dispatch(unsetGovUser());
    dispatch(unsetOrder());
  });

  if (!validations) return null;
  return (
    <FormProvider {...methods}>
      {typeof editing === 'number' && (
        <OrderAdditionalFields
          isFormDisabled={isFormDisabled}
          control={methods.control}
          watch={methods.watch}
        />
      )}
      <OrderStatus
        orderId={order?.id}
        isSync={order?.isSync}
        isCorrectStatus={isCorrectStatus}
        isOrderSynced={isOrderSynced}
      />
      <OrderClients
        isFormDisabled={isFormDisabled}
        fields={fields}
        validations={validations}
        setClientIndex={setClientIndex}
        setEditing={setEditing}
        handleOnOpenModal={handleOnOpenModal}
      />
      <OrderActions
        isFormDisabled={isFormDisabled}
        orderId={id}
        onCreateUser={onCreateUser}
        axaError={axaError}
      />
      <form
        autoComplete="off"
        onSubmit={methods.handleSubmit(onSubmit)}
        className="flex flex-col gap-12 mt-8">
        {fields.map(
          (el, idx) =>
            editing === idx && (
              <OrderFields
                isFormDisabled={isFormDisabled}
                key={el.id}
                setAxaError={setAxaError}
                setVoucherOrder={setVoucherOrder}
                watch={methods.watch}
                control={methods.control}
                axaError={axaError}
                orderId={id}
                editing={editing}
                errors={methods.formState.errors}
                index={idx}
              />
            )
        )}
      </form>
      <YesNoModal
        isOpen={isModalOpened}
        onApprove={deleteClient}
        question={t('delete client')}
        approveLabel={t('yes')}
        declineLabel={t('no')}
        handleClose={handleOnCloseModal}
        onDecline={handleOnCloseModal}
      />
    </FormProvider>
  );
};
