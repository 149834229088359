import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicExtended from 'ckeditor5-build-classic-extended';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCompany, selectSettings } from 'store';

import { Button, Input } from 'components';

export const EmailForm = ({ id, onSubmit, isSettings }) => {
  const { t } = useTranslation();
  const company = useSelector(selectCompany);
  const settings = useSelector(selectSettings);

  const names = {
    body: isSettings ? 'insuredEmailSubject' : 'emailSubject',
    email: isSettings ? 'insuredEmailBody' : 'emailBody'
  };

  const {
    trigger,
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign(
      {},
      ...Object.values(names).map((value) => ({ [value]: '' }))
    )
  });

  useEffect(() => {
    if (!isSettings && id === company?._id) {
      reset(
        Object.keys(defaultValues)?.reduce((acc, key) => {
          return { ...acc, [key]: company[key] };
        }, {})
      );
    }

    if (isSettings) {
      reset(
        Object.keys(defaultValues)?.reduce((acc, key) => {
          return { ...acc, [key]: settings[key] };
        }, {})
      );
    }
  }, [id, company?._id]);

  return (
    <div className="email">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <Input
            wrapperClassname=""
            name={names.body}
            label={t('companies - subject')}
            required
            error={errors[names.body]?.message}
            {...register(names.body, {
              required: { value: true, message: t('error - required') }
            })}
          />
          <div>
            <CKEditor
              config={{
                fontSize: {
                  options: [
                    9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                    24, 25, 26
                  ]
                },
                fontColor: {
                  colors: [
                    {
                      color: 'rgb(0, 0, 143)',
                      label: 'Axa Blue'
                    },
                    { color: 'hsl(0, 0%, 20%)', label: 'Dark Gray' },
                    { color: 'hsl(0, 0%, 40%)', label: 'Gray' },
                    { color: 'hsl(0, 0%, 60%)', label: 'Light Gray' },
                    { color: 'hsl(0, 0%, 80%)', label: 'Very Light Gray' },
                    { color: 'hsl(240, 100%, 50%)', label: 'Blue' },
                    { color: 'hsl(120, 100%, 50%)', label: 'Green' },
                    { color: 'hsl(60, 100%, 50%)', label: 'Yellow' },
                    { color: 'hsl(300, 100%, 50%)', label: 'Magenta' },
                    { color: 'hsl(180, 100%, 50%)', label: 'Cyan' },
                    { color: 'hsl(30, 100%, 50%)', label: 'Orange' },
                    { color: 'hsl(0, 0%, 90%)', label: 'Lightest Gray' },
                    { color: 'hsl(0, 0%, 10%)', label: 'Very Dark Gray' },
                    { color: 'hsl(45, 100%, 50%)', label: 'Reddish Orange' },
                    { color: 'hsl(270, 100%, 50%)', label: 'Purple' },
                    { color: 'hsl(210, 100%, 50%)', label: 'Blueish Purple' },
                    { color: 'hsl(330, 100%, 50%)', label: 'Pink' },
                    { color: 'hsl(15, 100%, 50%)', label: 'Warm Orange' },
                    { color: 'hsl(195, 100%, 50%)', label: 'Cool Blue' },
                    { color: 'hsl(90, 100%, 50%)', label: 'Bright Green' },
                    { color: 'hsl(270, 100%, 25%)', label: 'Dark Purple' }
                  ]
                },
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'fontFamily',
                    'fontSize',
                    '|',
                    'alignment',
                    'insertImage',
                    '|',
                    'fontColor',
                    'fontBackgroundColor',
                    '|',
                    'bold',
                    'italic',
                    'strikethrough',
                    'underline',
                    '|',
                    'link',
                    '|',
                    'outdent',
                    'indent',
                    '|',
                    'bulletedList',
                    'numberedList',
                    '|',
                    'insertTable',
                    '|',
                    'blockQuote',
                    '|',
                    'undo',
                    'redo',
                    'insertImageViaUrl'
                  ]
                }
              }}
              editor={ClassicExtended}
              data={
                defaultValues[names.email] ? defaultValues[names.email] : ''
              }
              onChange={(event, editor) => {
                setValue(names.email, editor.getData());
                trigger(names.email);
              }}
            />
            {!isSettings && (
              <p className="text-sm text-grey-3 mt-2 ">
                {t('companies - body hint')}
              </p>
            )}
          </div>
        </div>
        <Button type="submit" className="mt-4 w-fit">
          {t('save')}
        </Button>
      </form>
    </div>
  );
};
