import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { max, num } from 'normalizers';

import {
  asyncGetCompanies,
  asyncGetPartner,
  asyncUpdatePartner,
  selectCompanies
} from 'store';

import { Button, Input, Select } from 'components';

import AddSrc from 'svg/add.svg';
import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

export const PartnerProductsForm = ({ id }) => {
  const companies = useSelector(selectCompanies);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const partner = {
        products: []
      };

      if (!id) return;
      const data = await dispatch(asyncGetPartner(id)).unwrap();

      Object.keys(partner).forEach((key) => {
        if (data[key]) partner[key] = data[key];
      });

      return partner;
    }
  });

  const products = watch('products');

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'products'
  });
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    dispatch(asyncUpdatePartner({ id, data }));
  };

  const headers = [
    { header: t('company') },
    { header: t('product') },
    { header: t('discount percent') }
  ];

  const checkErrors = (index) => {
    const product = watch(`products`)[index];

    if (!product.company)
      setError(`products.${index}.company`, {
        type: 'required',
        message: t('error - required')
      });
    if (!product.product)
      setError(`products.${index}.product`, {
        type: 'required',
        message: t('error - required')
      });
    if (!product.discount && product.discount !== 0)
      setError(`products.${index}.discount`, {
        type: 'required',
        message: t('error - required')
      });

    return errors?.products?.[index];
  };
  const handleSaveProduct = (index) => () => {
    if (checkErrors(index)) return;

    setIsEditing(false);
  };
  const handleEditProduct = (index) => () => {
    if (isEditing !== false && checkErrors(isEditing)) return;

    setIsEditing(index);
  };
  const handleDeleteProduct = (index) => () => remove(index);
  const handleAddProduct = () => {
    append({
      company: '',
      product: '',
      discount: ''
    });
    const idx = getValues()?.products?.length - 1;

    setIsEditing(idx);
  };

  useEffect(() => {
    dispatch(asyncGetCompanies());
  }, [dispatch]);

  if (!companies) return null;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      {!products || !products.length ? (
        <p className="flex items-center justify-center">{t('missing items')}</p>
      ) : (
        <div className="w-full">
          <label className="text-grey-3 text-sm">{t('products')}*</label>
          <table className="table-auto w-full">
            <thead>
              <tr className="border">
                {headers.map(({ header }) => (
                  <th className="text-left text-sm p-2" key={header}>
                    {header}
                  </th>
                ))}
                <th className="text-left text-sm p-2">{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => {
                const _companies = companies.map(({ _id, organization }) => ({
                  value: _id,
                  label: organization
                }));
                const selectedCompany = companies.find(
                  (el) => el._id === watch(`products.${index}.company`)
                );
                const selectedProducts = watch('products').map(
                  ({ product }) => product
                );

                watch(`products.${index}.product`);
                const products = (selectedCompany?.products || []).map(
                  ({ _id, name }) => ({
                    value: _id,
                    label: name
                  })
                );

                return (
                  <tr className="border" key={field.id}>
                    <td className="text-left text-sm p-2 w-1/4">
                      <Controller
                        control={control}
                        name={`products.${index}.company`}
                        rules={{ required: t('error - required') }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              name={`products.${index}.company`}
                              disabled={isEditing !== index}
                              options={_companies}
                              onChange={(e) => {
                                update(index, { product: '' });

                                return onChange(e);
                              }}
                              value={value}
                            />
                          );
                        }}
                      />
                    </td>
                    <td className="text-left text-sm p-2 w-1/4">
                      <Controller
                        control={control}
                        name={`products.${index}.product`}
                        rules={{ required: t('error - required') }}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              isOptionDisabled={(option) =>
                                selectedProducts.includes(option.value)
                              }
                              name={`products.${index}.product`}
                              disabled={
                                !products?.length || isEditing !== index
                              }
                              options={products}
                              onChange={onChange}
                              value={value}
                            />
                          );
                        }}
                      />
                    </td>
                    <td className="text-left text-sm p-2 w-1/4">
                      <Input
                        normalize={[num, max(100)]}
                        disabled={isEditing !== index}
                        label={''}
                        {...register(`products.${index}.discount`, {
                          required: t('error - required')
                        })}
                      />
                    </td>
                    <td className="text-left text-sm p-2 w-1/4">
                      {isEditing === index && (
                        <Button onClick={handleSaveProduct(index)}>
                          {t('save')}
                        </Button>
                      )}
                      {isEditing !== index && (
                        <div className="flex flex-row">
                          <img
                            onClick={handleEditProduct(index)}
                            className="cursor-pointer pr-2"
                            src={EditSrc}
                            alt="edit"
                          />
                          <img
                            onClick={handleDeleteProduct(index)}
                            className="cursor-pointer pl-2"
                            src={DeleteSrc}
                            alt="delete"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="w-1/4"></td>
                <td className="w-1/4"></td>
                <td className="w-1/4"></td>
              </tr>
            </tbody>
          </table>
          {errors['types']?.message && (
            <span className="text-xs text-error">
              {errors['types']?.message}
            </span>
          )}
        </div>
      )}
      {isEditing === false && (
        <div className="flex items-center justify-end">
          <img
            src={AddSrc}
            onClick={handleAddProduct}
            className="cursor-pointer h-[30px] w-[30px]"
            alt="add"
          />
        </div>
      )}
      <div>
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
