import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCountriesArr } from 'utils';

import { maxLen, num } from 'normalizers';

import { selectUser } from 'store/index.js';

import { Input, Select } from 'components';

export const CorrespondentAddress = ({
  index,
  prefix = '',
  isFormDisabled
}) => {
  const { t } = useTranslation();
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  const user = useSelector(selectUser);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (!user) return;
    setCountries(getCountriesArr(user.lang));
  }, [user]);

  return (
    <>
      <Input
        disabled={isFormDisabled}
        label={t('street') + '*'}
        {...register(`clients.${index}.${prefix}.correspondent.street`)}
      />
      <Input
        disabled={isFormDisabled}
        normalize={[num, maxLen(10)]}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.[prefix]?.correspondent?.index?.message
            : undefined
        }
        label={t('index') + '*'}
        {...register(`clients.${index}.${prefix}.correspondent.index`, {
          minLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          },
          maxLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          }
        })}
      />
      <Input
        disabled={isFormDisabled}
        label={t('city') + '*'}
        {...register(`clients.${index}.${prefix}.correspondent.city`)}
      />
      <Controller
        name={`clients.${index}.${prefix}.correspondent.country`}
        control={control}
        defaultValue="Česká republika"
        render={({ field: { onChange } }) => (
          <Select
            disabled={isFormDisabled}
            defaultValue="Česká republika"
            options={countries}
            label={t('country') + '*'}
            value="Česká republika"
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
