import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutes } from 'constants/index.js';

import {
  asyncCreateNews,
  asyncDeleteNews,
  asyncGetNewsItem,
  asyncUpdateNews,
  selectIsDeletedNews,
  selectIsUpdatedNews,
  selectUser,
  unsetIsUpdatedNewsAction
} from 'store';

import {
  Button,
  Datepicker,
  EditorHtml,
  FlagSelect,
  Input,
  Select
} from 'components';

export const NewsCreateForm = ({ id }) => {
  const [lang, setLang] = useState('US');
  const [authorsList, setAuthorsList] = useState([]);

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const isUpdatedNews = useSelector(selectIsUpdatedNews);
  const isDeletedNews = useSelector(selectIsDeletedNews);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    reset,
    control,
    watch,
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, defaultValues }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const newsletter = {
        publishDate: moment().valueOf(),
        status: '',
        titleUS: '',
        titleRU: '',
        titleCZ: '',
        contentUS: '',
        contentRU: '',
        contentCZ: ''
      };

      if (id) {
        const newsData = await dispatch(asyncGetNewsItem(id)).unwrap();

        Object.keys(newsletter).forEach((key) => {
          if (newsData[key] || newsData[key] === 0)
            newsletter[key] = newsData[key];
        });
      }
      return newsletter;
    }
  });

  const flags = ['US', 'RU', 'CZ'];

  const isValid = () => {
    const values = getValues();

    const check = Object.keys(values)
      .filter((el) =>
        [
          'titleUS',
          'titleRU',
          'titleCZ',
          'contentUS',
          'contentRU',
          'contentCZ'
        ].includes(el)
      )
      .map((key) => Boolean(values[key]));
    const hasEmpty = check.some((el) => !el);

    return !hasEmpty;
  };

  const onSubmit = async (data) => {
    if (isValid()) {
      dispatch(
        id ? asyncUpdateNews({ id, payload: data }) : asyncCreateNews(data)
      );
    } else {
      toast.error(t('error - fill fields for all languages'));
    }
  };

  useEffect(() => {
    if (isUpdatedNews || isDeletedNews) {
      dispatch(unsetIsUpdatedNewsAction());
      navigate(AppRoutes.NEWS);
    }
  }, [isUpdatedNews, isDeletedNews]);

  useEffect(() => {
    if (!user) return;

    const author = {
      value: user._id,
      label:
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.email
    };
    setAuthorsList([author]);

    reset({
      author: user._id
    });
  }, [reset, user]);

  const handleDelete = async () => {
    dispatch(asyncDeleteNews(id));
  };

  const status = watch('status');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-y-4 gap-x-16">
        <Controller
          name="publishDate"
          control={control}
          defaultValue={defaultValues?.[`publishDate`] ?? new Date()}
          rules={{ required: t('error - required') }}
          render={({ field: { onChange, value } }) => (
            <Datepicker
              defaultValue={defaultValues?.[`publishDate`]}
              name="publishDate"
              label={t('newsletter publish date')}
              required
              error={errors['publishDate']?.message}
              onChange={onChange}
              value={value}
            />
          )}
        />
        {authorsList.length ? (
          <Controller
            name="author"
            control={control}
            rules={{ required: t('error - required') }}
            defaultValue={authorsList[0].value}
            render={({ field: { onChange, value } }) => (
              <Select
                defaultValue={authorsList[0]}
                error={errors['author']?.message}
                disabled={!authorsList?.length}
                options={authorsList}
                required
                label={t('newsletter author')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        ) : (
          ''
        )}
        <FlagSelect
          placeholder={' '}
          required
          wrapperClassname="row-start-2"
          label={t('select language')}
          selected={lang}
          onSelect={setLang}
          value={lang}
        />
        <Controller
          name="status"
          control={control}
          rules={{ required: t('error - required') }}
          render={({ field: { onChange, value } }) => (
            <Select
              wrapperClassname="row-start-2"
              error={errors['status']?.message}
              options={[
                { value: 'published', label: t('published') },
                { value: 'draft', label: t('draft') },
                { value: 'planned', label: t('planned') }
              ]}
              required
              label={t('status')}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      {flags.map((code) => {
        return (
          code === lang && (
            <div className="flex flex-col gap-4 mt-4">
              <Input
                name={`title${code}`}
                wrapperClassname="col-span-full"
                label={t('newsletter title')}
                error={errors[`title${code}`]?.message}
                required
                {...register(`title${code}`, {
                  required: t('error - required')
                })}
              />
              <Controller
                control={control}
                name={`content${code}`}
                rules={{ required: t('error - required') }}
                render={({ field: { onChange, value } }) => (
                  <EditorHtml
                    wrapperClassname="col-span-full"
                    defaultValue={defaultValues?.[`content${code}`]}
                    onChange={onChange}
                    onBlur={() => trigger(`content${code}`)}
                    required
                    value={value}
                    label={t('newsletter content')}
                    name={`content${code}`}
                    error={errors[`content${code}`]?.message}
                  />
                )}
              />
            </div>
          )
        );
      })}
      <div className="flex flex-row gap-4 justify-between col-span-full mt-4">
        <Button type="submit">{t('save')}</Button>
        {id && status === 'deleted' && (
          <Button type="button" onClick={handleDelete}>
            {t('delete')}
          </Button>
        )}
      </div>
    </form>
  );
};
