import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { downloadXmlFile } from 'utils/index.js';

import { asyncGetLogsItem, unsetLog } from 'store/loggs/actions.js';
import { selectLogsIsLoading, selectLogsItem } from 'store/loggs/selectors.js';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange.jsx';

import { Button } from 'components/index.js';

import { LogsInfo } from 'modules/Logs/LogsInfo.jsx';

export const Log = () => {
  const { id } = useParams();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const log = useSelector(selectLogsItem);
  const isLoading = useSelector(selectLogsIsLoading);

  const logTexts = [
    { id: 1, title: 'description', value: log?.actionDesc },
    { id: 2, title: 'type', value: log?.actionType },
    {
      id: 3,
      title: 'date',
      value: moment(log?.date).format('DD.MM.YYYY HH:mm')
    },
    { id: 4, title: 'firstName', value: log?.user?.firstName },
    { id: 5, title: 'lastName', value: log?.user?.lastName }
  ];

  const handleDownloadXml = () => {
    if (log?.requestXml) downloadXmlFile(log.requestXml, 'AXA.xml');
  };

  useEffect(() => {
    dispatch(asyncGetLogsItem(id));
  }, [dispatch, id]);

  useBeforeRouteChange(() => dispatch(unsetLog()));

  if (isLoading || !log) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <span>Info</span>
          <Button onClick={handleDownloadXml}>
            {t('download axa xml request')}
          </Button>
        </div>

        <div className="border-2 rounded-lg p-4 flex flex-col gap-4">
          {logTexts.map(({ id, title, value }) => (
            <div key={id} className="flex justify-between border-b-2">
              <span>{t(title)}</span>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </div>
      <LogsInfo log={log} />
    </div>
  );
};
