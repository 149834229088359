import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'components';

import { CorrespondentAddress } from '../SubForms/CorrespondentAddress';
import { LegalEntity } from '../SubForms/LegalEntity';
import { NonLegalEntity } from '../SubForms/NonLegalEntity';

export const Insurer = ({ index, isFormDisabled }) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  const isLegal = watch(`clients.${index}.insurer.isLegalEntity`);
  const isSameAddress = watch(`clients.${index}.insurer.addressesMatch`);

  return (
    <div className="grid grid-cols-2 gap-x-16 gap-y-4">
      <div className="flex flex-col col-span-full">
        <span className="text-lg">{t('insurer')}</span>
        <Controller
          defaultValue=""
          control={control}
          name={`clients.${index}.insurer.isLegalEntity`}
          render={({ field: { value, onChange } }) => (
            <Toggle
              disabled={isFormDisabled}
              name={`clients.${index}.insurer.isLegalEntity`}
              label={t('legal entity')}
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      {isLegal ? (
        <LegalEntity index={index} isFormDisabled={isFormDisabled} />
      ) : (
        <NonLegalEntity index={index} isFormDisabled={isFormDisabled} />
      )}

      <Controller
        defaultValue=""
        control={control}
        name={`clients.${index}.insurer.addressesMatch`}
        render={({ field: { value, onChange } }) => (
          <Toggle
            disabled={isFormDisabled}
            wrapperClassname="col-span-full"
            name={`clients.${index}.insurer.addressesMatch`}
            label={t('addresses match')}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      {!isSameAddress && (
        <CorrespondentAddress
          index={index}
          prefix="insurer"
          isFormDisabled={isFormDisabled}
        />
      )}
    </div>
  );
};
