import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import { getClientsReducer } from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.CLIENTS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getClientsReducer(builder);
  }
});

export const clientsReducer = slice.reducer;
