import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { asyncGetSettings, selectUser } from 'store';

import { EditorHtml } from 'components/index.js';

export const VouchersFaq = () => {
  const [faqText, setFaqText] = useState({});
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const getSettings = async () => {
    const settings = await dispatch(asyncGetSettings()).unwrap();
    setFaqText({
      CZ: settings.textVoucherCZ,
      RU: settings.textVoucherRU,
      US: settings.textVoucherUS
    });
  };

  useEffect(() => {
    getSettings();
  }, []);

  if (!faqText || user?.role === 'admin' || user?.role === 'sales-manager')
    return null;
  return (
    <div className="flex flex-col gap-2 p-2 border border-solid border-grey-6">
      <h3 className="font-bold text-lg">{t('vouchers info')}</h3>
      <EditorHtml
        defaultValue={faqText[user?.lang]}
        toolbarClassName="hidden"
        editorClassName="!border-none !p-0"
        readOnly
      />
    </div>
  );
};
