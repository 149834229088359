import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GraphChart } from 'components';

export const ReportsBarChart = ({
  selectedFilter,
  selectedView,
  companyNames,
  companiesData,
  dateRange
}) => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState([]);
  const [dates, setDates] = useState([]);
  const [dateFormat, setDateFormat] = useState('MM.YYYY');

  const calculateDates = () => {
    let newDates = [];

    switch (selectedFilter) {
      case 'today':
      case 'week': {
        setFormatWeek(newDates);
        break;
      }
      case 'month': {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        setFormatMonth(newDates, startOfMonth, endOfMonth);
        break;
      }
      case 'year': {
        const startOfYear = moment().startOf('year');
        const endOfYear = moment().endOf('year');
        setFormatYear(newDates, startOfYear, endOfYear);
        break;
      }
      case 'custom': {
        if (!dateRange[0] || !dateRange[1]) break;
        const startDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);

        if (endDate.diff(startDate, 'year') > 0) {
          setFormatYears(newDates, startDate, endDate);
          break;
        }

        if (endDate.diff(startDate, 'year') === 0) {
          if (endDate.diff(startDate, 'month') > 1) {
            setFormatYear(newDates, startDate, endDate);
          } else {
            setFormatMonth(newDates, startDate, endDate);
          }
          break;
        }
      }
    }

    setDates(newDates);
  };

  const setFormatWeek = (dates) => {
    const currentDate = moment();
    const startOfWeek = currentDate.startOf('week');

    for (let i = 0; i < 7; i++) {
      const currentDate = startOfWeek.clone().add(i, 'days');
      dates.push(currentDate.format('DD.MM.YYYY'));
    }
    setDateFormat('DD.MM.YYYY');
  };

  const setFormatMonth = (dates, startDate, endDate) => {
    let currentDate = startDate.clone();

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, 'day')
    ) {
      dates.push(currentDate.format('DD.MM.YYYY'));
      currentDate.add(1, 'day');
    }
    setDateFormat('DD.MM.YYYY');
  };

  const setFormatYear = (dates, startDate, endDate) => {
    let currentDate = startDate.clone();

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, 'month')
    ) {
      dates.push(currentDate.format('MM.YYYY'));
      currentDate.add(1, 'month');
    }
    setDateFormat('MM.YYYY');
  };

  const setFormatYears = (dates, startDate, endDate) => {
    for (
      let currentYear = startDate.year();
      currentYear <= endDate.year();
      currentYear++
    ) {
      dates.push(currentYear.toString());
    }
    setDateFormat('YYYY');
  };

  const formatDatesCharts = (date, callback) => {
    const formattedData = {};

    companiesData.forEach((companies, index) => {
      companies.forEach((company) => {
        const creationDate = moment(company.creationDate).format(dateFormat);
        if (date === creationDate) {
          callback(formattedData, index, company);
        }
      });
    });
    return formattedData;
  };

  const formatDatesByContracts = (formattedData, index) => {
    if (formattedData[companyNames[index]]) {
      formattedData[companyNames[index]] += 1;
    } else {
      formattedData[companyNames[index]] = 1;
    }
  };

  const formatDatesByMoney = (formattedData, index, company) => {
    if (formattedData[companyNames[index]]) {
      formattedData[companyNames[index]] += parseInt(company.full);
    } else {
      formattedData[companyNames[index]] = parseInt(company.full);
    }
  };

  const formatDatesByCommission = (formattedData, index, company) => {
    if (!formattedData[companyNames[index]]) {
      formattedData[companyNames[index]] = company.comission;
    }
  };

  const formatDatesByOrder = (formattedData, index) => {
    const orders = new Set(
      companiesData[index].map((company) => company.order)
    );

    if (!formattedData[companyNames[index]]) {
      formattedData[companyNames[index]] = orders.size;
    }
  };

  useEffect(() => {
    if (!companiesData?.length) return;
    calculateDates();
  }, [selectedFilter, dateRange]);

  useEffect(() => {
    if (!companiesData?.length) return;
    let formattedData;

    switch (selectedView) {
      case 'dynamicsOrders': {
        formattedData = dates.map((date) => ({
          date,
          ...formatDatesCharts(date, formatDatesByOrder)
        }));
        break;
      }
      case 'dynamicsContracts': {
        formattedData = dates.map((date) => ({
          date,
          ...formatDatesCharts(date, formatDatesByContracts)
        }));
        break;
      }
      case 'dynamicsMoney': {
        formattedData = dates.map((date) => ({
          date,
          ...formatDatesCharts(date, formatDatesByMoney)
        }));
        break;
      }
      case 'dynamicsCommission': {
        formattedData = dates.map((date) => ({
          date,
          ...formatDatesCharts(date, formatDatesByCommission)
        }));
        break;
      }
      default:
        break;
    }
    setFilteredData(formattedData);
  }, [selectedView, selectedFilter, dates]);

  if (!filteredData?.length || !companiesData?.length)
    return (
      <p className="flex items-center justify-center">{t('missing items')}</p>
    );
  return (
    <div className="flex flex-col items-center justify-center">
      <GraphChart data={filteredData} />
    </div>
  );
};
