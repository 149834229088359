import { api } from 'config';

class ReportsApi {
  constructor(url) {
    this.url = url;
  }

  getReports(queryParams, config) {
    return api.get(
      `${this.url}/list?start=${queryParams?.start ?? ''}&end=${
        queryParams?.end ?? ''
      }`,
      config
    );
  }

  getDashboard(config) {
    return api.get(`${this.url}/dashboard`, config);
  }
}

export const reportsApi = new ReportsApi('/reports');
