import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { num } from 'normalizers';

import {
  asyncCreateVoucher,
  asyncGetCompanies,
  asyncGetVoucher,
  asyncUpdateVoucher,
  selectCompanies
} from 'store';

import { Button, Datepicker, Input, Select } from 'components';

export const VoucherForm = ({ id }) => {
  const companies = useSelector(selectCompanies);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const voucher = {
        code: '',
        discount: '',
        amount: '',
        startsFrom: '',
        expiresAfter: '',
        insuranceCompany: '',
        insuranceProduct: ''
      };

      if (id) {
        const data = await dispatch(asyncGetVoucher(id)).unwrap();

        Object.keys(voucher).forEach((key) => {
          const voucherData = data[key];
          if (voucherData || !isNaN(voucherData)) voucher[key] = voucherData;
        });

        voucher.insuranceCompany = voucher.insuranceCompany._id;
        voucher.insuranceProduct = voucher.insuranceProduct._id;
      }
      return voucher;
    }
  });

  const onSubmit = async (data) => {
    dispatch(id ? asyncUpdateVoucher({ id, data }) : asyncCreateVoucher(data));

    if (id) navigate(AppRoutes.VOUCHERS);
  };

  watch('insuranceCompany');
  watch('insuranceProduct');
  const startsFrom = watch('startsFrom');
  const expiresAfter = watch('expiresAfter');

  const _companies = companies?.map((company) => ({
    value: company._id,
    label: company.organization
  }));

  const selectedCompany = companies?.find(
    (el) => el._id === getValues()?.insuranceCompany
  );

  const _products =
    selectedCompany?.products?.map((product) => ({
      value: product._id,
      label: product.name
    })) || [];

  useEffect(() => {
    dispatch(asyncGetCompanies());
  }, []);

  if (!companies) return null;
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-3 gap-y-4 gap-x-16">
      <Input
        label={t('code')}
        required
        error={errors['code']?.message}
        {...register('code', { required: t('error - required') })}
      />
      <Input
        normalize={[num]}
        label={t('discount')}
        error={errors['discount']?.message}
        {...register('discount', { required: t('error - required') })}
        required
      />
      <Input
        normalize={[num]}
        label={t('amount')}
        error={errors['amount']?.message}
        {...register('amount', { required: t('error - required') })}
        required
      />
      <Controller
        name="startsFrom"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            minDate={new Date()}
            maxDate={new Date(expiresAfter) || null}
            name="startsFrom"
            label={t('voucher start date')}
            required
            error={errors['startsFrom']?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="expiresAfter"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            minDate={new Date(startsFrom)}
            maxDate={new Date(moment().add(100, 'years'))}
            name="expiresAfter"
            label={t('voucher end date')}
            required
            error={errors['expiresAfter']?.message}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        name="insuranceCompany"
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            name="insuranceCompany"
            wrapperClassname="row-start-3"
            options={_companies}
            onChange={onChange}
            value={value}
            label={t('company')}
            error={errors['insuranceCompany']?.message}
            required
          />
        )}
      />
      {getValues()?.insuranceCompany && (
        <Controller
          control={control}
          name="insuranceProduct"
          rules={{ required: t('error - required') }}
          render={({ field: { onChange, value } }) => (
            <Select
              name="insuranceProduct"
              wrapperClassname="row-start-3"
              options={_products}
              onChange={onChange}
              value={value}
              label={t('product')}
              error={errors['insuranceProduct']?.message}
              required
            />
          )}
        />
      )}
      <Button className="row-start-4 justify-self-start">{t('save')}</Button>
    </form>
  );
};
