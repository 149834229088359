import { AppRoutes } from 'constants/index.js';

import { getSalesManagerRoute } from 'utils';

import { RolesList } from 'modules';

export const SalesManagers = () => {
  return (
    <RolesList
      roles={['sales-manager']}
      createRoute={AppRoutes.SALES_MANAGERS_CREATE}
      getCustomRoute={getSalesManagerRoute}
    />
  );
};
