import { CompanyPdfForm } from 'modules/CompanyPdfs/CompanyPdfForm.jsx';
import { CompanyPdfList } from 'modules/CompanyPdfs/CompanyPdfList.jsx';

export const CompanyPdf = ({ id }) => {
  return (
    <div className="flex flex-col gap-8">
      <CompanyPdfForm id={id} />
      <CompanyPdfList id={id} />
    </div>
  );
};
