import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  createProductReducer,
  deleteProductReducer,
  getProductReducer,
  getProductsReducer,
  importFromCsvReducer,
  unsetIsDeletedProductReducer,
  unsetIsUpdatedProductReducer,
  updateProductReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.PRODUCTS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProductsReducer(builder);
    getProductReducer(builder);
    createProductReducer(builder);
    updateProductReducer(builder);
    deleteProductReducer(builder);
    unsetIsUpdatedProductReducer(builder);
    unsetIsDeletedProductReducer(builder);
    importFromCsvReducer(builder);
  }
});

export const productsReducer = slice.reducer;
