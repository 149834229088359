import { useSelector } from 'react-redux';

import { selectProductsByCompanies } from 'store';

export const DashboardCommissions = () => {
  const products = useSelector(selectProductsByCompanies);

  if (!products) return null;
  return (
    <div className="flex gap-4 flex-wrap">
      {Object.keys(products).map((company) => (
        <div key={company} className="flex flex-col gap-1 flex-1">
          <span>{company}</span>
          {Object.values(products[company]).map((product) => {
            return Object.keys(product).map((productName) => (
              <span
                key={
                  productName
                }>{`${productName}: ${product[productName]}%`}</span>
            ));
          })}
        </div>
      ))}
    </div>
  );
};
