import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { max } from 'normalizers';

import {
  asyncGetPartnerVouchers,
  asyncSendCalculateClient,
  selectPartnersVouchers
} from 'store';
import { selectOrderIsLoading } from 'store';
import { asyncGetOrder } from 'store';

import { Button, ButtonVariants, Input, Select } from 'components';

const currency = 'CZK';

export const Calculator = ({
  index,
  orderId,
  prices,
  setPrices,
  axaError,
  setAxaError,
  onOrderSave,
  isFormDisabled
}) => {
  const vouchers = useSelector(selectPartnersVouchers);
  const isLoading = useSelector(selectOrderIsLoading);

  const { id } = useParams();

  const [maxDiscount, setMaxDiscount] = useState(0);

  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(false);

  const [voucherId, setVoucherId] = useState(null);

  const { control, register, watch, setValue, getValues } = useFormContext();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const insurance = watch(`clients.${index}.insurance.company`);
  const product = watch(`clients.${index}.insurance.product`);
  const type = watch(`clients.${index}.insurance.type`);
  const term = watch(`clients.${index}.insurance.term`);
  const start = watch(`clients.${index}.insurance.start`);

  const voucher = watch(`clients.${index}.insurance.voucher`);
  const discount = watch(`clients.${index}.insurance.discount`);

  useEffect(() => {
    if (product) {
      dispatch(asyncGetPartnerVouchers(product));
    }
  }, [product]);

  const _vouchers = vouchers?.map(({ voucher }) => ({
    value: voucher._id,
    label: voucher.code
  }));

  _vouchers?.unshift({ value: null, label: t('not selected') });

  const sendCalculateClient = async ({ isVoucher = false }) => {
    if (!isVoucher) {
      toast.info(t('synchronizing price with axa'), { toastId: 'axa-sync' });
    }

    const term = getValues(`clients.${index}.insurance.term`);
    const type = getValues(`clients.${index}.insurance.type`);

    if (!type) return;

    dispatch(
      asyncSendCalculateClient({
        isVoucher,
        company: insurance,
        clientIdx: index,
        product,
        type,
        term,
        discount,
        voucher,
        orderId,
        start
      })
    )
      .unwrap()
      .then((data) => {
        setAxaError(false);
        setPrices(data.prices);
        setMaxDiscount(data.maxDiscount || 0);
      })
      .catch(() => {
        toast.dismiss('axa-sync');
        setAxaError(true);
      })
      .finally(() => {
        dispatch(asyncGetOrder(id));
      });
  };

  useEffect(() => {
    if (voucher?._id) setVoucherId(voucher._id);
  }, [voucher]);

  useEffect(() => {
    onOrderSave({
      voucher: voucherId,
      orderId,
      clientIdx: index
    });
  }, [voucherId]);

  useEffect(() => {
    if (voucher || discount) sendCalculateClient({ isVoucher: true });
    if (term && type) sendCalculateClient({});
  }, [term, type, voucher, discount]);

  if (!product || !type || !term || !vouchers) return null;
  return (
    <div className="mt-8">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 items-end">
          <Controller
            control={control}
            name={`clients.${index}.insurance.voucher`}
            render={({ field: { onChange, value } }) => (
              <Select
                defaultValue={_vouchers.find(
                  (voucherData) => voucher?._id === voucherData?.value
                )}
                disabled={
                  !_vouchers.length || isDiscountDisabled || isFormDisabled
                }
                label={t('voucher')}
                wrapperClassname="w-1/2"
                name={`clients.${index}.insurance.voucher`}
                options={_vouchers}
                onChange={(e) => {
                  setValue(`clients.${index}.insurance.discount`, null);
                  setVoucherId(e.target.value);

                  if (e.target.value) {
                    setIsSelectDisabled(true);
                  } else {
                    setIsSelectDisabled(false);
                  }

                  return onChange(e);
                }}
                value={value}
              />
            )}
          />
          <div className="pb-1">{t('or')}</div>
          <Input
            type="number"
            changeOnBlur
            disabled={isSelectDisabled || isFormDisabled}
            normalize={[max(maxDiscount)]}
            actionOnChange={(e) => {
              setValue(`clients.${index}.insurance.voucher`, null);

              if (e.target.value) {
                setIsDiscountDisabled(true);
              } else {
                setIsDiscountDisabled(false);
              }
            }}
            wrapperClassname="w-1/2"
            label={t('discount')}
            {...register(`clients.${index}.insurance.discount`)}
          />
        </div>
      </div>
      <div className="text-grey-1 mt-8 text-md flex flex-row justify-between gap-4">
        <span>{t('full price:', { price: prices.full, currency })} </span>
        <span>
          {t('discount price:', { price: prices.withDiscount, currency })}
        </span>
        <span>
          {t('comission price:', { price: prices.comission, currency })}
        </span>
      </div>
      {axaError && (
        <Button
          disabled={isLoading}
          onClick={sendCalculateClient}
          variant={ButtonVariants.DECLINE}
          type="button"
          className="mt-2 disabled:opacity-50">
          {t('sync')}
        </Button>
      )}
    </div>
  );
};
