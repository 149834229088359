import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppRoutes, PASSWORD_OPTIONS } from 'constants/index.js';

import { asyncCheckRecoveryToken, asyncResetPassword } from 'store';

import { Button, Input } from 'components';

export const ResetPasswordForm = () => {
  const [search] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const recoveryToken = search.get('recoveryToken');

  const onSubmit = (data) => {
    dispatch(asyncResetPassword({ recoveryToken, ...data }))
      .unwrap()
      .then(() => navigate(AppRoutes.LOGIN));
  };

  useEffect(() => {
    if (!recoveryToken) return;
    const checkRecoveryToken = async () => {
      const isCorrect = await dispatch(
        asyncCheckRecoveryToken({ recoveryToken })
      ).unwrap();

      if (!isCorrect) navigate(AppRoutes.LOGIN);
    };

    checkRecoveryToken();
  }, [dispatch, navigate, recoveryToken]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[375px] flex flex-col gap-4">
      <span className="text-2xl">{t('login - new password')}</span>
      <Input
        error={errors['password']?.message}
        type="password"
        label={t('password')}
        required
        {...register('password', PASSWORD_OPTIONS)}
      />

      <Input
        error={errors['passwordRepeat']?.message}
        type="password"
        label={t('login - password repeat')}
        required
        {...register('passwordRepeat', PASSWORD_OPTIONS)}
      />

      <Button>{t('send')}</Button>
    </form>
  );
};
