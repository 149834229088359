import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = (state) => state.vouchers;

export const selectVouchers = createSelector(
  selectAuthState,
  (state) => state.vouchers
);

export const selectVouchersIsLoading = createSelector(
  selectAuthState,
  (state) => state.isLoading
);

export const selectVoucher = createSelector(
  selectAuthState,
  (state) => state.voucher
);

export const selectVouchersCount = createSelector(
  selectAuthState,
  (state) => state.vouchersCount
);
