import cs from 'classnames';
import Gravatar from 'react-gravatar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import {
  selectUser,
  unsetIsAuthorized,
  unsetIsFirstLogin
} from 'store/index.js';

import { useModal } from 'hooks';

import { Link } from 'components';

import { YesNoModal } from 'modules/ModalTemplates/YesNoModal.jsx';

import { PROTECTED_ROUTES } from '../routes';

export const Sidebar = () => {
  const dispatch = useDispatch();

  const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const user = useSelector(selectUser);

  const { email, firstName, lastName, role } = user || {};
  const name = `${firstName} ${lastName}`;

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    dispatch(unsetIsAuthorized());
    dispatch(unsetIsFirstLogin());
  };

  const isCurrentTab = (path) =>
    path === '/' ? pathname === path : pathname.includes(path);

  return (
    <div className="flex flex-col fixed w-1/6 h-screen py-2 border-r z-20 gap-4 bg-grey-primary">
      <div className="flex flex-row gap-2 px-2">
        <div className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] my-0 rounded-full overflow-hidden border border-black">
          <Link href={AppRoutes.PROFILE}>
            <Gravatar email={email} size={40} default="mp" />
          </Link>
        </div>
        <div className="flex items-center">
          <span className="text-grey-2 text-base">{name}</span>
        </div>
      </div>

      <ul className="flex flex-col px-2 gap-1">
        {PROTECTED_ROUTES.filter(
          ({ roles, sidebar = true }) =>
            sidebar && (!roles || roles.includes(role))
        ).map(({ breadcrumb, path, onClick }) => (
          <li key={breadcrumb} className="flex">
            <Link
              className={cs(
                'transition-colors ease-in-out duration-500 p-2 rounded text-sm flex-1 hover:no-underline',
                {
                  'text-white bg-green-1': isCurrentTab(path),
                  'text-grey-3 hover:text-white hover:bg-green-1':
                    path !== pathname
                }
              )}
              onClick={(e) => {
                if (onClick) {
                  const route = onClick(e);

                  navigate(route);
                }
              }}
              href={path}
              key={path}>
              {t(breadcrumb)}
            </Link>
          </li>
        ))}
        <li
          onClick={handleOnOpenModal}
          className="transition-colors ease-in-out duration-500 p-2 rounded text-sm cursor-pointer text-grey-3 hover:text-white hover:bg-green-1">
          {t('logout')}
        </li>
      </ul>
      <YesNoModal
        isOpen={isModalOpened}
        onApprove={handleLogout}
        question={t('confirm exit')}
        approveLabel={t('yes')}
        declineLabel={t('no')}
        handleClose={handleOnCloseModal}
        onDecline={handleOnCloseModal}
      />
    </div>
  );
};
