import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ isAuth, to }) => {
  const location = useLocation();
  const navigateState = !isAuth
    ? { from: location.pathname, search: location.search }
    : {};

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={to} state={navigateState} replace />
  );
};
