import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { reportsApi } from 'api';

import i18n from 'i18n';

export const asyncGetReports = createAsyncThunk(
  `${SliceNames.REPORTS}/GET_REPORTS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await reportsApi.getReports(payload);
      return data.list;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetDashboard = createAsyncThunk(
  `${SliceNames.REPORTS}/GET_DASHBOARD`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await reportsApi.getDashboard();
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);
