import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { downloadFile } from 'utils';

import {
  asyncDownloadInvoiceDraft,
  selectCompany,
  selectOrderIsLoading,
  selectProduct,
  selectSettings,
  selectUser,
  selectValidations,
  selectVoucher
} from 'store';

import { useModal } from 'hooks';

import { Button, ButtonVariants, GoBack, Modal, Portal } from 'components';

import { OrderAdminActions } from 'modules/Orders/FormParts/OrderAdminActions.jsx';

import LoadingSrc from 'svg/loading.svg';

import { CancelContractForm } from './CancelContractForm.jsx';

export const ViewInfo = ({
  client = {},
  orderStatus,
  prices,
  clientIdx,
  orderId
}) => {
  const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();
  const dispatch = useDispatch();

  const voucher = useSelector(selectVoucher);
  const product = useSelector(selectProduct);
  const company = useSelector(selectCompany);
  const validations = useSelector(selectValidations);

  const isLoading = useSelector(selectOrderIsLoading);
  const settings = useSelector(selectSettings);

  const user = useSelector(selectUser);
  const role = user?.role;

  const clientStatus = client?.status;

  const { t } = useTranslation();

  const { isSamePersons } = client;
  const { isLegalEntity = false, addressesMatch: insurerAddressesMatch } =
    client?.insurer || {};
  const { addressesMatch: insuredAddressesMatch } = client?.insured || {};

  const insurerCorCountry = insurerAddressesMatch
    ? client?.insurer?.czCountry
    : client?.insurer?.correspondent?.country;
  const insurerCorCity = insurerAddressesMatch
    ? client?.insurer?.czCity
    : client?.insurer?.correspondent?.city;
  const insurerCorStreet = insurerAddressesMatch
    ? client?.insurer?.czStreet
    : client?.insurer?.correspondent?.street;
  const insurerCorIndex = insurerAddressesMatch
    ? client?.insurer?.czIndex
    : client?.insurer?.correspondent?.index;

  const insurerCorrespondal =
    insurerCorCountry || insurerCorCity || insurerCorStreet || insurerCorIndex
      ? `${insurerCorCountry || '_'}, ${insurerCorCity || '_'}, ${
          insurerCorStreet || '_'
        }, ${insurerCorIndex || '_'}`
      : '_';

  const insuredField = isLegalEntity
    ? 'insured'
    : isSamePersons
      ? 'insurer'
      : 'insured';

  const matchField = isLegalEntity
    ? insuredAddressesMatch
    : isSamePersons
      ? insurerAddressesMatch
      : insuredAddressesMatch;

  const insuredCorCountry = matchField
    ? 'Česká republika'
    : client?.[insuredField]?.correspondent?.country;
  const insuredCorCity = matchField
    ? client?.[insuredField]?.czCity
    : client?.[insuredField]?.correspondent?.city;
  const insuredCorStreet = matchField
    ? client?.[insuredField]?.czStreet
    : client?.[insuredField]?.correspondent?.street;
  const insuredCorIndex = matchField
    ? client?.[insuredField]?.czIndex
    : client?.[insuredField]?.correspondent?.index;

  const insuredCorrespondal =
    insuredCorCountry || insuredCorCity || insuredCorStreet || insuredCorIndex
      ? `${insuredCorCountry || '_'}, ${insuredCorCity || '_'}, ${
          insuredCorStreet || '_'
        }, ${insuredCorIndex || '_'}`
      : '_';

  const handleDownloadInvoiceDraft = async () => {
    const data = await dispatch(
      asyncDownloadInvoiceDraft({ orderId, clientIdx })
    ).unwrap();

    downloadFile({
      data,
      fileName:
        orderStatus === 'paid' || orderStatus === 'pay-later'
          ? `smlouva-${client.insured.contractId}.pdf`
          : 'návrh-smlouvy.pdf',
      fileType: 'application/pdf'
    });
  };

  const isDownloadButtonAvailable = () => {
    const isIncorrect = Object.keys(validations[clientIdx] || {}).length !== 0;
    if (isIncorrect) {
      toast.error(t('empty fields in order'), { toastId: 'error' });
    }

    return isIncorrect;
  };

  const handleClientStatus = () =>
    orderStatus === 'paid' || orderStatus === 'pay-later'
      ? 'download invoice'
      : 'download invoice draft';

  if (!validations) return null;
  return (
    <div className="flex flex-col gap-4">
      {clientStatus === 'canceled' && (
        <div className="flex flex-col items-center gap-4">
          <span className="text-3xl text-error text-center flex items-center justify-center">
            {t('dissolved')}
          </span>
          <div className="flex flex-col w-full">
            <span className="text-error">{`${t('refund')}: ${
              client?.prices?.refund ?? 0
            } CZK`}</span>
            <span className="text-error">{`${t('storne')}: ${
              client?.prices?.storne ?? 0
            } CZK`}</span>
          </div>
        </div>
      )}
      <div className="flex gap-8 justify-between">
        <div className="flex flex-col gap-8">
          <div>
            <p>
              {t('contract number')}: {client?.insured?.contractId || '_'}
            </p>
            <p>
              {t('insurance')}: {company?.organization || '_'}
            </p>
            <p>
              {t('product')}: {product?.name || '_'}
            </p>
            <p>
              {t('insurance type')}: {client?.insurance?.type || '_'}
            </p>
          </div>
          <div>
            <p className="font-bold">{t('insurer')}</p>
            {isLegalEntity ? (
              <div>
                <p>
                  {t('company name')}: {client?.insurer?.companyName || '_'}
                </p>
                <p>
                  {t('itn code')}: {client?.insurer?.codeITN || '_'}
                </p>
                <p>
                  {t('email')}: {client?.insurer?.email || '_'}
                </p>
                <p>
                  {t('phone')}: {client?.insurer?.phone || '_'}
                </p>
                <p>
                  {t('index')}: {client?.insurer?.czIndex || '_'}
                </p>
                <p>
                  {t('czech')} {t('address')}: {client?.insurer?.czCity || '_'},{' '}
                  {client?.insurer?.czStreet || '_'}
                </p>
                <p>
                  {t('correspondal address')}: {insurerCorrespondal || '_'}
                </p>
              </div>
            ) : (
              <div>
                <p>
                  {t('name surname')}: {client?.insurer?.firstName || '_'}{' '}
                  {client?.insurer?.lastName || '_'}
                </p>
                <p>
                  {t('email')}: {client?.insurer?.email || '_'}
                </p>
                <p>
                  {t('phone')}: {client?.insurer?.phone || '_'}
                </p>
                <p>
                  {t('gender')}: {t(client?.insurer?.gender || '_')}
                </p>
                <p>
                  {t('birthday')}:{' '}
                  {client?.insurer?.birthday
                    ? moment.utc(client?.insurer?.birthday).format('DD.MM.YYYY')
                    : '_'}
                </p>
                <p>
                  {t('birth - place')}: {client?.insurer?.birthPlace || '_'}
                </p>
                <p>
                  {t('passport')}: {client?.insurer?.passport || '_'}
                </p>
                <p>
                  {t('passport expiration')}:{' '}
                  {client?.insurer?.passportFrom
                    ? moment
                        .utc(client?.insurer?.passportFrom)
                        .format('DD.MM.YYYY')
                    : '_'}{' '}
                  -{' '}
                  {client?.insurer?.passportTo
                    ? moment
                        .utc(client?.insurer?.passportTo)
                        .format('DD.MM.YYYY')
                    : '_'}
                </p>
                <p>
                  {t('itn code')}: {client?.insurer?.pid || '_'}
                </p>
                <p>
                  {t('citizenship')}: {client?.insurer?.citizenship || '_'}
                </p>
                <p>
                  {t('czech')} {t('address')}: {client?.insurer?.czCity || '_'},{' '}
                  {client?.insurer?.czStreet || '_'}
                </p>
                <p>
                  {t('correspondal address')}: {insurerCorrespondal}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <div>
            <p>
              {t('term')}: {client?.insurance?.term || '_'}
            </p>
            <p>
              {t('insurance start date')}:{' '}
              {client?.insurance?.start
                ? moment(client?.insurance?.start).format('DD.MM.YYYY')
                : '_'}
            </p>
            <p>
              {t('partner code')}: {client?.insurance?.partnerCode || '_'}
            </p>
            <p>
              {t('voucher code')}: {voucher?.code || '_'}
            </p>
          </div>
          <div>
            <p className="font-bold">{t('insured')}</p>
            <p>
              {t('name surname')}: {client?.[insuredField]?.firstName || '_'}{' '}
              {client?.[insuredField]?.lastName || '_'}
            </p>
            <p>
              {t('email')}: {client?.[insuredField]?.email || '_'}
            </p>
            <p>
              {t('phone')}: {client?.[insuredField]?.phone || '_'}
            </p>
            <p>
              {t('gender')}: {t(client?.[insuredField]?.gender || '_')}
            </p>
            <p>
              {t('birthday')}:{' '}
              {client?.[insuredField]?.birthday
                ? moment
                    .utc(client?.[insuredField]?.birthday)
                    .format('DD.MM.YYYY')
                : '_'}
            </p>
            <p>
              {t('birth - place')}: {client?.[insuredField]?.birthPlace || '_'}
            </p>
            <p>
              {t('passport')}: {client?.[insuredField]?.passport || '_'}
            </p>
            <p>
              {t('passport expiration')}:{' '}
              {client?.[insuredField]?.passportFrom
                ? moment
                    .utc(client?.[insuredField]?.passportFrom)
                    .format('DD.MM.YYYY')
                : '_'}{' '}
              -{' '}
              {client?.[insuredField]?.passportTo
                ? moment
                    .utc(client?.[insuredField]?.passportTo)
                    .format('DD.MM.YYYY')
                : '_'}
            </p>
            <p>
              {t('itn code')}: {client?.[insuredField]?.pid || '_'}
            </p>
            <p>
              {t('citizenship')}: {client?.[insuredField]?.citizenship || '_'}
            </p>
            <p>
              {t('czech')} {t('address')}:{' '}
              {client?.[insuredField]?.czCity || '_'},{' '}
              {client?.[insuredField]?.czStreet || '_'}
            </p>
            <p>
              {t('correspondal address')}: {insuredCorrespondal || '_'}
            </p>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <GoBack />
        {orderStatus !== 'deleted' &&
          (role !== 'cashier' || settings?.companyPartners === user?._id) && (
            <Button
              className={cn('relative disabled:opacity-50', {
                'opacity-60': clientStatus === 'canceled'
              })}
              disabled={
                isDownloadButtonAvailable() ||
                isLoading ||
                clientStatus === 'canceled'
              }
              onClick={handleDownloadInvoiceDraft}>
              {isLoading && (
                <div className="absolute inset-0 bg-green-1 flex items-center justify-center">
                  <img
                    src={LoadingSrc}
                    className="w-6 h-6"
                    alt={t('loading')}
                  />
                </div>
              )}
              {t(
                clientStatus === 'canceled' ? 'dissolved' : handleClientStatus()
              )}
            </Button>
          )}
        {orderStatus !== 'draft' && role === 'admin' && (
          <OrderAdminActions
            clientIndex={clientIdx}
            orderId={orderId}
            hideDownloadInvoice
          />
        )}
        {orderStatus === 'paid' &&
          role === 'admin' &&
          client.status === 'active' && (
            <Button
              onClick={handleOnOpenModal}
              className={cn('self-end')}
              variant={ButtonVariants.DECLINE}>
              {t('contract cancel')}
            </Button>
          )}
        <Portal wrapperId="portal-modal">
          <Modal isOpen={isModalOpened} handleClose={handleOnCloseModal}>
            <CancelContractForm
              price={prices?.full}
              handleClose={handleOnCloseModal}
            />
          </Modal>
        </Portal>
      </div>
    </div>
  );
};
