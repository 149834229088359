import { createSelector } from '@reduxjs/toolkit';

const selectLogsState = (state) => state.logs;

export const selectLogs = createSelector(
  selectLogsState,
  (state) => state.logs
);

export const selectLogsCount = createSelector(
  selectLogsState,
  (state) => state.logsCount
);

export const selectLogsItem = createSelector(
  selectLogsState,
  (state) => state.log
);

export const selectLogsIsLoading = createSelector(
  selectLogsState,
  (state) => state.isLoading
);
