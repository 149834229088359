import { withLoadingReducer } from 'utils';

import {
  asyncCreateNews,
  asyncDeleteNews,
  asyncGetNews,
  asyncGetNewsItem,
  asyncUpdateNews,
  unsetIsDeletedNewsAction,
  unsetIsUpdatedNewsAction
} from './actions.js';

export const getNewsReducer = (builder) => {
  builder
    .addCase(asyncGetNews.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetNews.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.news = payload.list;
      state.newsCount = payload.count;
      state.newsCounts = payload.counts;
    })
    .addCase(asyncGetNews.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getNewsItemReducer = (builder) => {
  builder
    .addCase(asyncGetNewsItem.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetNewsItem.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.newsItem = payload;
    })
    .addCase(asyncGetNewsItem.rejected, (state) => {
      state.isLoading = false;
    });
};

export const createNewsReducer = withLoadingReducer(asyncCreateNews);

export const updateNewsReducer = (builder) => {
  builder
    .addCase(asyncUpdateNews.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateNews.fulfilled, (state) => {
      state.isLoading = false;
      state.isUpdatedNews = true;
    })
    .addCase(asyncUpdateNews.rejected, (state) => {
      state.isUpdatedNews = false;
    });
};

export const deleteNewsReducer = (builder) => {
  builder
    .addCase(asyncDeleteNews.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncDeleteNews.fulfilled, (state) => {
      state.isLoading = false;
      state.isDeletedNews = true;
    })
    .addCase(asyncDeleteNews.rejected, (state) => {
      state.isDeletedNews = false;
    });
};

export const unsetIsUpdatedNewsReducer = (builder) => {
  builder.addCase(unsetIsUpdatedNewsAction, (state) => {
    state.isUpdatedNews = false;
  });
};

export const unsetIsDeletedNewsReducer = (builder) => {
  builder.addCase(unsetIsDeletedNewsAction, (state) => {
    state.isUpdatedNews = false;
  });
};
