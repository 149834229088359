import axios from 'axios';

export const apiBlob = axios.create({
  baseURL: import.meta.env.BASE_URL,
  responseType: 'blob'
});

apiBlob.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken') || null;
    if (token) {
      config.headers = {
        authorization: token
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

apiBlob.interceptors.response.use(
  (response) => {
    response.headers = {
      'Content-Type': 'application/octet-stream',
      'content-disposition':
        'attachment; filename=' + JSON.parse(response.config.data).fileName
    };
    return response.data;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.clear();
      if (!window.location.href.includes('login')) {
        window.location.href = '/';
      }
      return Promise.reject(error);
    } else {
      return error;
    }
  }
);
