import { api } from 'config';

class LogsApi {
  constructor(url) {
    this.url = url;
  }

  getLogs(queryParams, config) {
    return api.get(
      `${this.url}?page=${queryParams?.page ?? ''}&date=${
        queryParams?.date ?? ''
      }&actionType=${queryParams?.actionType ?? ''}&user=${
        queryParams?.user ?? ''
      }`,
      config
    );
  }

  getLogsItem(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }
}

export const logsApi = new LogsApi('/logs');
