import { asyncGetClients } from './actions.js';

export const getClientsReducer = (builder) => {
  builder
    .addCase(asyncGetClients.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetClients.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.clients = payload.list;
      state.clientsCount = payload.count;
    })
    .addCase(asyncGetClients.rejected, (state) => {
      state.isLoading = false;
    });
};
