import { useTranslation } from 'react-i18next';

import { Table, TooltipTable } from 'components/index.js';

import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

export const OrderClients = ({
  fields,
  validations,
  setClientIndex,
  setEditing,
  handleOnOpenModal,
  isFormDisabled
}) => {
  const { t } = useTranslation();

  const ClientActions = ({ index }) => {
    const handleDeleteClient = () => {
      setClientIndex(index);
      handleOnOpenModal();
    };

    const handleEditClient = () => {
      sessionStorage.setItem('clientIndex', index);
      setEditing(index);
    };

    return (
      <div className="flex flex-row gap-2">
        <img
          onClick={handleEditClient}
          className="cursor-pointer"
          src={EditSrc}
          alt="edit"
        />
        {!isFormDisabled && (
          <img
            onClick={handleDeleteClient}
            className="cursor-pointer"
            src={DeleteSrc}
            alt="delete"
          />
        )}
      </div>
    );
  };

  const cfg = () => [
    {
      valueFunc: (_, el) => {
        const field = el?.isSamePersons ? 'insurer' : 'insured';
        const { firstName, lastName } = el?.[field] || {};

        return `${firstName || ''} ${lastName || ''}`;
      },
      key: 'name',
      header: t('name'),
      cellClassName: (idx) =>
        Object.keys(validations?.[idx] || {}).length !== 0
          ? 'border-l-[4px] border-l-error'
          : 'border-l-[4px] border-l-green-1'
    },
    {
      valueFunc: (_, el) => {
        const field =
          el?.isSamePersons && !el?.insurer?.isLegalEntity
            ? 'insurer'
            : 'insured';
        const { phone } = el?.[field] || {};

        return phone;
      },
      key: 'phone',
      header: t('phone')
    },
    {
      valueFunc: (_, el) => el?.insured?.contractId || t('n/a'),
      key: 'axa-contract',
      header: t('axa contract')
    },
    {
      header: t('actions'),
      component: ClientActions,
      propKeys: ['index']
    }
  ];

  return (
    <Table
      items={fields}
      config={cfg()}
      tooltipChildren={(selectedIndex) => {
        if (
          validations?.[selectedIndex] &&
          Object.keys(validations?.[selectedIndex]).length > 1
        )
          return <TooltipTable item={validations?.[selectedIndex]} />;
      }}
    />
  );
};
