import { useParams } from 'react-router-dom';

import { VoucherForm } from 'modules';

export const Voucher = () => {
  const { id } = useParams();
  return (
    <div className="flex flex-col">
      <VoucherForm id={id} />
    </div>
  );
};
