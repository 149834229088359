import cs from 'classnames';
import cn from 'classnames';
import { ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { forwardRef, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const EditorHtml = forwardRef(
  (
    {
      onBlur,
      onChange,
      required,
      wrapperClassname,
      editorClassName,
      label,
      defaultValue = '',
      value,
      name,
      hideError = false,
      error,
      ...props
    },
    ref
  ) => {
    const [editorState, setEditorState] = useState(null);
    const handleChange = (event) => {
      if (event?.blocks[0]?.text === '') onChange('');
      else onChange(draftToHtml(event));
    };

    useEffect(() => {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(value ? value : defaultValue)
          )
        )
      );
    }, [defaultValue]);

    if (!editorState) return null;
    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm mb-1')}>
          {label}
          {required && '*'}
        </label>
        <Editor
          onBlur={onBlur}
          toolbarClassName="border border-grey-6 mb-0"
          editorClassName={cn(
            'border-x border-b border-grey-6 p-2 bg-white',
            editorClassName
          )}
          ref={ref}
          name={name}
          onChange={handleChange}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          {...props}
        />
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

EditorHtml.displayName = 'EditorHtml';
