import { withLoadingReducer } from 'utils';

import {
  asyncCreateProduct,
  asyncDeleteProduct,
  asyncGetProduct,
  asyncGetProducts,
  asyncImportFromCsv,
  asyncUpdateProduct,
  unsetIsDeletedProductAction,
  unsetIsUpdatedProductAction
} from './actions.js';

export const getProductsReducer = (builder) => {
  builder
    .addCase(asyncGetProducts.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = payload.list;
      state.productsCount = payload.count;
    })
    .addCase(asyncGetProducts.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getProductReducer = (builder) => {
  builder
    .addCase(asyncGetProduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.product = payload;
    })
    .addCase(asyncGetProduct.rejected, (state) => {
      state.isLoading = false;
    });
};

export const createProductReducer = withLoadingReducer(asyncCreateProduct);

export const updateProductReducer = (builder) => {
  builder
    .addCase(asyncUpdateProduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateProduct.fulfilled, (state) => {
      state.isLoading = false;
      state.isUpdatedProduct = true;
    })
    .addCase(asyncUpdateProduct.rejected, (state) => {
      state.isLoading = false;
      state.isUpdatedProduct = false;
    });
};

export const deleteProductReducer = (builder) => {
  builder
    .addCase(asyncDeleteProduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncDeleteProduct.fulfilled, (state) => {
      state.isLoading = false;
      state.isDeletedProduct = true;
    })
    .addCase(asyncDeleteProduct.rejected, (state) => {
      state.isLoading = false;
      state.isDeletedProduct = false;
    });
};

export const unsetIsUpdatedProductReducer = (builder) => {
  builder.addCase(unsetIsUpdatedProductAction, (state) => {
    state.isUpdatedProduct = false;
  });
};

export const unsetIsDeletedProductReducer = (builder) => {
  builder.addCase(unsetIsDeletedProductAction, (state) => {
    state.isDeletedProduct = false;
  });
};

export const importFromCsvReducer = (builder) => {
  builder
    .addCase(asyncImportFromCsv.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncImportFromCsv.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.product = payload;
    })
    .addCase(asyncImportFromCsv.rejected, (state) => {
      state.isLoading = false;
    });
};
