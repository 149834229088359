import { withLoadingReducer } from 'utils';

import {
  asyncBecomePartner,
  asyncCheckRecoveryToken,
  asyncForgotPassword,
  asyncResetPassword,
  asyncSendLogin
} from './actions.js';

export const checkRecoveryTokenReducer = withLoadingReducer(
  asyncCheckRecoveryToken
);

export const sendLoginReducer = withLoadingReducer(asyncSendLogin);

export const becomePartnerReducer = withLoadingReducer(asyncBecomePartner);

export const resetPasswordReducer = withLoadingReducer(asyncResetPassword);

export const forgotPasswordReducer = withLoadingReducer(asyncForgotPassword);
