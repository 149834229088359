import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { filterProductsByAge } from 'utils/filter-products-age.js';

import {
  asyncGetPartnerProductsCompanies,
  selectPartnerCompanies,
  selectPartnerProducts
} from 'store';

import { Datepicker, Select, TextArea } from 'components';

export const Insurance = ({ index, isFormDisabled }) => {
  const companies = useSelector(selectPartnerCompanies);
  const products = useSelector(selectPartnerProducts);

  const [productTypes, setProductTypes] = useState([]);

  const { t } = useTranslation();
  const { control, watch, register, setValue } = useFormContext();

  const dispatch = useDispatch();

  const _companies = companies?.reduce((acc, curr) => {
    if (!acc.some((el) => el.value === curr._id))
      acc.push({
        value: curr._id,
        label: curr.organization
      });

    return acc;
  }, []);

  const company = watch(`clients.${index}.insurance.company`);
  const product = watch(`clients.${index}.insurance.product`);

  const term = watch(`clients.${index}.insurance.term`);

  const insuredDob =
    watch(`clients.${index}.insured.birthday`) ||
    watch(`clients.${index}.insurer.birthday`) ||
    new Date();

  const insuredAge = useMemo(() => {
    if (!insuredDob) return 0;

    const dobMoment = moment(insuredDob);
    const today = moment();

    let age = today.diff(dobMoment, 'years');

    if (today.isBefore(dobMoment.add(age, 'years'), 'day')) {
      age -= 1;
    }

    return age;
  }, [insuredDob]);

  const companiesObj = companies?.reduce((acc, curr) => {
    acc[curr._id] = curr;

    return acc;
  }, {});

  const _products = filterProductsByAge(products, insuredAge)
    ?.map((product) => {
      if (
        companiesObj[company] &&
        product.company === companiesObj[company].organization
      ) {
        return { value: product._id, label: product.name };
      }
      return null;
    })
    .filter(Boolean);

  const productsObj = products?.reduce((acc, curr) => {
    acc[curr._id] = curr;

    return acc;
  }, {});
  const _product = productsObj?.[product] || { types: [] };

  const getInsuranceTypes = () => {
    let types = [];

    if (product) {
      types = _product.types.filter(
        (el) =>
          !el.name.includes('_SLEVA') &&
          Number(el.ageStart) <= insuredAge &&
          Number(el.ageEnd) >= insuredAge
      );
    }

    return types.map((type) => ({
      value: type.term,
      label: type.name
    }));
  };

  const getInsuranceTerms = () => {
    let terms = [];

    const types = _product.types.filter(
      (el) =>
        !el.name.includes('_SLEVA') &&
        Number(el.ageStart) <= insuredAge &&
        Number(el.ageEnd) >= insuredAge
    );

    terms = types.map((curr) => ({
      value: curr.term,
      label: curr.term
    }));

    return terms;
  };

  const _types = getInsuranceTypes();
  const _terms = getInsuranceTerms();

  useEffect(() => {
    dispatch(asyncGetPartnerProductsCompanies());
  }, []);

  useEffect(() => {
    if (term) {
      const formattedType = _types?.find((type) => type.value.includes(term));

      setValue(`clients.${index}.insurance.type`, formattedType?.label);
    }
  }, [term]);

  useEffect(() => {
    if (products && product) {
      const selectedProduct =
        products.find((prod) => prod._id === product) ?? products[0];

      const formatString = (product) =>
        `${product.name}: ${product?.ageStart ?? 1} - ${
          product?.ageEnd ?? 100
        }`;

      setProductTypes(selectedProduct?.types.map(formatString));
    }
  }, [products, product]);

  if (!products && !companies) return null;
  return (
    <div className="flex flex-col">
      <span className="text-lg">{t('insurance')}</span>
      <div className="grid grid-cols-2 gap-x-16 gap-y-4">
        <Controller
          control={control}
          name={`clients.${index}.insurance.company`}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder={t('n/a')}
              disabled={!_companies.length || isFormDisabled}
              label={t('company') + '*'}
              name={`clients.${index}.insurance.company`}
              options={_companies}
              onChange={(e) => {
                setValue(`clients.${index}.insurance.product`, null);
                setValue(`clients.${index}.insurance.type`, null);
                setValue(`clients.${index}.insurance.term`, null);

                return onChange(e);
              }}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name={`clients.${index}.insurance.product`}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder={t('n/a')}
              disabled={!_products.length || isFormDisabled}
              label={t('product') + '*'}
              name={`clients.${index}.insurance.product`}
              options={_products}
              onChange={(e) => {
                setValue(`clients.${index}.insurance.type`, null);
                setValue(`clients.${index}.insurance.term`, null);
                return onChange(e);
              }}
              value={value}
            />
          )}
        />
        <div className="w-1/3 hidden">
          <Controller
            control={control}
            name={`clients.${index}.insurance.type`}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={t('n/a')}
                disabled={!_types.length || isFormDisabled}
                label={t('type') + '*'}
                name={`clients.${index}.insurance.type`}
                options={_types}
                onChange={(e) => {
                  setValue(`clients.${index}.insurance.term`, null);

                  return onChange(e);
                }}
                value={value}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name={`clients.${index}.insurance.term`}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder={t('n/a')}
              disabled={!_terms.length || isFormDisabled}
              label={t('term') + '*'}
              name={`clients.${index}.insurance.term`}
              options={_terms}
              onChange={onChange}
              value={value}
            />
          )}
        />
        {productTypes?.length > 0 && !_terms?.length > 0 && (
          <Tooltip className="z-10" id={`clients.${index}.insurance.term`}>
            {t('order ages')}
          </Tooltip>
        )}
        <Controller
          name={`clients.${index}.insurance.start`}
          defaultValue={new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Prague' }))}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Datepicker
              minDate={isFormDisabled ? null : new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Prague' }))}
              defaultValue={new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Prague' }))}
              name={`clients.${index}.insurance.start`}
              label={t('insurance start') + '*'}
              onChange={(date) => {
                if (date) {
                  // Convert date to Prague timezone
                  const pragueDate = new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Prague' }));
                  onChange(pragueDate); // Pass the Prague timezone adjusted date to the controller
                } else {
                  onChange(date);
                }
              }}
              selected={value}
            />
          )}
        />
        <TextArea
          rows={8}
          disabled={isFormDisabled}
          wrapperClassname="col-span-full"
          name={`clients.${index}.insurance.comment`}
          label={t('comment')}
          {...register(`clients.${index}.insurance.comment`)}
        />
      </div>
    </div>
  );
};
