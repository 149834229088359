import { useParams } from 'react-router-dom';

import { ProductsForm } from 'modules';

export const Product = () => {
  const { id } = useParams();

  return (
    <div className="flex flex-col">
      <ProductsForm id={id} />
    </div>
  );
};
