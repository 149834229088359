import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AppRoutes } from 'constants/index.js';

import {
  asyncGetProducts,
  selectProducts,
  selectProductsCount,
  selectProductsIsLoading
} from 'store';

import { usePagination, useQueryParams, useSearch } from 'hooks';

import { Input, Link, LinkVariants, Pagination } from 'components';

import { ProductsList } from 'modules';

export const Products = () => {
  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const dispatch = useDispatch();

  const list = useSelector(selectProducts);
  const isLoading = useSelector(selectProductsIsLoading);
  const count = useSelector(selectProductsCount);

  const { t } = useTranslation();

  const getProducts = async () => {
    dispatch(asyncGetProducts({ page, search }));
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = async (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      await getProducts();
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useQueryParams({ page: page + 1, search });

  useEffect(() => {
    getProducts();
  }, [page]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        <Link href={AppRoutes.PRODUCT_CREATE} variant={LinkVariants.SECONDARY}>
          {t('create product')}
        </Link>
      </div>
      <ProductsList
        list={list}
        reload={getProducts}
        isLoading={isLoading}
        setPage={setPage}
      />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
