import moment from 'moment/moment.js';

export const clearOrderForm = {
  insurer: {
    isLegalEntity: false,
    addressesMatch: true,

    companyName: '',
    codeITN: '',

    firstName: '',
    lastName: '',
    passport: '',
    email: '',
    birthday: '',
    phone: '',
    gender: '',
    citizenship: '',
    czIndex: '',
    czStreet: '',
    czCity: '',
    czCountry: 'Česká republika',

    correspondent: {
      street: '',
      index: '',
      city: '',
      country: 'Česká republika'
    }
  },
  insured: {
    addressesMatch: true,

    firstName: '',
    lastName: '',
    email: '',
    birthday: '',
    phone: '',
    gender: '',
    citizenship: '',
    czIndex: '',
    czStreet: '',
    czCity: '',
    czCountry: 'Česká republika',

    correspondent: {
      street: '',
      index: '',
      city: '',
      country: 'Česká republika'
    }
  },
  insurance: {
    company: null,
    product: null,
    type: null,
    term: null,
    start: moment(new Date()),
    comment: ''
  },
  isSamePersons: false
};
