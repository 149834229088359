import { withLoadingReducer } from 'utils';

import {
  asyncApprovePayment,
  asyncCreateOrder,
  asyncDownloadInvoiceDraft,
  asyncDownloadPaymentProtocol,
  asyncDownloadSampleOrder,
  asyncGetGovUser,
  asyncGetOrder,
  asyncGetOrderPrices,
  asyncGetOrderXML,
  asyncGetOrders,
  asyncGetPopulatedOrder,
  asyncOrderPayLater,
  asyncOrderRetryPayment,
  asyncOrderVoucher,
  asyncSendCalculateClient,
  asyncSendCancelClient,
  asyncSendPaymentDetails,
  asyncSendValidateClient,
  asyncUpdateOrder,
  asyncUpdatePaymentType,
  unsetGovUser,
  unsetIsCancelSend,
  unsetOrder
} from './actions.js';

export const getOrdersReducer = (builder) => {
  builder
    .addCase(asyncGetOrders.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.orders = payload.list;
      state.ordersCount = payload.count;
      state.ordersCounts = payload.counts;
    })
    .addCase(asyncGetOrders.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getOrderReducer = (builder) => {
  builder
    .addCase(asyncGetOrder.pending, (state) => {
      state.isLoading = true;
      state.isUpdatedPayment = false;
    })
    .addCase(asyncGetOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.order = payload;
    })
    .addCase(asyncGetOrder.rejected, (state) => {
      state.isLoading = false;
      state.isSuccess = false;
    });
};

export const getGovUserReducer = (builder) => {
  builder
    .addCase(asyncGetGovUser.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetGovUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.govUser = payload;
    })
    .addCase(asyncGetGovUser.rejected, (state) => {
      state.isLoading = false;
    });
};

export const createOrderReducer = withLoadingReducer(asyncCreateOrder);

export const getPopulatedOrderReducer = (builder) => {
  builder
    .addCase(asyncGetPopulatedOrder.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetPopulatedOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order = payload;
    })
    .addCase(asyncGetPopulatedOrder.rejected, (state) => {
      state.isLoading = false;
    });
};

export const approvePaymentReducer = (builder) => {
  builder
    .addCase(asyncApprovePayment.pending, (state) => {
      state.isLoading = true;
      state.isDownloading = true;
    })
    .addCase(asyncApprovePayment.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order = payload;
      state.isDownloading = false;
    })
    .addCase(asyncApprovePayment.rejected, (state) => {
      state.isLoading = false;
      state.isDownloading = false;
    });
};

export const sendPaymentDetailsReducer = (builder) => {
  builder
    .addCase(asyncSendPaymentDetails.pending, (state) => {
      state.isSendDetailsLoading = true;
    })
    .addCase(asyncSendPaymentDetails.fulfilled, (state, { payload }) => {
      state.isSendDetailsLoading = false;
      state.order = payload;
    })
    .addCase(asyncSendPaymentDetails.rejected, (state) => {
      state.isLoading = false;
      state.isSendDetailsLoading = false;
    });
};

export const updateOrderVoucherReducer = withLoadingReducer(asyncOrderVoucher);

export const downloadInvoiceDraft = withLoadingReducer(
  asyncDownloadInvoiceDraft
);

export const downloadPaymentProtocol = withLoadingReducer(
  asyncDownloadPaymentProtocol
);
export const downloadSampleOrderReducer = withLoadingReducer(
  asyncDownloadSampleOrder
);

export const calculateClientReducer = (builder) => {
  builder
    .addCase(asyncSendCalculateClient.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncSendCalculateClient.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.prices = payload.prices;
      state.maxDiscount = payload.maxDiscount || 0;
    })
    .addCase(asyncSendCalculateClient.rejected, (state) => {
      state.isLoading = false;
    });
};

export const validateClientReducer = (builder) => {
  builder
    .addCase(asyncSendValidateClient.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncSendValidateClient.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.validation = payload;
    })
    .addCase(asyncSendValidateClient.rejected, (state) => {
      state.isLoading = false;
    });
};

export const cancelClientReducer = (builder) => {
  builder
    .addCase(asyncSendCancelClient.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncSendCancelClient.fulfilled, (state) => {
      state.isLoading = false;
      state.isCancelSend = true;
    })
    .addCase(asyncSendCancelClient.rejected, (state) => {
      state.isLoading = false;
      state.isCancelSend = false;
    });
};

export const updatePaymentTypeReducer = (builder) => {
  builder
    .addCase(asyncUpdatePaymentType.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdatePaymentType.fulfilled, (state) => {
      state.isLoading = false;
      state.isUpdatedPayment = true;
    })
    .addCase(asyncUpdatePaymentType.rejected, (state) => {
      state.isLoading = false;
      state.isUpdatedPayment = false;
    });
};

export const updateOrderReducer = (builder) => {
  builder
    .addCase(asyncUpdateOrder.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateOrder.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.order = payload.order;
      state.isUpdatedOrder = true;
    })
    .addCase(asyncUpdateOrder.rejected, (state) => {
      state.isLoading = false;
      state.isUpdatedOrder = false;
    });
};

export const getOrderPricesReducer = (builder) => {
  builder
    .addCase(asyncGetOrderPrices.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetOrderPrices.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.prices = payload;
    })
    .addCase(asyncGetOrderPrices.rejected, (state) => {
      state.isLoading = false;
      state.isUpdatedOrder = false;
    });
};

export const getOrderXMLReducer = (builder) => {
  builder
    .addCase(asyncGetOrderXML.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetOrderXML.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.xml = payload;
    })
    .addCase(asyncGetOrderXML.rejected, (state) => {
      state.isLoading = false;
      state.isUpdatedOrder = false;
    });
};

export const unsetIsCancelSendReducer = (builder) => {
  builder.addCase(unsetIsCancelSend, (state) => {
    state.isCancelSend = false;
  });
};

export const unsetGovUserReducer = (builder) => {
  builder.addCase(unsetGovUser, (state) => {
    state.govUser = null;
  });
};

export const unsetOrderReducer = (builder) => {
  builder.addCase(unsetOrder, (state) => {
    state.order = null;
  });
};

export const orderRetryPaymentReducer = withLoadingReducer(
  asyncOrderRetryPayment
);
export const orderPayLaterReducer = withLoadingReducer(asyncOrderPayLater);
