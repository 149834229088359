import { createSelector } from '@reduxjs/toolkit';

const selectReportsState = (state) => state.reports;

export const selectStats = createSelector(
  selectReportsState,
  (state) => state.stats
);

export const selectFather = createSelector(
  selectReportsState,
  (state) => state.father
);
