import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants';

import { asyncGetCompany, asyncUpdateCompany } from 'store';

import { CompanyForm, EmailForm } from 'modules';
import { CompanyPdf } from 'modules/CompanyPdfs/CompanyPdf.jsx';

export const Company = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [active, setActive] = useState(1);

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    await dispatch(
      asyncUpdateCompany({
        id,
        payload: data
      })
    );
    navigate(AppRoutes.COMPANIES);
  };

  const arr = [
    { tab: t('company') },
    { tab: t('companies - email to insured person') },
    { tab: t('companies - pdfs') }
  ];

  const forms = {
    1: <CompanyForm id={id} />,
    2: <EmailForm id={id} onSubmit={onSubmit} />,
    3: <CompanyPdf id={id} />
  };

  const handleTabClick = (idx) => () => {
    setActive(idx + 1);
  };

  useEffect(() => {
    if (id) dispatch(asyncGetCompany(id));
  }, [dispatch, id]);

  return (
    <div className="flex flex-col">
      {id && (
        <div className="flex items-center gap-4">
          {arr.map(({ tab }, idx) => (
            <button
              key={tab}
              onClick={handleTabClick(idx)}
              className={cs('transition-colors ease-in-out duration-500', {
                'text-green-2': active === idx + 1,
                'text-grey-4 hover:text-green-2': active !== idx + 1
              })}>
              {tab}
            </button>
          ))}
        </div>
      )}
      <div className="p-4">{forms[active]}</div>
    </div>
  );
};
