import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { selectUser } from 'store/index.js';

import { Button, Table } from 'components';

import AttentionSrc from 'svg/attention.svg';
import editSrc from 'svg/edit.svg';
import noteSrc from 'svg/note.svg';

import viewSrc from 'png/view.png';

export const OrdersList = ({ list, isLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const role = user?.role;

  const Actions = ({ status, _id }) => {
    const actionSetStorage = () => {
      const orderId = sessionStorage.getItem('orderId');

      if (orderId !== _id) {
        sessionStorage.setItem('clientIndex', '0');
      }

      sessionStorage.setItem('orderId', _id);
    };

    const continueOrder = () => {
      actionSetStorage();
      navigate(`/orders/${_id}`);
    };

    const viewOrder = () => {
      actionSetStorage();
      navigate(`/orders/payment/${_id}`);
    };

    return (
      <div className="flex">
        {status === 'draft' &&
          (role === 'partner-agent' ||
            role === 'partner-member' ||
            role === 'admin') && (
            <Button
              onClick={continueOrder}
              className="!bg-transparent border-0 !px-2">
              <img className="w-4 h-4" src={editSrc} alt="icon" />
            </Button>
          )}
        {(status !== 'draft' || role !== 'admin') && (
          <Button
            onClick={viewOrder}
            className="!bg-transparent border-0 !px-2">
            <img className="w-4 h-4" src={viewSrc} alt="icon" />
          </Button>
        )}
      </div>
    );
  };

  const cfg = [
    {
      valueFunc: (_, el) => (
        <div className="flex gap-1 items-center">
          {t(`order #`, { id: el.id })}
          <span data-tooltip-id={el.id} className="text-red-500">
            {((el?.isSync !== null &&
              el?.isSync !== undefined &&
              !el?.isSync) ||
              el?.status === 'axa-pay-error' ||
              el?.status === 'bad-axa-connection' ||
              el?.status === 'axa-id-exists' ||
              el?.status === 'negotiation-number-error') && (
              <img className="w-4 h-4" src={AttentionSrc} alt=" (!)" />
            )}
          </span>
          {el?.status !== 'draft' &&
            ((el?.mediatorFather && el?.partner?._id === user?._id) ||
              (role === 'admin' && el?.mediatorFather)) && (
              <p className="text-xs py-1 px-2 bg-blue-2-light text-white rounded">
                {t('mediator')}
              </p>
            )}
          <Tooltip place="right" className="z-30" id={el.id}>
            {t(!el?.isSync ? 'axa-sync-error' : el?.status)}
          </Tooltip>
        </div>
      ),
      key: 'id',
      header: t('order id')
    },
    {
      valueFunc: (_, el) => el?.clients?.length || 0,
      key: `clients`,
      header: t('clients')
    },
    {
      valueFunc: (_, el) => `${el?.prices?.withDiscount} CZK`,
      key: `price`,
      header: t('price')
    },
    {
      valueFunc: (_, el) => t(`order - status - ${el?.status || 'draft'}`),
      key: 'status',
      header: t('status')
    },
    {
      valueFunc: (_, el) => `${el?.prices?.comission} CZK`,
      key: `comission`,
      header: t('comission')
    },
    {
      valueFunc: (_, el) =>
        t(`comission status - ${el?.facture?.status || '_'}`),
      key: `comission-status`,
      header: t('comission status')
    },
    {
      valueFunc: (_, el) =>
        el.status === 'pay-later' && (
          <span className="flex items-center">
            <img
              data-tooltip-id="note"
              className="w-4 h-4 cursor-pointer"
              src={noteSrc}
              alt="icon"
            />
            <Tooltip className="z-10" id="note">
              {t('contract pay later')}
            </Tooltip>
          </span>
        ),
      key: 'contract status',
      header: t('contract status'),
      propsKeys: ['status', '_id']
    },
    {
      component: Actions,
      key: 'actions',
      header: t('actions'),
      propsKeys: ['status', '_id']
    }
  ];

  const updatedCfg = [
    ...(role !== 'partner-member' && role !== 'partner-agent'
      ? [
          {
            valueFunc: (_, el) =>
              `${el.partner?.firstName} ${el.partner.lastName}`,
            key: `partnerName`,
            header: t('partner name')
          },
          ...cfg
        ]
      : cfg)
  ];

  return <Table config={updatedCfg} items={list} isLoading={isLoading} />;
};
