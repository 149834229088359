import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutes } from 'constants/index.js';

import i18n from 'i18n/index.js';

import { Link, LinkVariants, Logo } from 'components';

import { LoginForm } from 'modules';

export const Login = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('logged')) {
      toast.error(i18n.t('new login'));
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col p-4 items-center h-screen justify-between">
      <div className="flex flex-col items-center w-full gap-16">
        <div className="flex flex-row justify-end w-full">
          <Link
            href={AppRoutes.PARTNER_REGISTER}
            variant={LinkVariants.SECONDARY}>
            {t('login - become partner')}
          </Link>
        </div>
        <Logo />
        <LoginForm />
      </div>

      <p className="text-center text-sm mb-8 flex flex-col">
        <Link href={AppRoutes.FORGET_PASSWORD}>
          {t('login - forgot password')}
        </Link>
        <Link href={AppRoutes.PRIVACY_POLICY}>{t('login - policies')}</Link>
      </p>
    </div>
  );
};
