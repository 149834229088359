import cs from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  asyncGetSettings,
  asyncUpdateSettings,
  selectSettings,
  unsetSettings
} from 'store';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange.jsx';

import {
  EmailForm,
  EmailSettingsForm,
  InfoPageSettingsForm,
  MainSettingsForm,
  PaymentSettingsForm
} from 'modules';

export const Settings = () => {
  const [active, setActive] = useState(1);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const settings = useSelector(selectSettings);

  const onSubmit = (data) => dispatch(asyncUpdateSettings(data));

  const arr = [
    { tab: t('settings - main') },
    { tab: t('settings - email') },
    { tab: t('settings - payment') },
    { tab: t('settings - additional') },
    { tab: t('settings - payment - email') }
  ];

  const forms = {
    1: <MainSettingsForm settings={settings} />,
    2: <EmailSettingsForm settings={settings} />,
    3: <PaymentSettingsForm settings={settings} />,
    4: <InfoPageSettingsForm settings={settings} />,
    5: <EmailForm onSubmit={onSubmit} isSettings />
  };

  const handleTabClick = (idx) => () => setActive(idx + 1);

  useEffect(() => {
    dispatch(asyncGetSettings());
  }, [dispatch]);

  useBeforeRouteChange(() => dispatch(unsetSettings()));

  if (!settings)
    return (
      <p className="flex items-center justify-center">{t('missing items')}</p>
    );
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        {arr.map(({ tab }, idx) => (
          <button
            key={tab}
            onClick={handleTabClick(idx)}
            className={cs('transition-colors ease-in-out duration-500', {
              'text-green-2': active === idx + 1,
              'text-grey-4 hover:text-green-2': active !== idx + 1
            })}>
            {tab}
          </button>
        ))}
      </div>
      <div className="p-4">{forms[active]}</div>
    </div>
  );
};
