import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import i18n from 'i18n';

import { store } from 'store';

import { App } from './App.jsx';
import './index.css';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
        <ToastContainer
          closeOnClick={false}
          autoClose={10000}
          closeButton={true}
          draggable={false}
        />
        <div id="portal-modal" />
      </I18nextProvider>
    </Provider>
  </BrowserRouter>
);
