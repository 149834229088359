import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  becomePartnerReducer,
  checkRecoveryTokenReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  sendLoginReducer
} from './reducers.js';

const initialState = {
  user: null,
  isLoading: false,
  isAuthorized: false
};

const slice = createSlice({
  name: SliceNames.AUTH,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    checkRecoveryTokenReducer(builder);
    becomePartnerReducer(builder);
    resetPasswordReducer(builder);
    forgotPasswordReducer(builder);
    sendLoginReducer(builder);
  }
});

export const authReducer = slice.reducer;
