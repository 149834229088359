import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { asyncDeleteCompanyPdf, selectCompany } from 'store/index.js';

import { useModal } from 'hooks/index.js';

import { Table } from 'components/index.js';

import { CompanyPdfListActions } from 'modules/CompanyPdfs/CompanyPdfListActions.jsx';
import { YesNoModal } from 'modules/ModalTemplates/YesNoModal.jsx';

export const CompanyPdfList = ({ id }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { isModalOpened, handleOnCloseModal, handleOnOpenModal } = useModal();

  const [pdfName, setPdfName] = useState('');

  const company = useSelector(selectCompany);

  const deleteCompanyPdf = () => {
    dispatch(asyncDeleteCompanyPdf({ id, payload: { file: pdfName } }))
      .unwrap()
      .then(() => {
        handleOnCloseModal();
      });
  };

  const cfg = [
    {
      valueFunc: (_, el) =>
        el
          .split('/')
          .filter((x) => x)
          .pop(),
      key: 'name',
      header: t('name')
    },
    {
      valueFunc: (_, el) => (
        <CompanyPdfListActions
          handleOnOpenModal={handleOnOpenModal}
          setPdfName={setPdfName}
          el={el}
        />
      ),
      key: 'actions',
      header: t('actions'),
      propsKeys: ['_id']
    }
  ];

  if (!company?.files?.length) return null;
  return (
    <div className="flex flex-col gap-1">
      <span>{t('companies - pdfs - list')}</span>
      <Table config={cfg} items={company.files} />
      <YesNoModal
        isOpen={isModalOpened}
        onApprove={deleteCompanyPdf}
        question={t('delete pdf')}
        approveLabel={t('yes')}
        declineLabel={t('no')}
        handleClose={handleOnCloseModal}
        onDecline={handleOnCloseModal}
      />
    </div>
  );
};
