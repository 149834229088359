import { withLoadingReducer } from 'utils';

import { unsetCommission } from 'store/commissions/actions.js';

import {
  asyncGetPaymentTypes,
  asyncGetSettings,
  asyncSendTestEmail,
  asyncUpdateSettings,
  unsetSettings
} from './actions.js';

export const getSettingsReducer = (builder) => {
  builder
    .addCase(asyncGetSettings.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.settings = payload;
    })
    .addCase(asyncGetSettings.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getPaymentTypesReducer = (builder) => {
  builder
    .addCase(asyncGetPaymentTypes.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetPaymentTypes.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.paymentTypes = payload;
    })
    .addCase(asyncGetPaymentTypes.rejected, (state) => {
      state.isLoading = false;
    });
};

export const sendTestEmailReducer = withLoadingReducer(asyncSendTestEmail);
export const updateSettingsReducer = (builder) => {
  builder
    .addCase(asyncUpdateSettings.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateSettings.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.settings = payload;
    })
    .addCase(asyncUpdateSettings.rejected, (state) => {
      state.isLoading = false;
    });
};

export const unsetSettingsReducer = (builder) => {
  builder.addCase(unsetSettings, (state) => {
    state.settings = null;
  });
};
