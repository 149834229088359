import i18n from 'i18n/index.js';

export const getPaymentMethods = (order, partner, updatePaymentType) => {
  const user = partner;

  return [
    {
      condition: user?.allowedPaymentMethods?.includes('stripe'),
      label: i18n.t('online'),
      onClick: updatePaymentType('stripe')
    },
    {
      condition: user?.allowedPaymentMethods?.includes('bank'),
      label: i18n.t('bank'),
      onClick: updatePaymentType('bank')
    },
    {
      condition: user?.allowedPaymentMethods?.includes('office'),
      label: i18n.t('office'),
      onClick: updatePaymentType('office')
    },
    {
      condition: user?.allowedPaymentMethods?.includes('deposit'),
      label: i18n.t('deposit'),
      onClick: updatePaymentType('deposit')
    }
  ];
};
