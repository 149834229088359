import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { max, num } from 'normalizers';

import {
  asyncGetPartner,
  asyncGetVouchers,
  asyncUpdatePartner,
  selectVouchers
} from 'store/index.js';

import { Button, Input, Select } from 'components';

import AddSrc from 'svg/add.svg';
import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

export const PartnerVouchersForm = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const vouchers = useSelector(selectVouchers);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const partner = {
        vouchers: []
      };

      if (id) {
        const data = await dispatch(asyncGetPartner(id)).unwrap();

        Object.keys(partner).forEach((key) => {
          if (data[key]) partner[key] = data[key];
        });
      }

      return partner;
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vouchers'
  });
  const { t } = useTranslation();

  const onSubmit = (data) => {
    dispatch(asyncUpdatePartner({ id, data }));
  };

  const headers = [{ header: t('code') }, { header: t('amount') }];

  const checkErrors = (index) => {
    const voucher = watch(`vouchers`)[index];

    if (!voucher.voucher)
      setError(`vouchers.${index}.voucher`, {
        type: 'required',
        message: t('error - required')
      });

    if (!voucher.amount)
      setError(`vouchers.${index}.amount`, {
        type: 'required',
        message: t('error - required')
      });

    return errors?.vouchers?.[index];
  };
  const handleSaveVoucher = (index) => () => {
    if (checkErrors(index)) return;

    setIsEditing(false);
  };
  const handleEditVoucher = (index) => () => {
    if (isEditing !== false && checkErrors(isEditing)) return;

    setIsEditing(index);
  };
  const handleDeleteVoucher = (index) => () => remove(index);
  const handleAddVoucher = () => {
    append({
      voucher: '',
      amount: ''
    });
    const idx = getValues()?.vouchers?.length - 1;

    setIsEditing(idx);
  };

  useEffect(() => {
    dispatch(asyncGetVouchers());
  }, []);

  if (!vouchers) return <p>{t('missing items')}</p>;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="w-full">
        <label className="text-grey-3 text-sm">{t('vouchers')}*</label>
        <table className="table-auto w-full">
          <thead>
            <tr className="border">
              {headers.map(({ header }) => (
                <th className="text-left text-sm p-2" key={header}>
                  {header}
                </th>
              ))}
              <th className="text-left text-sm p-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => {
              const _vouchers = vouchers.map(({ _id, code }) => ({
                value: _id,
                label: code
              }));
              const voucher = watch(`vouchers.${index}.voucher`);
              const voucherAmount =
                vouchers.find((el) => el._id === voucher)?.amount || 0;

              return (
                <tr className="border" key={field.id}>
                  <td className="text-left text-sm p-2 w-1/4">
                    <Controller
                      control={control}
                      name={`vouchers.${index}.voucher`}
                      rules={{ required: t('error - required') }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          name={`vouchers.${index}.voucher`}
                          disabled={isEditing !== index}
                          options={_vouchers}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </td>
                  <td className="text-left text-sm p-2 w-1/4">
                    <Input
                      normalize={[num, max(voucherAmount)]}
                      disabled={isEditing !== index}
                      label={''}
                      {...register(`vouchers.${index}.amount`, {
                        required: t('error - required')
                      })}
                    />
                  </td>
                  <td className="text-left text-sm p-2 w-1/4">
                    {isEditing === index && (
                      <Button onClick={handleSaveVoucher(index)}>
                        {t('save')}
                      </Button>
                    )}
                    {isEditing !== index && (
                      <div className="flex flex-row">
                        <img
                          onClick={handleEditVoucher(index)}
                          className="cursor-pointer pr-2"
                          src={EditSrc}
                          alt="edit"
                        />
                        <img
                          onClick={handleDeleteVoucher(index)}
                          className="cursor-pointer pl-2"
                          src={DeleteSrc}
                          alt="delete"
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="w-1/4"></td>
              <td className="w-1/4"></td>
              <td className="w-1/4"></td>
              <td className="w-1/4 text-left text-sm p-2">
                {isEditing === false && (
                  <img
                    src={AddSrc}
                    onClick={handleAddVoucher}
                    className="float-right cursor-pointer h-[30px]"
                    alt="add"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {errors['types']?.message && (
          <span className="text-xs text-error">{errors['types']?.message}</span>
        )}
      </div>
      <div>
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
