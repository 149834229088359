import { asyncGetLogs, asyncGetLogsItem, unsetLog } from './actions.js';

export const getLogsReducer = (builder) => {
  builder
    .addCase(asyncGetLogs.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetLogs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.logs = payload.list;
      state.logsCount = payload.count;
    })
    .addCase(asyncGetLogs.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getLogsItemReducer = (builder) => {
  builder
    .addCase(asyncGetLogsItem.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetLogsItem.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.log = payload;
    })
    .addCase(asyncGetLogsItem.rejected, (state) => {
      state.isLoading = false;
    });
};

export const unsetLogReducer = (builder) => {
  builder.addCase(unsetLog, (state) => {
    state.log = null;
  });
};
