import cs from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

export const Table = ({
  items = [],
  config = [],
  missingText = 'missing items',
  hasDropDown,
  dropDownContent,
  className,
  tooltipChildren,
  isLoading,
  skeletonRowCount = 10
}) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState('');

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('dropId');
    if (id && hasDropDown) {
      setSelectedIndex(id);
    }
  }, [searchParams]);

  if (!items || items.length < 1)
    return (
      <p className="flex items-center justify-center text-center">
        {t(missingText)}
      </p>
    );
  return (
    <table className={cs('table-auto w-full', className)}>
      <thead>
        <tr className="border">
          {config.map(({ header, hClass }, idx) => (
            <th key={idx} className={cs('text-left text-sm p-2', hClass)}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!items?.length && <tr className="text-sm p-2">{missingText}</tr>}
        {isLoading
          ? Array.from(Array(skeletonRowCount)).map((_, index) => (
              <tr key={index} className="h-11">
                {config.map((_, index) => (
                  <td
                    className="skeleton-loader border border-white "
                    key={index}
                  />
                ))}
              </tr>
            ))
          : items.map((el, index) => {
              return (
                <Fragment key={el?._id ?? index}>
                  <tr
                    data-tooltip-id={`${el?._id}`}
                    className={cs(
                      'text-sm border',
                      { 'border-t-0': hasDropDown },
                      { 'cursor-pointer': tooltipChildren }
                    )}>
                    {config.map(
                      ({
                        valueFunc,
                        key,
                        component: Component,
                        propsKeys = [],
                        cellClassName
                      }) => {
                        let _cellClassName = '';
                        if (cellClassName) {
                          if (typeof cellClassName === 'function')
                            _cellClassName = cellClassName(index) || '';
                          else _cellClassName = cellClassName;
                        }

                        if (Component) {
                          const componentProps = propsKeys.reduce(
                            (acc, curr) => {
                              acc[curr] = el[curr];

                              return acc;
                            },
                            {}
                          );

                          return (
                            <td
                              key={`${el._id}-${key}`}
                              className={cs('p-2', _cellClassName)}>
                              <Component {...componentProps} index={index} />
                            </td>
                          );
                        } else {
                          const val =
                            typeof valueFunc === 'function'
                              ? valueFunc(el[key], el)
                              : el[key];

                          return (
                            <td
                              key={`${el._id}-${key}`}
                              className={cs('p-2', _cellClassName)}>
                              {val}
                            </td>
                          );
                        }
                      }
                    )}
                  </tr>
                  {tooltipChildren && (
                    <Tooltip place="bottom" className="z-10" id={`${el?._id}`}>
                      {tooltipChildren(index)}
                    </Tooltip>
                  )}
                  {hasDropDown && dropDownContent && (
                    <tr>
                      <td colSpan={config.length} className="p-0">
                        <div
                          className="grid transition-all overflow-hidden [&>*]:overflow-hidden"
                          style={{
                            gridTemplateRows:
                              selectedIndex === `${index}` ? '1fr' : '0fr',
                            height: selectedIndex === `${index}` ? 'auto' : '0',
                            opacity: selectedIndex === `${index}` ? '100%' : '0'
                          }}>
                          {dropDownContent[index]}
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
      </tbody>
    </table>
  );
};
