import { useState } from 'react';

export const useModal = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleOnOpenModal = () => {
    setIsModalOpened(true);
  };

  const handleOnCloseModal = () => {
    setIsModalOpened(false);
  };

  return { isModalOpened, handleOnOpenModal, handleOnCloseModal };
};
