import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { clientsApi } from 'api';

import i18n from 'i18n';

export const asyncGetClients = createAsyncThunk(
  `${SliceNames.CLIENTS}/GET_CLIENTS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await clientsApi.getClients(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);
