import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutes, EMAIL_OPTIONS, PASSWORD_OPTIONS } from 'constants/index.js';

import { selectIsLoading } from 'store/auth/selectors.js';
import { asyncGetSelf, asyncSendLogin, setSelf } from 'store/index.js';

import { Button, Input } from 'components';

export const LoginForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);

  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const onSubmit = async (data) => {
    try {
      const response = await dispatch(asyncSendLogin(data));

      if (response?.user?.lang) i18n.changeLanguage(response.user.lang);

      const isFirstLogin = response.user?.isFirstLogin;
      const role = response.user?.role;

      window.dispatchEvent(new Event('storage'));
      navigate(
        isFirstLogin && role.includes('partner')
          ? AppRoutes.PARTNER_INFO
          : AppRoutes.HOME
      );
    } catch (error) {
      toast.error(t(`form error - ${error.key}`));
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[375px] flex flex-col gap-4">
      <span className="text-2xl">{t('login - login')}</span>

      <Input
        label={t('email')}
        error={errors['email']?.message}
        required
        {...register('email', EMAIL_OPTIONS)}
      />

      <Input
        error={errors['password']?.message}
        type="password"
        label={t('password')}
        required
        {...register('password', PASSWORD_OPTIONS)}
      />

      <Button isLoading={isLoading}>{t('login - login')}</Button>
    </form>
  );
};
