import { useSelector } from 'react-redux';

import { selectUser } from 'store/index.js';

import { DashboardStatistics } from 'modules';

export const Home = () => {
  const user = useSelector(selectUser);
  const { firstName, role } = user || {};

  if (!user) return null;

  return <DashboardStatistics name={firstName} role={role} />;
};
