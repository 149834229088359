import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { asyncChangePassword } from 'store';

import { Button, Input } from 'components';

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
  });

  const onSubmit = async (data) => {
    dispatch(asyncChangePassword(data));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-1/2 flex flex-col gap-4 pr-8">
      <Input
        required
        name="currentPassword"
        type="password"
        label={t('current password')}
        error={errors['currentPassword']?.message}
        {...register('currentPassword', {
          required: t('error - required')
        })}
      />
      <Input
        required
        name="newPassword"
        type="password"
        label={t('new password')}
        error={errors['newPassword']?.message}
        {...register('newPassword', {
          required: t('error - required')
        })}
      />
      <Input
        required
        name="repeatPassword"
        type="password"
        label={t('repeat password')}
        error={errors['repeatPassword']?.message}
        {...register('repeatPassword', {
          required: t('error - required'),
          validate: (val) => {
            const vals = getValues();

            if (val !== vals['newPassword'])
              return t('error - not same passwords');

            return true;
          }
        })}
      />
      <div>
        <Button>{t('save')}</Button>
      </div>
    </form>
  );
};
