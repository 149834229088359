import cn from 'classnames';
import moment from 'moment/moment.js';
import { useTranslation } from 'react-i18next';

import { AppRoutes } from 'constants/index.js';

import { Link, Table } from 'components';

import viewSrc from 'png/view.png';

export const LogsList = ({ list, isLoading }) => {
  const { t } = useTranslation();

  const cfg = [
    {
      valueFunc: (_, el) =>
        `${el?.user?.firstName ?? ''} ${el?.user?.lastName ?? ''}`,
      key: 'user',
      header: t('user')
    },
    {
      key: 'actionDesc',
      header: t('description')
    },
    {
      key: 'actionType',
      header: t('action type')
    },
    {
      valueFunc: (el) => moment(el).format('DD.MM.YYYY HH:mm'),
      key: 'date',
      header: t('date')
    },
    {
      component: ({ _id, payload }) => (
        <Link
          href={`${AppRoutes.LOGS}/${_id}`}
          className={cn('!bg-transparent border-0', { hidden: !payload })}>
          <img className="w-4 h-4" src={viewSrc} alt="view" />
        </Link>
      ),
      key: 'actions',
      header: t('actions'),
      propsKeys: ['_id', 'payload']
    }
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
