import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { selectUser } from 'store/index.js';

import { Link } from 'components';

import { PROTECTED_ROUTES } from '../routes';

export const Header = () => {
  const { t } = useTranslation();
  const bc = useBreadcrumbs(PROTECTED_ROUTES, { excludePaths: ['/'] });

  const user = useSelector(selectUser);

  return (
    <div className="w-5/6 right-0 py-2 px-6 fixed border-b z-20 bg-white flex flex-row justify-between">
      <span>
        {!bc?.length && <span className="leading-4 text-xl">{t('home')}</span>}
        {bc.map(({ breadcrumb, match }, idx) => (
          <span
            key={breadcrumb?.props?.children}
            className="leading-4 text-xl text-grey-1"
          >
            {idx === 0 && bc.length > 1 ? (
              <Link
                href={match?.pathnameBase || ''}
                key={breadcrumb?.props?.children}
                classNames="underline-offset-4"
              >
                {t(breadcrumb?.props?.children)}
              </Link>
            ) : (
              <span className="cursor-default">
                {t(breadcrumb?.props?.children)}
              </span>
            )}

            {idx < bc?.length - 1 && ' / '}
          </span>
        ))}
      </span>
      <span>
        {(user?.role === 'partner-agent' || user?.role === 'partner-member') &&
          t('number of partnership #', { number: user.numberOfPartnership })}
      </span>
    </div>
  );
};
