import { useTranslation } from 'react-i18next';

import { AppRoutes } from 'constants/index.js';

import { Link, LinkVariants } from 'components';

import { PartnerRegisterForm } from 'modules';

export const PartnerRegister = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 p-4 m-4">
      <Link
        variant={LinkVariants.SECONDARY}
        className="self-end"
        href={AppRoutes.LOGIN}
      >
        {t('login - login')}
      </Link>
      <div className="flex flex-col gap-4 w-1/2 mx-auto">
        <h3 className="text-2xl">{t('login - become partner')}</h3>
        <PartnerRegisterForm />
      </div>
    </div>
  );
};
