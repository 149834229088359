import { createSelector } from '@reduxjs/toolkit';

const selectCompaniesState = (state) => state.companies;

export const selectCompanies = createSelector(
  selectCompaniesState,
  (state) => state.companies
);
export const selectCompany = createSelector(
  selectCompaniesState,
  (state) => state.company
);
export const selectCompaniesCount = createSelector(
  selectCompaniesState,
  (state) => state.companiesCount
);
export const selectCompaniesIsLoading = createSelector(
  selectCompaniesState,
  (state) => state.isLoading
);
