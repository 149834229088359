import cn from 'classnames';

import LoadingSrc from 'svg/loading.svg';

export const ButtonVariants = {
  PRIMARY:
    'border border-green-1 bg-green-1 text-white hover:bg-white hover:text-green-1',
  CLEAR: '',
  PRIMARY_INVERTED:
    'border border-green-1 bg-white text-green-1 hover:bg-green-primary hover:text-white',
  DECLINE:
    'border border-error bg-error text-white hover:bg-white hover:text-error'
};

export const Button = ({
  disabled,
  children,
  className,
  onClick,
  variant = ButtonVariants.PRIMARY,
  isLoading = false,
  loaderClassName,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'py-1 px-6 rounded cursor-pointer relative',
        className,
        {
          'text-grey-5 cursor-default pointer-events-none': disabled
        },
        variant
      )}
      disabled={isLoading || disabled}
      {...props}>
      {isLoading && (
        <div
          className={cn(
            'absolute inset-0 bg-green-1 flex items-center justify-center rounded',
            loaderClassName
          )}>
          <img src={LoadingSrc} className="w-6 h-6" alt="loading" />
        </div>
      )}
      {children}
    </button>
  );
};
