import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectFather } from 'store';

import { Button } from 'components';

export const ContactManager = ({ handleOnCloseModal }) => {
  const { name, email, phone } = useSelector(selectFather);

  const { t } = useTranslation();

  const modalData = [
    {
      header: 'email',
      href: `mailto:${email}`,
      data: email
    },
    {
      header: 'phone',
      href: `tel:${phone}`,
      data: phone
    }
  ];

  return (
    <div className="flex flex-col justify-between h-[30vh] xl:w-[25vw] gap-8 p-8">
      <div>
        <p className="text-3xl">{name}</p>
        <div>
          {modalData.map(({ header, href, data }) => (
            <span key={href} className="flex gap-2">
              {t(header)}:
              <a className="text-blue-1" href={href}>
                {data}
              </a>
            </span>
          ))}
        </div>
      </div>
      <Button onClick={handleOnCloseModal}>{t('close')}</Button>
    </div>
  );
};
