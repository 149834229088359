import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { asyncGetOrderPrices, selectOrderPrices } from 'store/index.js';

import { Logo } from 'components/index.js';

export const PaymentQR = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const prices = useSelector(selectOrderPrices);

  useEffect(() => {
    dispatch(asyncGetOrderPrices(id));
  }, [dispatch, id]);

  if (!prices) return null;

  return (
    <div className="flex flex-col items-center justify-center gap-4 w-full h-full">
      <Logo />
      <div className="flex flex-col items-center justify-center">
        <span>{t('account number')}: 111-1111111</span>
        <span>{t('bank code')}: 5500</span>
        <span>{t('variable symbol')}: 0012</span>
        <span>
          {t('amount')}: {prices?.orderPrices?.withDiscount} CZK
        </span>
      </div>
    </div>
  );
};
