import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { asyncUpdateSettings } from 'store';

import { Button, EditorHtml, FlagSelect } from 'components';

export const InfoPageSettingsForm = ({ settings }) => {
  const [lang, setLang] = useState('US');

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isLoading, isValid, errors, defaultValues }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const settingsData = {
        personalInfoUS: '',
        personalInfoCZ: '',
        personalInfoRU: '',
        textContractUS: '',
        textContractCZ: '',
        textContractRU: '',
        textVoucherUS: '',
        textVoucherRU: '',
        textVoucherCZ: ''
      };

      if (settings) {
        Object.keys(settingsData).forEach((key) => {
          if (settings[key]) settingsData[key] = settings[key];
        });
      }

      return settingsData;
    }
  });

  const { t } = useTranslation();

  const onSubmit = async (data) => {
    dispatch(asyncUpdateSettings(data));
  };

  const flags = ['US', 'RU', 'CZ'];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <FlagSelect
          placeholder={t('select language')}
          wrapperClassname="w-1/4"
          required
          label={t('standart language')}
          selected={lang}
          onSelect={setLang}
          value={lang}
        />
        <div>
          {flags.map((code) => {
            return (
              code === lang && (
                <div className="flex flex-col gap-4" key={code}>
                  <Controller
                    control={control}
                    name={`personalInfo${code}`}
                    rules={{ required: t('error - required') }}
                    render={({ field: { onChange, value } }) => (
                      <EditorHtml
                        defaultValue={defaultValues?.[`personalInfo${code}`]}
                        label={t('personal info')}
                        onChange={onChange}
                        required
                        value={value}
                        name={`personalInfo${code}`}
                        error={errors[`personalInfo${code}`]?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`textContract${code}`}
                    required
                    rules={{ required: t('error - required') }}
                    render={({ field: { onChange, value } }) => (
                      <EditorHtml
                        defaultValue={defaultValues?.[`textContract${code}`]}
                        onChange={onChange}
                        required
                        value={value}
                        label={t('contract info')}
                        name={`textContract${code}`}
                        error={errors[`textContract${code}`]?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`textVoucher${code}`}
                    rules={{ required: t('error - required') }}
                    render={({ field: { onChange, value } }) => (
                      <EditorHtml
                        defaultValue={defaultValues?.[`textVoucher${code}`]}
                        onChange={onChange}
                        value={value}
                        label={t('vouchers info')}
                        required
                        name={`textVoucher${code}`}
                        error={errors[`textVoucher${code}`]?.message}
                      />
                    )}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
      <div>
        <Button type="submit" disabled={!isLoading && !isValid}>
          {t('save')}
        </Button>
      </div>
    </form>
  );
};
