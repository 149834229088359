import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  asyncGetCommissions,
  selectCommissions,
  selectCommissionsCount,
  selectCommissionsIsLoading
} from 'store';

import { usePagination, useQueryParams, useSearch } from 'hooks';

import { Datepicker, Input, Pagination } from 'components';

import { ComissionsList } from 'modules';

export const Comissions = () => {
  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();
  const [searchParams] = useSearchParams();

  const [date, setDate] = useState(searchParams.get('date'));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const list = useSelector(selectCommissions);
  const isLoading = useSelector(selectCommissionsIsLoading);
  const count = useSelector(selectCommissionsCount);

  const getComissions = () => {
    dispatch(asyncGetCommissions({ page, search, date }));
  };

  const handleDateRangeChange = (e) => {
    setDate(e);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      getComissions();
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useQueryParams({ page: page + 1, search, date });

  useEffect(() => {
    getComissions();
  }, [page, date]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <Input
          onKeyPress={handleSubmitSearch}
          onChange={handleSetSearch}
          value={search}
          label={t('search')}
          hideError
        />
        <Datepicker
          isClearable
          wrapperClassname="w-1/4"
          maxDate={new Date()}
          label={t('filter date')}
          onChange={handleDateRangeChange}
          value={date}
        />
      </div>
      <ComissionsList
        list={list}
        reload={getComissions}
        isLoading={isLoading}
      />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
