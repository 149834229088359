import { asyncGetDashboard, asyncGetReports } from './actions.js';

export const getReportsReducer = (builder) => {
  builder
    .addCase(asyncGetReports.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetReports.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reports = payload;
    })
    .addCase(asyncGetReports.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getDashboardReducer = (builder) => {
  builder
    .addCase(asyncGetDashboard.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetDashboard.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.stats = payload.stats;
      state.father = payload.father;
    })
    .addCase(asyncGetDashboard.rejected, (state) => {
      state.isLoading = false;
    });
};
