import { createSelector } from '@reduxjs/toolkit';

const selectClientsState = (state) => state.clients;

export const selectClients = createSelector(
  selectClientsState,
  (state) => state.clients
);

export const selectClientsIsLoading = createSelector(
  selectClientsState,
  (state) => state.isLoading
);

export const selectClientsCount = createSelector(
  selectClientsState,
  (state) => state.clientsCount
);
