import { PhoneNumberUtil } from 'google-libphonenumber';

import i18n from 'i18n/index.js';

export const INCORRECT_PHONE_NUMBER_MESSAGE = i18n.t('error - invalid phone');

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneNumber = (phone) => {
  if (!phone) return undefined;
  try {
    const isValidNumber = phoneUtil.isValidNumber(
      phoneUtil.parseAndKeepRawInput(phone)
    );

    if (!isValidNumber) return INCORRECT_PHONE_NUMBER_MESSAGE;

    return undefined;
  } catch {
    return INCORRECT_PHONE_NUMBER_MESSAGE;
  }
};
