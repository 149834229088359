import { withLoadingReducer } from 'utils';

import {
  asyncCreateCompany,
  asyncDeleteCompany,
  asyncDeleteCompanyPdf,
  asyncGetCompanies,
  asyncGetCompany,
  asyncUpdateCompany
} from './actions.js';

export const getCompaniesReducer = (builder) => {
  builder
    .addCase(asyncGetCompanies.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetCompanies.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.companies = payload.list;
      state.companiesCount = payload.count;
    })
    .addCase(asyncGetCompanies.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getCompanyReducer = (builder) => {
  builder
    .addCase(asyncGetCompany.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetCompany.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.company = payload;
    })
    .addCase(asyncGetCompany.rejected, (state) => {
      state.isLoading = false;
    });
};

export const createCompanyReducer = withLoadingReducer(asyncCreateCompany);

export const updateCompanyReducer = (builder) => {
  builder
    .addCase(asyncUpdateCompany.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateCompany.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.company = payload;
    })
    .addCase(asyncUpdateCompany.rejected, (state) => {
      state.isLoading = false;
    });
};

export const deleteCompanyPdfReducer = (builder) => {
  builder
    .addCase(asyncDeleteCompanyPdf.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncDeleteCompanyPdf.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.company = payload;
    })
    .addCase(asyncDeleteCompanyPdf.rejected, (state) => {
      state.isLoading = false;
    });
};

export const deleteCompanyReducer = withLoadingReducer(asyncDeleteCompany);
