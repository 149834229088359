import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { Header, Sidebar } from 'modules';

export const Wrapper = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthorized = localStorage.getItem('accessToken');

    if (!isAuthorized) navigate(AppRoutes.HOME);
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="relative pt-10 pl-[16.67%] h-full w-full z-10">
        <div className="p-6">{children}</div>
      </div>
    </>
  );
};
