import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { vouchersApi } from 'api';

import i18n from 'i18n';

export const asyncGetVouchers = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_VOUCHERS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await vouchersApi.getVouchers(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetVoucher = createAsyncThunk(
  `${SliceNames.PARTNERS}/GET_VOUCHER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await vouchersApi.getVoucher(payload);
      return data.voucher;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateVoucher = createAsyncThunk(
  `${SliceNames.PARTNERS}/CREATE_VOUCHER`,
  async (payload, { rejectWithValue }) => {
    try {
      await vouchersApi.createVoucher(payload);
      toast.success(i18n.t('voucher created successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateVoucher = createAsyncThunk(
  `${SliceNames.PARTNERS}/UPDATE_VOUCHER`,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      await vouchersApi.updateVoucher(id, data);
      toast.success(i18n.t('updated successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteVoucher = createAsyncThunk(
  `${SliceNames.PARTNERS}/DELETE_VOUCHER`,
  async (payload, { rejectWithValue }) => {
    try {
      await vouchersApi.deleteVoucher(payload);
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);
