import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { ordersApi } from 'api';
import { govApi } from 'api/govApi.js';

import i18n from 'i18n';

export const asyncGetOrders = createAsyncThunk(
  `${SliceNames.ORDERS}/GET_ORDERS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.getOrders(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetOrder = createAsyncThunk(
  `${SliceNames.ORDERS}/GET_ORDER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.getOrder(payload);
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncOrderVoucher = createAsyncThunk(
  `${SliceNames.ORDERS}/UPDATE_VOUCHER_ORDER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.updateOrderVoucher(payload);
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetOrderPrices = createAsyncThunk(
  `${SliceNames.ORDERS}/GET_ORDER_PRICES`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.getOrderPrices(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetOrderXML = createAsyncThunk(
  `${SliceNames.ORDERS}/GET_ORDER_XML`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.getOrderXML(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateOrder = createAsyncThunk(
  `${SliceNames.ORDERS}/CREATE_ORDER`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.createOrder();
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetPopulatedOrder = createAsyncThunk(
  `${SliceNames.ORDERS}/GET_POPULATED_ORDER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.getPopulatedOrder(payload);
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncApprovePayment = createAsyncThunk(
  `${SliceNames.ORDERS}/APPROVE_PAYMENT`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.approvePayment(payload);
      toast.success(i18n.t('payment successfully approved'));
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncSendPaymentDetails = createAsyncThunk(
  `${SliceNames.ORDERS}/SEND_PAYMENT_DETAILS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.sendPaymentDetails(payload);
      toast.success(i18n.t('payment details send'));
      return data.order;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadInvoiceDraft = createAsyncThunk(
  `${SliceNames.ORDERS}/DOWNLOAD_INVOICE_DRAFT`,
  async ({ orderId, clientIdx }, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.downloadInvoiceDraft(orderId, clientIdx);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadPaymentProtocol = createAsyncThunk(
  `${SliceNames.ORDERS}/DOWNLOAD_PAYMENT_PROTOCOL`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.downloadPaymentProtocol(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncDownloadSampleOrder = createAsyncThunk(
  `${SliceNames.ORDERS}/DOWNLOAD_SAMPLE_ORDER`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.downloadSampleOrder(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncSendCalculateClient = createAsyncThunk(
  `${SliceNames.ORDERS}/SEND_CALCULATE_CLIENT`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.sendCalculateClient(payload);

      if (typeof data?.axaPrices === 'string') {
        const xml = new DOMParser().parseFromString(
          data.axaPrices,
          'application/xml'
        );
        const message = xml.querySelector('Message');

        toast.error(message.textContent);
      }
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncSendValidateClient = createAsyncThunk(
  `${SliceNames.ORDERS}/SEND_VALIDATE_CLIENT`,
  async ({ clientIdx, ...payload }, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.sendValidateClient(payload, clientIdx);
      return data.validation;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncSendCancelClient = createAsyncThunk(
  `${SliceNames.ORDERS}/SEND_CANCEL_CLIENT`,
  async ({ orderId, client }, { rejectWithValue }) => {
    try {
      await ordersApi.sendCancelClient(orderId, client);
      toast.success(i18n.t('request sent'));
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdatePaymentType = createAsyncThunk(
  `${SliceNames.ORDERS}/UPDATE_PAYMENT_TYPE`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      await ordersApi.updatePaymentType(id, payload);
    } catch (error) {
      toast.error(i18n.t('check validity clients'));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateOrder = createAsyncThunk(
  `${SliceNames.ORDERS}/UPDATE_ORDER`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await ordersApi.updateOrder(id, payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncOrderPayLater = createAsyncThunk(
  `${SliceNames.ORDERS}/ORDER_PAY_LATER`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      await ordersApi.orderPayLater(id, payload);
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncOrderRetryPayment = createAsyncThunk(
  `${SliceNames.ORDERS}/ORDER_RETRY_PAYMENT`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      await ordersApi.retryPayment(id, payload);
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetGovUser = createAsyncThunk(
  `${SliceNames.ORDERS}/ORDER_GOV_USER`,
  async (id) => {
    const { data } = await govApi.getGovUser(id);
    return data;
  }
);

export const unsetIsCancelSend = createAction(
  `${SliceNames.ORDERS}/UNSET_IS_CANCEL_SEND`
);

export const unsetGovUser = createAction(`${SliceNames.ORDERS}/UNSET_GOV_USER`);
export const unsetOrder = createAction(`${SliceNames.ORDERS}/UNSET_ORDER`);
