import { api } from 'config';

class CompaniesApi {
  constructor(url) {
    this.url = url;
  }

  createCompany(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  updateCompany(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  deleteCompany(id, config) {
    return api.delete(`${this.url}/${id}`, config);
  }

  deleteCompanyPdf(id, payload, config) {
    return api.patch(`${this.url}/delete-file/${id}`, payload, config);
  }

  getCompany(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  getCompanies(queryParams, config) {
    return api.get(
      `${this.url}/list/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&status=${queryParams?.status ?? ''}`,
      config
    );
  }
}

export const companiesApi = new CompaniesApi('/companies');
