import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Toggle } from 'components';

import { Client } from '../SubForms/Client';
import { CorrespondentAddress } from '../SubForms/CorrespondentAddress';

export const Insured = ({ index, isFormDisabled }) => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  const isSamePersons = watch(`clients.${index}.isSamePersons`);
  const isSameAddress = watch(`clients.${index}.insured.addressesMatch`);
  const isInsurerLegal = watch(`clients.${index}.insurer.isLegalEntity`);

  if (isSamePersons && !isInsurerLegal) return null;
  return (
    <div className="flex flex-col">
      <span className="text-lg col-span-full">{t('insured')}</span>
      <div className="grid grid-cols-2 gap-x-16 gap-y-4">
        <Client index={index} isFormDisabled={isFormDisabled} />
        <Controller
          defaultValue=""
          control={control}
          name={`clients.${index}.insured.addressesMatch`}
          render={({ field: { value, onChange } }) => (
            <Toggle
              disabled={isFormDisabled}
              wrapperClassname="col-span-full"
              name={`clients.${index}.insured.addressesMatch`}
              label={t('addresses match')}
              checked={value}
              onChange={onChange}
            />
          )}
        />
        {!isSameAddress && (
          <CorrespondentAddress
            index={index}
            prefix="insured"
            disabled={isFormDisabled}
          />
        )}
      </div>
    </div>
  );
};
