import cs from 'classnames';
import { forwardRef } from 'react';

export const Toggle = forwardRef(
  (
    { name, wrapperClassname, label, hideError, error, inlineLabel, ...props },
    ref
  ) => {
    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <span className="text-grey-3 text-sm cursor-default">{label}</span>
        <div className="flex flex-row">
          <label className="relative inline-flex items-center cursor-pointer w-16 flex-row">
            <input
              ref={ref}
              type="checkbox"
              className="sr-only peer"
              {...props}
            />
            <div
              className={cs(
                'w-16 h-6 bg-grey-5',
                'peer-focus:outline-none peer-checked:after:translate-x-[39px] peer-checked:after:border-white',
                'rounded-full peer',
                'after:content-[""] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-1'
              )}
            />
          </label>
          <span className="text-grey-3 pl-2 text-md cursor-default">
            {inlineLabel}
          </span>
        </div>
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

Toggle.displayName = 'Toggle';
