import { getYear } from 'date-fns';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { downloadFile } from 'utils';

import {
  asyncDownloadPaymentProtocol,
  asyncGetOrder,
  asyncGetSettings,
  selectOrder,
  selectSettings
} from 'store';

import { Button } from 'components';

export const Process = ({ id }) => {
  const order = useSelector(selectOrder);
  const settings = useSelector(selectSettings);

  const variableNumber = order?.id;
  const amount = order?.prices?.withDiscount;

  const qrCode = `SPD*1.0*ACC:${settings?.iban}*AM:${amount}*CC:CZK*X-VS:${getYear(new Date())}${variableNumber}*PT:IP`;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(asyncGetOrder(id));
    dispatch(asyncGetSettings());
  }, []);

  const getOrderPrice = () => {
    const { withDiscount, comission, full } = order.prices;

    switch (order?.paymentType) {
      case 'bank':
      case 'client':
        return withDiscount;
      case 'office':
      case 'deposit':
        return withDiscount - comission;
      default:
        return full;
    }
  };

  const handleDownloadProtocol = async () => {
    const data = await dispatch(asyncDownloadPaymentProtocol(id)).unwrap();

    downloadFile({
      data,
      fileName: `soubor-objednávky-${order.id}.pdf`,
      fileType: 'application/pdf'
    });
  };

  const getPaymentMethod = () =>
    t(`order - payment method - ${order?.paymentType || '_'}`);

  if (!order || !settings) return null;
  return (
    <div className="flex flex-col gap-4 text-sm items-start">
      <span>
        {t('order payment in process', {
          id: order.id,
          price: getOrderPrice(),
          method: getPaymentMethod()
        })}
      </span>
      {(order.paymentType === 'bank' || order.paymentType === 'client') && (
        <>
          <span>
            {t('bank account')}: {settings?.invoiceNumber || '_'}
          </span>
          <span>
            var symbol: {`${getYear(new Date())}${variableNumber}` || '_'}
          </span>
          <QRCodeSVG value={qrCode} />
        </>
      )}
      {order.paymentType === 'deposit' && (
        <Button onClick={handleDownloadProtocol}>
          {t('download-payment-protocol')}
        </Button>
      )}
    </div>
  );
};
