import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectCommission } from 'store/index.js';

export const ComissionInfo = () => {
  const { t } = useTranslation();

  const [commissionSum, setCommissionSum] = useState(0);

  const data = useSelector(selectCommission);

  const headers = ['commission info', 'payment data'];
  const commissionInfo = [
    {
      facture: data?.fakturaXlsId,
      'request date': moment(data?.requestDate).format('DD.MM.YYYY')
    },
    {
      status: t(data?.status),
      'payment type': t(data?.paymentType),
      payout: `${commissionSum} CZK`
    }
  ];

  useEffect(() => {
    if (data?.comissions) {
      setCommissionSum(
        data?.comissions.reduce((accumulator, currentValue) => {
          const calculatedValue =
            currentValue?.status !== 'canceled'
              ? currentValue.comission
              : currentValue.comission - currentValue.storne;

          return accumulator + calculatedValue;
        }, 0)
      );
    }
  }, [data]);

  if (!data) return null;
  return (
    <div className="flex gap-4">
      {commissionInfo.map((data, index) => (
        <div key={index} className="flex gap-2 flex-col flex-1">
          <span className="font-bold text-xl">{t(headers[index])}</span>
          <div className="border p-4 flex flex-col gap-2">
            {Object.keys(data).map((key) => (
              <span key={key}>
                {t(key)}: {data[key] && data[key] !== '' ? data[key] : t('n/a')}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
