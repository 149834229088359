import cs from 'classnames';
import cn from 'classnames';
import moment from 'moment';
import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const Datepicker = forwardRef(
  (
    {
      defaultValue,
      label,
      name,
      error,
      value,
      onChange,
      required,
      hideError = false,
      wrapperClassname,
      minDate = null,
      maxDate = null,
      format = 'dd.MM.yyyy',
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cs('flex flex-col relative', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
          {label}
          {required && '*'}
        </label>
        <DatePicker
          disabled={disabled}
          autoComplete="off"
          minDate={minDate}
          maxDate={maxDate}
          defaultValue={moment(defaultValue).valueOf()}
          yearDropdownItemNumber={100}
          className={cn('rounded border-grey-6 py-1 px-2 text-sm w-full', {
            'border-0': disabled
          })}
          onSelect={onChange}
          onChange={onChange}
          selected={moment(value).valueOf()}
          ref={ref}
          name={name}
          dateFormat={format}
          showYearDropdown
          disabledKeyboardNavigation
          scrollableMonthYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          {...props}
        />
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

Datepicker.displayName = 'Datepicker';
