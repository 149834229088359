import { createSelector } from '@reduxjs/toolkit';

const selectOrdersState = (state) => state.orders;

export const selectOrders = createSelector(
  selectOrdersState,
  (state) => state.orders
);

export const selectOrder = createSelector(
  selectOrdersState,
  (state) => state.order
);

export const selectOrderXML = createSelector(
  selectOrdersState,
  (state) => state.xml
);

export const selectOrdersCounts = createSelector(
  selectOrdersState,
  (state) => state.ordersCounts
);

export const selectOrdersCount = createSelector(
  selectOrdersState,
  (state) => state.ordersCount
);

export const selectValidations = createSelector(
  selectOrdersState,
  (state) => state.validation
);

export const selectIsCancelSend = createSelector(
  selectOrdersState,
  (state) => state.isCancelSend
);

export const selectIsUpdatedPayment = createSelector(
  selectOrdersState,
  (state) => state.isUpdatedPayment
);

export const selectIsUpdatedOrder = createSelector(
  selectOrdersState,
  (state) => state.isUpdatedOrder
);

export const selectOrderIsLoading = createSelector(
  selectOrdersState,
  (state) => state.isLoading
);

export const selectSendDetailsIsLoading = createSelector(
  selectOrdersState,
  (state) => state.isSendDetailsLoading
);

export const selectFileIsDownloading = createSelector(
  selectOrdersState,
  (state) => state.isDownloading
);
export const selectOrderIsSuccess = createSelector(
  selectOrdersState,
  (state) => state.isSuccess
);

export const selectOrderGovUser = createSelector(
  selectOrdersState,
  (state) => state.govUser
);
export const selectOrderPrices = createSelector(
  selectOrdersState,
  (state) => state.prices
);
