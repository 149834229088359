export const EMAIL_PATTERN =
  /^([A-Za-z0-9_\-.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const ITN_PATTERN = /^[0-9]{1,10}$/;

export const BANK_ADDRESS_PATTERN = /^[0-9\-\/]{1,40}$/;

export const BANK_ITN_PATTERN = /^[0-9a-zA-Z]{1,24}$/;

export const PHONE_PATTERN = /^(\+)?\d{10,15}$/g;
