import cs from 'classnames';
import { forwardRef } from 'react';

export const Input = forwardRef(
  (
    {
      name,
      label,
      required,
      disabled = false,
      hideError = false,
      error,
      wrapperClassname,
      onChange,
      normalize = [],
      actionOnChange = null,
      actionOnBlur = null,
      changeOnBlur,
      onBlur,
      ...props
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (normalize.length) {
        normalize.forEach((el) => {
          e.target.value = el(e.target.value);
        });
      }
      if (actionOnChange) {
        actionOnChange(e);
      }

      onChange(e);
    };

    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
          {label}
          {required && '*'}
        </label>
        <input
          onChange={!changeOnBlur ? handleChange : () => {}}
          onBlur={(e) => {
            handleChange(e);
            onBlur(e);
            actionOnBlur && actionOnBlur(e);
          }}
          disabled={disabled}
          ref={ref}
          className={cs('text-sm px-2 py-1 rounded', {
            'border border-grey-6': !disabled,
            'border-0': disabled
          })}
          name={name}
          {...props}
        />
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
