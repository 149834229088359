import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  getPaymentTypesReducer,
  getSettingsReducer,
  sendTestEmailReducer,
  unsetSettingsReducer,
  updateSettingsReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.SETTINGS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getSettingsReducer(builder);
    sendTestEmailReducer(builder);
    updateSettingsReducer(builder);
    getPaymentTypesReducer(builder);
    unsetSettingsReducer(builder);
  }
});

export const settingsReducer = slice.reducer;
