import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import {
  asyncGetClients,
  selectClients,
  selectClientsCount,
  selectClientsIsLoading
} from 'store';

import { useFilter, usePagination, useQueryParams, useSearch } from 'hooks';

import { Input, Pagination, Select } from 'components';

import { ClientsList } from 'modules';

export const Clients = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const queryFilter = searchParams.get('filter');

  const {
    filterOptions,
    selectedFilter: selectedOption,
    setSelectedFilter: setSelectedOption
  } = useFilter([
    {
      label: t('all'),
      value: ''
    },
    {
      label: t('active'),
      value: 'active'
    },
    {
      label: t('canceled'),
      value: 'canceled'
    },
    {
      label: t('expired'),
      value: 'expired'
    }
  ]);

  const { page, setPage } = usePagination();
  const { search, setSearch } = useSearch();

  const dispatch = useDispatch();

  const list = useSelector(selectClients);
  const isLoading = useSelector(selectClientsIsLoading);
  const count = useSelector(selectClientsCount);

  const getClients = (filter) => {
    dispatch(asyncGetClients({ page, search, status: filter }));
  };

  const handleOnFilter = (e) => {
    setSelectedOption(e.target.value);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
  };

  const handleSubmitSearch = (event) => {
    if (event.key === 'Enter') {
      setPage(0);
      getClients();
    }
  };

  const handleSetSearch = (e) => {
    setSearch(e.target.value);
  };

  useQueryParams({
    page: page + 1,
    search,
    filter: selectedOption ?? queryFilter
  });

  useEffect(() => {
    getClients(selectedOption ?? queryFilter ?? '');
  }, [page, selectedOption]);

  if (!list) return null;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-end">
        <div className="flex gap-2 items-end flex-1 justify-end">
          <Select
            defaultValue={
              filterOptions?.find(({ value }) => value === queryFilter) ??
              filterOptions[0]
            }
            onChange={handleOnFilter}
            options={filterOptions}
            wrapperClassname="w-1/4"
          />
          <Input
            wrapperClassname="w-1/4"
            onKeyPress={handleSubmitSearch}
            onChange={handleSetSearch}
            value={search}
            label={t('search')}
            hideError
          />
        </div>
      </div>
      <ClientsList list={list} isLoading={isLoading} />
      <Pagination onPageChange={handlePageClick} count={count} page={page} />
    </div>
  );
};
