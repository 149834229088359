import cs from 'classnames';
import { forwardRef } from 'react';

export const Card = forwardRef(({ children, className, onClick }, ref) => {
  return (
    <div
      onClick={onClick}
      className={cs('rounded-md bg-white', className)}
      ref={ref}>
      {children}
    </div>
  );
});

Card.displayName = 'Card';
