import cs from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  PartnerInfoForm,
  PartnerProductsForm,
  PartnerVouchersForm
} from 'modules';

export const Partner = () => {
  const [active, setActive] = useState(1);

  const { id } = useParams();
  const { t } = useTranslation();

  const arr = [
    { tab: t('partner - info') },
    { tab: t('partner - products') },
    { tab: t('partner - vouchers') }
  ];

  const forms = {
    1: <PartnerInfoForm id={id} />,
    2: <PartnerProductsForm id={id} />,
    3: <PartnerVouchersForm id={id} />
  };

  const handleTabClick = (idx) => () => setActive(idx + 1);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        {arr.map(({ tab }, idx) => (
          <button
            disabled={!id}
            key={tab}
            onClick={handleTabClick(idx)}
            className={cs('transition-colors ease-in-out duration-500', {
              'text-green-2': active === idx + 1,
              'cursor-default': !id,
              'text-grey-4 hover:text-green-2': id && active !== idx + 1
            })}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className={'p-4'}>{forms[active]}</div>
    </div>
  );
};
