import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getVoucherRoute } from 'utils';

import { asyncDeleteVoucher, asyncGetVouchers, selectUser } from 'store';

import { useModal } from 'hooks';

import { Link, Table } from 'components';

import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

import { YesNoModal } from '../';

export const VouchersList = ({ list, isLoading }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const role = user?.role;

  const Actions = ({ _id: id }) => {
    const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();

    const removeVoucher = async () => {
      dispatch(asyncDeleteVoucher(id));
      dispatch(asyncGetVouchers());
    };

    return (
      <div className="flex flex-row gap-2">
        {isModalOpened && (
          <YesNoModal
            onApprove={removeVoucher}
            question={t('modal template - yes-no - voucher - question')}
            approveLabel={t('yes')}
            declineLabel={t('no')}
            handleClose={handleOnCloseModal}
            isOpen={isModalOpened}
            onDecline={handleOnCloseModal}
          />
        )}
        <Link href={getVoucherRoute(id)}>
          <img src={EditSrc} />
        </Link>
        <span
          onClick={handleOnOpenModal}
          className="cursor-pointer hover:underline text-error">
          <img src={DeleteSrc} />
        </span>
      </div>
    );
  };

  const cfg = [
    {
      key: `code`,
      header: t('code'),
      hClass: ''
    },
    {
      valueFunc: (_, el) => (
        <span>
          {moment(el.startsFrom).format('DD.MM.YYYY')} -{' '}
          {moment(el.expiresAfter).format('DD.MM.YYYY')}
        </span>
      ),
      header: t('term'),
      key: 'term',
      hClass: ''
    },
    {
      valueFunc: (company) => <span>{company?.organization}</span>,
      key: `insuranceCompany`,
      header: t('company'),
      hClass: ''
    },
    {
      valueFunc: (product) => <span>{product?.name}</span>,
      key: `insuranceProduct`,
      header: t('product'),
      hClass: ''
    },
    {
      key: `amount`,
      header: t('amount'),
      hClass: ''
    },
    {
      valueFunc: (product) => <span>{product} CZK</span>,
      key: `discount`,
      header: t('discount'),
      hClass: ''
    },
    ...(role !== 'admin'
      ? []
      : [
          {
            header: t('actions'),
            hClass: 'w-1/4',
            component: Actions,
            propsKeys: ['_id']
          }
        ])
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
