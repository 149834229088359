import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { asyncCreateOrder, selectUser, selectUsers } from 'store/index.js';

import { Button, Select, Toggle } from 'components/index.js';

export const OrderAdditionalFields = ({ control, isFormDisabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const role = user?.role;

  const partners = useSelector(selectUsers);

  const { t } = useTranslation();

  const handleCreateOrder = async () => {
    sessionStorage.removeItem('orderId');
    const order = await dispatch(asyncCreateOrder()).unwrap();

    sessionStorage.setItem('orderId', order._id);
    navigate(`/orders/${order._id}`);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex">
        <div className="flex-1">
          {role === 'partner-member' && (
            <Controller
              defaultValue={true}
              control={control}
              name="isSeal"
              render={({ field: { value, onChange } }) => (
                <Toggle
                  disable={isFormDisabled}
                  label={t('seal')}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          )}
        </div>
        <div className="flex flex-1 justify-end">
          <Button onClick={handleCreateOrder}>{t('create new order')}</Button>
        </div>
      </div>
      {partners && role === 'partner-member' && (
        <div>
          <Controller
            control={control}
            name="mediator"
            render={({ field: { onChange, value } }) => (
              <Select
                disable={isFormDisabled}
                name="mediator"
                isClearable
                wrapperClassname="w-1/3"
                options={partners?.map((partner) => ({
                  value: partner._id,
                  label: `${partner.firstName} ${partner.lastName}`
                }))}
                label={t('mediator')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
