import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getCommissionRoute } from 'utils';

import { asyncGetPopulatedOrder, selectOrder } from 'store';

import { Button } from 'components';

import LoadingSrc from 'svg/loading.svg';

const currency = 'CZK';
export const Paid = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const order = useSelector(selectOrder);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onFactureClick = () => {
    setIsLoading(true);
    let attempts = 0;
    const maxAttempts = 5;
    const interval = 2000;

    const attemptRequest = () => {
      dispatch(asyncGetPopulatedOrder(id))
        .unwrap()
        .then((response) => {
          if (response?.facture) {
            setIsLoading(false);
            const link = getCommissionRoute(response?.facture);
            navigate(link);
            return;
          }

          attempts += 1;
          if (attempts < maxAttempts) {
            setTimeout(attemptRequest, interval);
          } else {
            setIsLoading(false);
            toast.error(t('stripe - facture - failed'));
          }
        });
    };

    attemptRequest();
  };

  useEffect(() => {
    dispatch(asyncGetPopulatedOrder(id));
  }, []);

  if (!order) return null;
  return (
    <div className="flex flex-col gap-4 text-sm">
      <span className="flex flex-col gap-4">
        {t('stripe - thank you', {
          sum: order.prices.withDiscount + order.prices.comission,
          id: order.id
        })}
        <Button
          disabled={isLoading}
          className="self-start relative"
          onClick={onFactureClick}>
          {isLoading && (
            <div className="absolute inset-0 bg-green-1 flex items-center justify-center">
              <img src={LoadingSrc} className="w-6 h-6" alt={t('loading')} />
            </div>
          )}
          {t('go to commission')}
        </Button>
      </span>
      <div className="text-grey-1 text-md flex flex-row justify-between gap-4">
        <span>{t('full price:', { price: order.prices.full, currency })} </span>
        <span>
          {t('discount price:', { price: order.prices.withDiscount, currency })}
        </span>
        <span>
          {t('comission price:', { price: order.prices.comission, currency })}
        </span>
      </div>
    </div>
  );
};
