import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  createNewsReducer,
  deleteNewsReducer,
  getNewsItemReducer,
  getNewsReducer,
  unsetIsDeletedNewsReducer,
  unsetIsUpdatedNewsReducer,
  updateNewsReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.NEWS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getNewsReducer(builder);
    getNewsItemReducer(builder);
    createNewsReducer(builder);
    updateNewsReducer(builder);
    deleteNewsReducer(builder);
    unsetIsUpdatedNewsReducer(builder);
    unsetIsDeletedNewsReducer(builder);
  }
});

export const newsReducer = slice.reducer;
