import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AppRoutes,
  BANK_ADDRESS_PATTERN,
  ITN_PATTERN
} from 'constants/index.js';

import { maxLen, num } from 'normalizers';

import {
  asyncChangePassword,
  asyncGetSelf,
  asyncUpdateSelf,
  selectUser
} from 'store';

import { Button, Input } from 'components';

export const PartnerForm = () => {
  const [error] = useState('');
  const [message] = useState('');

  const [isShowPassword, setIsShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const { t, i18n } = useTranslation();
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isLoading, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const usr = {
        firstName: '',
        lastName: '',
        companyName: '',
        codeITN: '',
        address: '',
        bankAddress: '',
        newPassword: '',
        passwordRepeat: ''
      };

      const data = await dispatch(asyncGetSelf()).unwrap();

      if (data.user.lang) i18n.changeLanguage(data.user.lang);

      Object.keys(usr).forEach((key) => {
        if (data.user[key]) usr[key] = data.user[key];
      });

      return usr;
    }
  });

  const onGeneratePassword = () => {
    const password = 'xxxxxxxxxxxx'.replace(/[xy]/g, (symbol, res) =>
      ('x' === symbol
        ? (res = (Math.random() * 16) | 0)
        : (res & 0x3) | 0x8
      ).toString(16)
    );

    setValue('newPassword', password);
    setValue('passwordRepeat', password);
    setIsShowPassword(true);
  };

  const onShowPassword = () => {
    setIsShowPassword((prevState) => !prevState);
  };

  const onSubmit = (data) => {
    const { newPassword, passwordRepeat: _, ...restData } = data;

    dispatch(asyncUpdateSelf(restData));
    dispatch(asyncChangePassword({ newPassword, isFirstLogin: true }));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col gap-6">
      <div className="flex flex-row gap-16 justify-between">
        <Input
          autoComplete="off"
          required
          wrapperClassname="w-1/2"
          name="firstName"
          label={t('first name')}
          error={errors['firstName']?.message}
          {...register('firstName', { required: t('error - required') })}
        />
        <Input
          autoComplete="off"
          required
          wrapperClassname="w-1/2"
          name="lastName"
          label={t('last name')}
          error={errors['lastName']?.message}
          {...register('lastName', { required: t('error - required') })}
        />
      </div>
      <div className="flex flex-row gap-16 justify-between">
        <Input
          autoComplete="off"
          required
          wrapperClassname="w-1/2"
          name="companyName"
          label={t('organization name')}
          error={errors['companyName']?.message}
          {...register('companyName', { required: t('error - required') })}
        />
        <Input
          autoComplete="off"
          normalize={[num, maxLen(10)]}
          required
          wrapperClassname="w-1/2"
          name="codeITN"
          label={t('itn code')}
          error={errors['codeITN']?.message}
          {...register('codeITN', {
            required: t('error - required'),
            pattern: {
              value: ITN_PATTERN,
              message: t('error - invalid itn')
            }
          })}
        />
      </div>
      <div className="flex flex-row gap-16 justify-between">
        <Input
          autoComplete="off"
          required
          wrapperClassname="w-1/2"
          name="address"
          label={t('organization address')}
          error={errors['address']?.message}
          {...register('address', { required: t('error - required') })}
        />
        <Input
          autoComplete="off"
          wrapperClassname="w-1/2"
          required
          name="bankAddress"
          label={t('bank address')}
          error={errors['bankAddress']?.message}
          {...register('bankAddress', {
            required: t('error - required'),
            pattern: {
              value: BANK_ADDRESS_PATTERN,
              message: t('error - invalid bank data')
            }
          })}
        />
      </div>
      <div className="flex flex-row gap-16 justify-between">
        <Input
          autoComplete="off"
          wrapperClassname="w-1/2"
          error={errors['newPassword']?.message}
          type={isShowPassword ? 'text' : 'password'}
          label={t('new password')}
          required
          {...register('newPassword', {
            required: t('error - required'),
            minLength: {
              value: 12,
              message: t('error - password min length')
            }
          })}
        />
        <Input
          autoComplete="off"
          wrapperClassname="w-1/2"
          error={errors['passwordRepeat']?.message}
          type={isShowPassword ? 'text' : 'password'}
          label={t('repeat password')}
          required
          {...register('passwordRepeat', {
            validate: (value) =>
              value === watch('newPassword') || t('error - not same passwords'),
            required: t('error - required'),
            minLength: {
              value: 12,
              message: t('error - password min length')
            }
          })}
        />
      </div>
      <div className="flex gap-2 flex-wrap mt-4">
        <Button onClick={onGeneratePassword} type="button">
          {t('generate password')}
        </Button>
        <Button onClick={onShowPassword} type="button">
          {t(isShowPassword ? 'hide password' : 'show password')}
        </Button>
        <Button
          className="disabled:opacity-50"
          disabled={!isLoading && !isValid}>
          {t('save navigate to profile')}
        </Button>
      </div>
      {message && <span className="text-md text-green">{t(message)}</span>}
      {error && <span className="text-md text-error">{t(error)}</span>}
    </form>
  );
};
