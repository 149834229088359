import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getCountriesArr } from 'utils/index.js';

import { maxLen, num } from 'normalizers';

import {
  asyncGetGovUser,
  selectOrderGovUser,
  selectUser
} from 'store/index.js';

import { Input, Select } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

export const LegalEntity = ({ index, isFormDisabled }) => {
  const {
    register,
    control,
    reset,
    watch,
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation();

  const govUser = useSelector(selectOrderGovUser);

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (!user) return;
    setCountries(getCountriesArr(user.lang));
  }, [user]);

  useEffect(() => {
    if (govUser) {
      const isSeal = watch('isSeal');
      const mediator = watch('mediator');

      reset({
        isSeal,
        mediator,
        [`clients.${index}.insurer.isLegalEntity`]: true,
        [`clients.${index}.insurer.addressesMatch`]: true,
        [`clients.${index}.insured.addressesMatch`]: true,
        [`clients.${index}.insurer.czCity`]: govUser?.sidlo?.nazevObce,
        [`clients.${index}.insurer.czStreet`]: `${govUser?.sidlo?.nazevUlice} ${govUser?.sidlo?.cisloDomovni} / ${govUser?.sidlo?.cisloOrientacni}`,
        [`clients.${index}.insurer.czIndex`]: govUser?.sidlo?.psc,
        [`clients.${index}.insurer.companyName`]: govUser?.obchodniJmeno
      });
    }
  }, [govUser, reset]);

  return (
    <>
      <Input
        disabled={isFormDisabled}
        actionOnBlur={(e) => {
          const itnCode = e?.target?.value;
          dispatch(asyncGetGovUser(itnCode));
        }}
        normalize={[num, maxLen(10)]}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.insurer?.codeITN?.message
            : undefined
        }
        label={t('itn code') + '*'}
        {...register(`clients.${index}.insurer.codeITN`, {
          minLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          }
        })}
      />
      <Input
        disabled={isFormDisabled}
        label={t('email')}
        {...register(`clients.${index}.insurer.email`)}
      />
      <Input
        disabled={isFormDisabled}
        label={t('company name') + '*'}
        {...register(`clients.${index}.insurer.companyName`)}
      />
      <PhoneField
        disabled={isFormDisabled}
        control={control}
        name={`clients.${index}.insurer.phone`}
        label={t('phone')}
      />

      <Input
        disabled={isFormDisabled}
        normalize={[num, maxLen(10)]}
        label={t('czech index') + '*'}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.insurer?.czIndex?.message
            : undefined
        }
        {...register(`clients.${index}.insurer.czIndex`, {
          minLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          },
          maxLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          }
        })}
      />
      <Input
        disabled={isFormDisabled}
        label={t('czech street') + '*'}
        {...register(`clients.${index}.insurer.czStreet`)}
      />
      <Input
        disabled={isFormDisabled}
        label={t('czech city') + '*'}
        {...register(`clients.${index}.insurer.czCity`)}
      />
      <Controller
        name={`clients.${index}.insurer.czCountry`}
        control={control}
        defaultValue="Česká republika"
        render={({ field: { onChange, value } }) => (
          <Select
            disabled
            defaultValue="Česká republika"
            options={countries}
            label={t('country') + '*'}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
