import { NotFound } from 'pages/NotFound.jsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  PARTNER_PROTECTED_ROUTES,
  PROTECTED_ROUTES,
  UNPROTECTED_ROUTES
} from './routes';

import { AppRoutes } from 'constants/index.js';

import {
  asyncGetSelf,
  selectIsAuthorized,
  selectIsFirstLogin,
  selectUser,
  unsetIsAuthorized,
  unsetIsFirstLogin
} from 'store/index.js';

import { ProtectedRoute } from 'components/index.js';

import { Wrapper } from 'pages';

export const App = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isAuthorized = useSelector(selectIsAuthorized);
  const isFirstLogin = useSelector(selectIsFirstLogin);

  const user = useSelector(selectUser);
  const role = user?.role;

  const isPartner = role?.includes('partner');

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      dispatch(asyncGetSelf());
    } else {
      dispatch(unsetIsAuthorized());
      dispatch(unsetIsFirstLogin());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstLogin && isPartner) {
      navigate(AppRoutes.PARTNER_INFO);
    }
  }, [isFirstLogin, isPartner]);

  if (isAuthorized === null || isFirstLogin === null) return null;

  return (
    <Routes>
      <Route
        element={<ProtectedRoute isAuth={isAuthorized} to={AppRoutes.LOGIN} />}>
        <Route
          element={
            <ProtectedRoute
              isAuth={isFirstLogin && isPartner}
              to={AppRoutes.HOME}
            />
          }>
          {PARTNER_PROTECTED_ROUTES.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
        </Route>
        <Route
          element={
            <ProtectedRoute
              isAuth={!(isFirstLogin && isPartner)}
              to={AppRoutes.PARTNER_INFO}
            />
          }>
          {PROTECTED_ROUTES.map(
            ({ path, element: Element, roles }) =>
              (!roles || roles.includes(user?.role)) && (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Wrapper>
                      <Element />
                    </Wrapper>
                  }
                />
              )
          )}
        </Route>
      </Route>
      <Route
        element={<ProtectedRoute isAuth={!isAuthorized} to={AppRoutes.HOME} />}>
        {UNPROTECTED_ROUTES.map(({ path, element: Element }) => (
          <Route key={path} path={path} element={<Element />} />
        ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

// isAuthorized &&
// isFirstLogin &&
// role?.includes('partner')

// isAuthorized &&
// (!isFirstLogin || !role?.includes('partner')) &&
