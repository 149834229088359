import { ChangePasswordForm, ProfileForm } from 'modules';

export const Profile = () => {
  return (
    <div className="flex flex-col gap-4">
      <ProfileForm />
      <ChangePasswordForm />
    </div>
  );
};
