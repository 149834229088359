import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectUser, asyncGetOrderXML } from 'store/index.js';


import { AppRoutes } from 'constants/index.js';

import {
  asyncGetCompany,
  asyncGetOrder,
  asyncGetProduct,
  asyncGetSettings,
  asyncGetVoucher,
  asyncSendValidateClient,
  selectOrder,
  selectOrderIsSuccess
} from 'store';

import { Link, LinkVariants } from 'components';

import { downloadFile } from 'utils/index.js';

import { ViewInfo } from 'modules';

export const OrderClientInfo = () => {
  
  const user = useSelector(selectUser);

  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const orderId = searchParams.get('orderId');
  const clientId = searchParams.get('clientId');

  const adminPatch = searchParams.get('admin');
  const adminPatchPrice = searchParams.get('price');
  const adminPatchContractId = searchParams.get('contract_id');
  const adminPatchSigninDate = searchParams.get('signin_date');
  const adminPatchFromDate = searchParams.get('from_date');

  const getRightDate = (date) => {
    const [day, month, year] = date.split('.');
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();
    const newDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));
    const dateISO = newDate.toISOString();
    return dateISO;
  }
  console.log(adminPatch,
    adminPatchPrice,
    adminPatchContractId,
    adminPatchSigninDate,
    adminPatchFromDate)

  useEffect(() => {
    const fetchOrderXML = async () => {
      if (adminPatch === "patch" && user?.role === "admin" && adminPatchSigninDate && adminPatchContractId && adminPatchFromDate ) {
        try {
          setIsLoading(true);

          const sendStartDate = getRightDate(adminPatchSigninDate);
          const adminPatchFromDateISO = getRightDate(adminPatchFromDate);

          const adminPatchData = { 
            signinDate: sendStartDate,
            price: adminPatchPrice,
            contractId: adminPatchContractId,
            fromDate: adminPatchFromDateISO
          };
          const payload = { orderId, sendStartDate, adminPatchData };
          const data = await dispatch(asyncGetOrderXML(payload));
          await downloadFile({
            data: data.payload || data,
            fileName: `smlouva-${adminPatchContractId}.pdf`,
            fileType: 'application/pdf'
          });
          
          navigate(`/orders/client-info?orderId=${orderId}&clientId=${clientId}`);
          window.location.reload();
          
        } catch (error) {
          console.error("Failed to fetch order XML:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchOrderXML();
  }, []);

  const isSuccess = useSelector(selectOrderIsSuccess);
  const order = useSelector(selectOrder);

  const prices = order?.clients[clientId]?.prices;
  const client = order?.clients[clientId];
  const orderStatus = order?.status;

  const dispatch = useDispatch();

  const getOrder = () => {
    if (orderId !== 'undefined' && clientId !== 'undefined') {
      dispatch(asyncGetOrder(orderId))
        .unwrap()
        .then((order) => {
          const client = order?.clients[clientId];

          if (client?.insurance?.product) {
            const product = client?.insurance?.product;
            dispatch(
              asyncGetProduct(
                typeof product === 'object' ? product._id : product
              )
            );
          }

          if (client?.insurance?.company) {
            const company = client.insurance.company;
            dispatch(
              asyncGetCompany(
                typeof company === 'object' ? company._id : company
              )
            );
          }

          if (client?.insurance?.voucher) {
            const clientVoucher = client.insurance.voucher;
            dispatch(
              asyncGetVoucher(
                typeof clientVoucher === 'object'
                  ? clientVoucher._id
                  : clientVoucher
              )
            );
          }
          dispatch(asyncGetSettings());
          dispatch(asyncSendValidateClient({ orderId, clientIdx: clientId }));
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  if (isLoading) return null;
  if (isSuccess === undefined && !prices && !client)
    return (
      <div className="h-96 flex flex-col gap-4 items-center justify-center ">
        <p className="text-3xl text-center">{t('missing items')}</p>
        <Link href={AppRoutes.ORDERS} variant={LinkVariants.SECONDARY}>
          {t('go back orders')}
        </Link>
      </div>
    );
  return (
    <>
    {isLoading ? <p>Loading...</p> : null}
      {!isLoading && <ViewInfo
        orderStatus={orderStatus}
        prices={prices}
        client={client}
        clientIdx={clientId}
        orderId={orderId}
      />}
    </>
  );
};
