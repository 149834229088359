import { EMAIL_PATTERN } from 'constants/patterns.js';

import { validatePhone } from 'validators/index.js';

import i18n from 'i18n/index.js';

export const EMAIL_OPTIONS = {
  required: i18n.t('error - required'),
  pattern: {
    value: EMAIL_PATTERN,
    message: i18n.t('error - invalid email')
  }
};

export const PASSWORD_OPTIONS = {
  required: i18n.t('error - required'),
  minLength: {
    value: 12,
    message: i18n.t('error - password min length')
  }
};

export const PHONE_OPTIONS = {
  required: i18n.t('error - required'),
  pattern: validatePhone(i18n.t)
};
