import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { asyncGetReports } from 'store';

import { useQueryParams } from 'hooks';

import { Select, Table } from 'components';

import { ReportsBarChart, ReportsPieChart } from 'modules';

export const Reports = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [companiesData, setCompaniesData] = useState([]);

  const queryFilter = searchParams.get('date');

  const [selectedFilter, setSelectedFilter] = useState(queryFilter ?? 'year');

  const [selectedView, setSelectedView] = useState(
    searchParams.get('type') ?? 'byCompany'
  );
  const [selectedViewText, setSelectedViewText] = useState(
    t('reports - view - by company')
  );

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const currentDate = moment();

  const dispatch = useDispatch();

  const customDate = useCallback(() => {
    const dates = [moment(startDate).unix(), moment(endDate).unix()];
    return dates.includes(NaN) ? [null, null] : dates;
  }, [endDate]);

  const getUnixStringDateRange = (type) => {
    return [currentDate.startOf(type).unix(), currentDate.endOf(type).unix()];
  };

  const formatDateRange = (type) => {
    if (type === 'custom') {
      return customDate();
    }
    return getUnixStringDateRange(type);
  };

  const filterOptions = [
    {
      label: t('reports - date - today'),
      value: 'day'
    },
    {
      label: t('reports - date - week'),
      value: 'week'
    },
    {
      label: t('reports - date - month'),
      value: 'month'
    },
    {
      label: t('reports - date - year'),
      value: 'year'
    },
    {
      label: t('reports - date - range'),
      value: 'custom'
    }
  ];

  const viewOptions = [
    {
      label: t('reports - view - by company'),
      value: 'byCompany'
    },
    {
      label: t('reports - view - within company'),
      value: 'withinCompany'
    },
    {
      label: t('reports - view - share by company'),
      value: 'shareByCompany'
    },
    {
      label: t('reports - view - share within company'),
      value: 'shareWithinCompany'
    },
    {
      label: t('reports - view - share of money'),
      value: 'shareOfMoney'
    },
    {
      label: t('reports - view - share by money'),
      value: 'shareByMoney'
    },
    {
      label: t('reports - view - dynamics orders'),
      value: 'dynamicsOrders'
    },
    {
      label: t('reports - view - dynamics contracts'),
      value: 'dynamicsContracts'
    },
    {
      label: t('reports - view - dynamics money'),
      value: 'dynamicsMoney'
    },
    {
      label: t('reports - view - dynamics commission'),
      value: 'dynamicsCommission'
    }
  ];

  const cfg = [
    {
      key: `comission`,
      header: t('comission')
    },
    {
      valueFunc: (el) => moment(el).format('DD.MM.YYYY'),
      key: `creationDate`,
      header: t('creation date')
    },
    {
      key: `product`,
      header: t('product')
    },
    {
      valueFunc: (_, el) => `${el.firstName} ${el.lastName}`,
      key: `name`,
      header: t('name surname')
    },
    {
      key: `full`,
      header: t('price'),
      valueFunc: (el) => Math.round(isNaN(+el) ? 0 : +el)
    }
  ];

  const getReports = async () => {
    const [startData, endDate] = formatDateRange(selectedFilter);

    const reports = await dispatch(
      asyncGetReports({
        start: startData,
        end: endDate
      })
    ).unwrap();

    setCompanies(Object.keys(reports));

    const data = Object.values(reports);
    setCompaniesData(data);

    setProducts((prevProducts) => {
      data.forEach((dataItem) => {
        dataItem.forEach((orderData) => {
          if (!prevProducts.includes(orderData.product)) {
            prevProducts.push(orderData.product);
          }
        });
      });
      return prevProducts;
    });
  };

  const handleOnFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handleViewModeChange = (e) => {
    setSelectedView(e.target.value);
    setSelectedViewText(e.target.label);
  };

  const handleDateRangeChange = (e) => {
    setDateRange(e);
  };

  useEffect(() => {
    getReports();
  }, [selectedView, selectedFilter, endDate]);

  useQueryParams({
    type: selectedView,
    date: selectedFilter
  });

  if (!companies || !products) return null;
  return (
    <div className="flex flex-col gap-4">
      <div>
        <p>{selectedViewText}</p>
      </div>
      <div className="grid grid-cols-4 grid-rows-reports justify-between gap-2">
        <Select
          defaultValue={
            filterOptions?.find(({ value }) => value === queryFilter) ??
            filterOptions[3]
          }
          onChange={handleOnFilterChange}
          options={filterOptions}
          wrapperClassname="flex-1 col-start-1"
        />
        {selectedFilter === 'custom' && (
          <DatePicker
            showMonthDropdown
            isClearable
            showYearDropdown
            scrollableMonthYearDropdown
            scrollableYearDropdown
            maxDate={new Date()}
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateRangeChange}
            className="rounded border-grey-6 py-1 px-2 text-sm w-full col-start-2"
          />
        )}
        <Select
          defaultValue={
            viewOptions?.length
              ? viewOptions.find(
                  (view) => view.value === searchParams.get('type')
                )
              : viewOptions[0]
          }
          onChange={handleViewModeChange}
          options={viewOptions}
          wrapperClassname="flex-1 col-start-4"
        />
        {selectedView.includes('share') && companiesData && (
          <ReportsPieChart
            selectedView={selectedView}
            companyNames={companies}
            companiesData={companiesData}
          />
        )}
      </div>
      {(selectedView === 'byCompany' || selectedView === 'withinCompany') &&
        (companies?.length > 0 ? (
          companies.map((company, index) => (
            <div className="flex flex-col gap-2" key={index}>
              {companiesData[index]?.length > 0 && <span>{company}</span>}
              {companiesData && selectedView === 'withinCompany' ? (
                products.map((product, productIndex) => {
                  return (
                    <Fragment key={productIndex}>
                      {companiesData[index].some(
                        (companyData) => companyData.product === product
                      ) && <span className="ml-2">{product}</span>}
                      <Table
                        missingText=""
                        items={companiesData[index].filter(
                          (companyData) => companyData.product === product
                        )}
                        config={cfg}
                      />
                    </Fragment>
                  );
                })
              ) : (
                <Table items={companiesData[index]} config={cfg} />
              )}
            </div>
          ))
        ) : (
          <p className="col-span-4 flex items-center justify-center">
            {t('missing items')}
          </p>
        ))}
      {selectedView.includes('dynamics') && companiesData && (
        <ReportsBarChart
          selectedView={selectedView}
          companyNames={companies}
          companiesData={companiesData}
          selectedFilter={selectedFilter}
          dateRange={dateRange}
        />
      )}
    </div>
  );
};
