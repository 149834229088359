import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { authApi } from 'api';

import i18n from 'i18n';

import { setSelf } from 'store/users/actions.js';

export const asyncSendLogin = createAsyncThunk(
  `${SliceNames.AUTH}/SEND_LOGIN`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authApi.sendLogin(payload);
      dispatch(setSelf(data));
      localStorage.setItem('accessToken', data?.accessToken);

      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncCheckRecoveryToken = createAsyncThunk(
  `${SliceNames.AUTH}/CHECK_RECOVERY_TOKEN`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authApi.sendCheckRecoveryToken(payload);
      return data?.isCorrect;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncBecomePartner = createAsyncThunk(
  `${SliceNames.AUTH}/BECOME_PARTNER`,
  async (payload, { rejectWithValue }) => {
    try {
      await authApi.sendBecomePartner(payload);
      toast.success(i18n.t('email sent'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncResetPassword = createAsyncThunk(
  `${SliceNames.AUTH}/RESET_PASSWORD`,
  async (payload, { rejectWithValue }) => {
    try {
      await authApi.sendResetPassword(payload);
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncForgotPassword = createAsyncThunk(
  `${SliceNames.AUTH}/FORGOT_PASSWORD`,
  async (payload, { rejectWithValue }) => {
    try {
      await authApi.sendForgotPassword(payload);
      toast.success(i18n.t('email sent'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);
