import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children, wrapperId }) => {
  const [wrapper, setWrapper] = useState(null);

  useEffect(() => {
    const wrapper = document.getElementById(wrapperId);

    setWrapper(wrapper);
  }, [wrapperId]);

  if (!wrapper) return null;
  return createPortal(children, wrapper);
};
