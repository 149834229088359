import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { asyncGetPartners, asyncUpdateSettings } from 'store';

import { useModal } from 'hooks/index.js';

import { Button, Toggle } from 'components';

import { YesNoModal } from 'modules/ModalTemplates/YesNoModal.jsx';

export const PaymentSettingsForm = ({ settings }) => {
  const { isModalOpened, handleOnCloseModal, handleOnOpenModal } = useModal();

  const [activeMethods, setActiveMethods] = useState([]);
  const [method, setMethod] = useState();

  const dispatch = useDispatch();

  const {
    setValue,
    control,
    handleSubmit,
    formState: { isLoading, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const settingsData = {
        stripe: false,
        bank: false,
        office: false,
        deposit: false
      };

      if (settings) {
        Object.keys(settingsData).forEach((key) => {
          if (settings[key]) settingsData[key] = settings[key];
        });
      }

      return settingsData;
    }
  });
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    dispatch(asyncUpdateSettings(data));
  };

  const handleOpenModal = (name) => {
    setMethod(name);
    handleOnOpenModal();
  };

  const handleApprove = () => {
    setValue(method, false);
    handleOnCloseModal();
  };

  const handleDecline = () => {
    setValue(method, true);
    handleOnCloseModal();
  };

  const getPartners = () => {
    dispatch(asyncGetPartners())
      .unwrap()
      .then((response) => {
        const uniqueMethods = new Set(
          response?.list.flatMap((partner) =>
            partner.allowedPaymentMethods.map((method) => method)
          )
        );

        setActiveMethods(Array.from(uniqueMethods));
      });
  };

  useEffect(() => {
    getPartners();
  }, [dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <span className="text-md">{t('payment methods')}:</span>
      <div className="flex flex-col gap-4 my-4">
        <Controller
          defaultValue=""
          control={control}
          name="stripe"
          render={({ field: { value, onChange } }) => (
            <Toggle
              label={t('online')}
              checked={value}
              onChange={(e) => {
                if (activeMethods.includes('stripe') && value === true) {
                  handleOpenModal('stripe');
                }

                onChange(e);
              }}
            />
          )}
        />
        <Controller
          defaultValue=""
          control={control}
          name="bank"
          render={({ field: { value, onChange } }) => (
            <Toggle
              label={t('bank')}
              checked={value}
              onChange={(e) => {
                if (activeMethods.includes('bank') && value === true) {
                  handleOpenModal('bank');
                }

                onChange(e);
              }}
            />
          )}
        />
        <Controller
          defaultValue=""
          control={control}
          name="office"
          render={({ field: { value, onChange } }) => (
            <Toggle
              label={t('office')}
              checked={value}
              onChange={(e) => {
                if (activeMethods.includes('office') && value === true) {
                  handleOpenModal('office');
                }

                onChange(e);
              }}
            />
          )}
        />
        <Controller
          defaultValue=""
          control={control}
          name="deposit"
          render={({ field: { value, onChange } }) => (
            <Toggle
              label={t('deposit')}
              checked={value}
              onChange={(e) => {
                if (activeMethods.includes('deposit') && value === true) {
                  handleOpenModal('deposit');
                }

                onChange(e);
              }}
            />
          )}
        />
      </div>
      <div>
        <Button type="submit" disabled={!isLoading && !isValid}>
          {t('save')}
        </Button>
      </div>
      {isModalOpened && (
        <YesNoModal
          question={t('modal template - yes-no - settings - question')}
          approveLabel={t('yes')}
          declineLabel={t('no')}
          handleClose={handleDecline}
          isOpen={isModalOpened}
          onApprove={handleApprove}
          onDecline={handleDecline}
        />
      )}
    </form>
  );
};
