import cs from 'classnames';
import { forwardRef } from 'react';

export const Radio = forwardRef(
  (
    {
      defaultValue,
      options,
      name,
      label,
      required,
      disabled = false,
      hideError = false,
      error,
      wrapperClassname,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
          {label}
          {required && '*'}
        </label>
        <div className="flex flex-row gap-4">
          {options.map(({ value, label }, idx) => (
            <div
              key={value}
              className="flex flex-row items-center gap-2 cursor-pointer h-[30px]">
              <input
                id={`${idx}-${name}`}
                type="radio"
                ref={ref}
                defaultChecked={value === defaultValue}
                className={cs(
                  'display-none',
                  'text-sm border-2 border-grey-6 rounded-full cursor-pointer text-bold',
                  'checked:bg-green-1 checked:hover:bg-green-1 checked:focus:bg-green-1 checked:bg-none',
                  'focus:bg-green-1 focus:outline-none focus:ring-0',
                  'active:bg-green-1',
                  'transition duration-500'
                )}
                disabled={disabled}
                name={name}
                value={value}
                {...props}
              />
              <label htmlFor={`${idx}-${name}`} className="cursor-pointer">
                {label}
              </label>
            </div>
          ))}
        </div>
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

Radio.displayName = 'Radio';
