import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = (queries) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryDependency = JSON.stringify(queries);

  useEffect(() => {
    Object.keys(queries).forEach((queryKey) => {
      if (queries[queryKey]) {
        searchParams.set(queryKey, `${queries[queryKey]}`);
      } else {
        searchParams.delete(queryKey);
      }
    });

    setSearchParams(searchParams);
  }, [queryDependency]);
};
