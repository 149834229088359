export const AppRoutes = {
  HOME: '/',
  ORDERS: '/orders',
  ORDER_CREATE: '/orders/create',
  ORDER: '/orders/:id',
  ORDER_CLIENT: '/orders/client-info',
  ORDER_PAYMENT: '/orders/payment/:id',
  ORDER_PROCESS: '/orders/payment-process/:id',
  ORDER_PAID: '/orders/payment-finished/:id',
  ORDER_PAYMENT_FAILED: '/orders/payment-failed/:id',
  NEWS: '/news',
  NEWS_CREATE: '/news/create',
  NEWS_EDIT: '/news/edit/:id',
  NEWS_ITEM: '/news/:id',
  PROFILE: '/profile',
  VOUCHERS: '/vouchers',
  VOUCHER_CREATE: '/vouchers/create',
  VOUCHER: '/vouchers/:id',
  COMMISSIONS: '/comissions',
  COMMISSION: '/comissions/:id',
  REPORTS: '/reports',
  CLIENTS: '/clients',
  PARTNERS: '/partners',
  PARTNER_CREATE: '/partners/create',
  PARTNER: '/partners/:id',
  COMPANIES: '/companies',
  COMPANY_CREATE: '/companies/create',
  COMPANY: '/companies/:id',
  PRODUCTS: '/products',
  PRODUCT_CREATE: '/products/create',
  PRODUCT: '/products/:id',
  CASHIERS: '/cashiers',
  CASHIER: '/cashiers/:id',
  CASHIER_CREATE: '/cashiers/create',
  SALES_MANAGERS: '/sales-managers',
  SALES_MANAGER: '/sales-managers/:id',
  SALES_MANAGERS_CREATE: '/sales-managers/create',
  SETTINGS: '/settings',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PARTNER_REGISTER: '/partner-register',
  PRIVACY_POLICY: '/privacy-policy',
  PARTNER_INFO: '/partner-info',
  PAYMENT_QR: '/payment-qr/:id',
  LOGS: '/logs',
  LOG: '/logs/:id',
  STRIPE_SUCCESSFUL: '/stripe-payment'
};
