export const filterProductsByAge = (products, age) => {
  const filteredProducts = [];

  products?.forEach((product) => {
    const filteredTypes = [];

    product?.types?.forEach((type) => {
      const ageStart = parseInt(type?.ageStart, 10);
      const ageEnd = parseInt(type?.ageEnd, 10);

      if (age >= ageStart && age <= ageEnd) {
        filteredTypes.push(type);
      }
    });

    if (filteredTypes?.length > 0) {
      filteredProducts?.push({
        ...product,
        types: filteredTypes
      });
    }
  });

  return filteredProducts;
};
