import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUser } from 'store/index.js';

import { useExpiresSoon } from 'hooks';

import { Table } from 'components';

export const DropDownList = ({ list, passport }) => {
  const user = useSelector(selectUser);

  const expires = useExpiresSoon();
  const { t } = useTranslation();

  const isExpiredSoon = (el) => {
    if (expires) {
      const today = moment();
      const expirationDate = moment(el.end);

      const expireSoon = expirationDate.diff(today, 'milliseconds');

      switch (user?.expiresSoon) {
        case '7 days': {
          const sevenDaysInMillis = moment.duration(7, 'days').valueOf();
          return expireSoon <= sevenDaysInMillis && expireSoon > 0;
        }
        case '1 month': {
          const oneMonthInMillis = moment.duration(1, 'months').valueOf();
          return expireSoon <= oneMonthInMillis && expireSoon > 0;
        }
        case '3 months': {
          const threeMonthsInMillis = moment.duration(3, 'months').valueOf();
          return expireSoon <= threeMonthsInMillis && expireSoon > 0;
        }
      }
    }
  };

  const isExpired = (el) => {
    if (expires) {
      const expirationDate = moment(el.end);
      return expirationDate.diff(moment(), 'milliseconds') < 0;
    }
  };

  const cfg = [
    {
      valueFunc: (obj, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {obj}
        </span>
      ),
      key: `contractId`,
      header: t('contract')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {el?.company?.organization}
        </span>
      ),
      key: `company`,
      header: t('insurance')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {el?.product?.name}
        </span>
      ),
      key: `product`,
      header: t('product')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {el?.type}
        </span>
      ),
      key: `type`,
      header: t('insurance type')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {el?.term}
        </span>
      ),
      key: `term`,
      header: t('term')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el)
          })}
        >
          {`${el?.withDiscount} CZK`}
        </span>
      ),
      key: `price`,
      header: t('price')
    },
    {
      valueFunc: (_, el) => (
        <span
          className={cn({
            'line-through': isExpired(el),
            'text-error': isExpiredSoon(el)
          })}
        >
          {moment(el.start)
            .add(el.term, 'months')
            .subtract(1, 'day')
            .format('DD.MM.YYYY')}
        </span>
      ),
      key: `insuranceEndDate`,
      header: t('expiring date')
    }
  ];

  return <Table config={cfg} items={list} className="bg-grey-4" />;
};
