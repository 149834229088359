import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCountriesArr } from 'utils';

import { maxLen, num } from 'normalizers';

import { selectUser } from 'store/index.js';

import { Datepicker, Input, Radio, Select } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

export const NonLegalEntity = ({ index, isFormDisabled }) => {
  const {
    watch,
    register,
    control,
    setValue,
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [countries, setCountries] = useState([]);

  const watchIsSamePersons = watch(`clients.${index}.isSamePersons`);

  useEffect(() => {
    if (!user) return;

    setCountries(getCountriesArr(user.lang));
  }, [user]);

  return (
    <>
      <Input
        disabled={isFormDisabled}
        label={t('first name') + '*'}
        {...register(`clients.${index}.insurer.firstName`)}
      />
      <Input
        disabled={isFormDisabled}
        label={t('last name') + '*'}
        {...register(`clients.${index}.insurer.lastName`)}
      />
      <Input
        disabled={isFormDisabled}
        label={t('email')}
        {...register(`clients.${index}.insurer.email`)}
      />
      <Controller
        name={`clients.${index}.insurer.birthday`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            disabled={isFormDisabled}
            maxDate={new Date()}
            name={`clients.${index}.insurer.birthday`}
            label={t('birthday') + '*'}
            error={errors[`clients.${index}.insurer.birthday`]?.message}
            onChange={(date) => {
              if (date) {
                date.setHours((-1 * date.getTimezoneOffset()) / 60);
              }

              setValue(`clients.${index}.insurance.term`, null);
              setValue(`clients.${index}.insurance.type`, null);

              onChange(date);
            }}
            value={value ? moment(value).toDate() : null}
          />
        )}
      />
      <Radio
        disabled={isFormDisabled}
        options={[
          { value: 'male', label: t('male') },
          { value: 'female', label: t('female') }
        ]}
        name={`clients.${index}.insurer.gender`}
        label={t('gender') + '*'}
        error={errors[`clients.${index}.insurer.gender`]?.message}
        {...register(`clients.${index}.insurer.gender`)}
      />
      <Controller
        name={`clients.${index}.insurer.citizenship`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={isFormDisabled}
            name={`clients.${index}.insurer.citizenship`}
            options={countries}
            label={t('citizenship') + '*'}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <PhoneField
        disabled={isFormDisabled}
        control={control}
        name={`clients.${index}.insurer.phone`}
        label={t('phone')}
      />
      <Input
        disabled={isFormDisabled}
        normalize={[num, maxLen(10)]}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.insurer?.czIndex?.message
            : undefined
        }
        label={t('czech index') + '*'}
        {...register(`clients.${index}.insurer.czIndex`, {
          minLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          },
          maxLength: {
            value: 5,
            message: t('indexLength', { max: 5 })
          }
        })}
      />
      <Input
        disabled={isFormDisabled}
        label={t('czech street') + '*'}
        {...register(`clients.${index}.insurer.czStreet`)}
      />
      <Input
        disabled={isFormDisabled}
        label={t('czech city') + '*'}
        {...register(`clients.${index}.insurer.czCity`)}
      />
      <Input
        disabled={isFormDisabled}
        required={watchIsSamePersons}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.insurer?.passport?.message
            : undefined
        }
        label={t('passport')}
        {...register(`clients.${index}.insurer.passport`, {
          maxLength: {
            value: 9,
            message: t('indexLengthMax', { max: 9 })
          }
        })}
      />
      <Controller
        name={`clients.${index}.insurer.czCountry`}
        control={control}
        defaultValue="Česká republika"
        render={({ field: { onChange, value } }) => (
          <Select
            disabled
            defaultValue="Česká republika"
            options={countries}
            label={t('country') + '*'}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input
        disabled={isFormDisabled}
        required={watchIsSamePersons}
        error={
          errors?.clients?.length
            ? errors?.clients[index]?.insurer?.pid?.message
            : undefined
        }
        label={t('pid')}
        {...register(`clients.${index}.insurer.pid`, {
          maxLength: {
            value: 20,
            message: t('indexLengthMax', { max: 20 })
          }
        })}
      />
      <Controller
        name={`clients.${index}.insurer.birthPlace`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            disabled={isFormDisabled}
            required={watchIsSamePersons}
            options={countries}
            label={t('birth - place')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        name={`clients.${index}.insurer.passportFrom`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            disabled={isFormDisabled}
            maxDate={new Date()}
            name={`clients.${index}.insurer.passportFrom`}
            label={t('passport - from') + (watchIsSamePersons ? '*' : '')}
            error={errors[`clients.${index}.insurer.passportFrom`]?.message}
            onChange={(date) => {
              if (date) {
                date.setHours((-1 * date.getTimezoneOffset()) / 60);
              }

              onChange(date);
            }}
            value={value ? moment(value).toDate() : null}
          />
        )}
      />
      <Controller
        name={`clients.${index}.insurer.passportTo`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Datepicker
            disabled={isFormDisabled}
            minDate={new Date()}
            name={`clients.${index}.insurer.passportTo`}
            label={t('passport - to') + (watchIsSamePersons ? '*' : '')}
            error={errors[`clients.${index}.insurer.passportTo`]?.message}
            onChange={(date) => {
              if (date) {
                date.setHours((-1 * date.getTimezoneOffset()) / 60);
              }

              onChange(date);
            }}
            value={value ? moment(value).toDate() : null}
          />
        )}
      />
    </>
  );
};
