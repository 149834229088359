import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { logsReducer } from 'store/loggs/slice.js';

import { authReducer } from './auth/slice.js';
import { clientsReducer } from './clients/slice.js';
import { commissionsReducer } from './commissions/slice.js';
import { companiesReducer } from './companies/slice.js';
import { newsReducer } from './news/slice.js';
import { ordersReducer } from './orders/slice.js';
import { partnersReducer } from './partners/slice.js';
import { productsReducer } from './products/slice.js';
import { reportsReducer } from './reports/slice.js';
import { settingsReducer } from './settings/slice.js';
import { usersReducer } from './users/slice.js';
import { vouchersReducer } from './vouchers/slice.js';

const rootReducer = combineReducers({
  auth: authReducer,
  partners: partnersReducer,
  vouchers: vouchersReducer,
  users: usersReducer,
  settings: settingsReducer,
  orders: ordersReducer,
  products: productsReducer,
  news: newsReducer,
  companies: companiesReducer,
  commissions: commissionsReducer,
  clients: clientsReducer,
  reports: reportsReducer,
  logs: logsReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
