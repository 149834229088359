import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SliceNames } from 'constants/index.js';

import { productsApi } from 'api';

import i18n from 'i18n';

export const asyncGetProducts = createAsyncThunk(
  `${SliceNames.PRODUCTS}/GET_PRODUCTS`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.getProducts(payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncGetProduct = createAsyncThunk(
  `${SliceNames.PRODUCTS}/GET_PRODUCT`,
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.getProduct(payload);
      return data.product;
    } catch (error) {
      toast.error(i18n.t(error.key ?? 'error'));
      return rejectWithValue(error);
    }
  }
);

export const asyncCreateProduct = createAsyncThunk(
  `${SliceNames.PRODUCTS}/CREATE_PRODUCT`,
  async (payload, { rejectWithValue }) => {
    try {
      await productsApi.createProduct(payload);
      toast.success(i18n.t('created successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateProduct = createAsyncThunk(
  `${SliceNames.PRODUCTS}/UPDATE_PRODUCT`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      await productsApi.updateProduct(id, payload);
      toast.success(i18n.t('updated successfully'));
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncDeleteProduct = createAsyncThunk(
  `${SliceNames.PRODUCTS}/DELETE_PRODUCT`,
  async (payload, { rejectWithValue }) => {
    try {
      await productsApi.deleteProduct(payload);
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const asyncImportFromCsv = createAsyncThunk(
  `${SliceNames.PRODUCTS}/IMPORT_FROM_CSV`,
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const { data } = await productsApi.importFromCsv(id, payload);
      return data;
    } catch (error) {
      toast.error(i18n.t(`form error - ${error.key}`));
      return rejectWithValue(error);
    }
  }
);

export const unsetIsUpdatedProductAction = createAction(
  `${SliceNames.PRODUCTS}/UNSET_IS_UPDATED_PRODUCT`
);

export const unsetIsDeletedProductAction = createAction(
  `${SliceNames.PRODUCTS}/UNSET_IS_DELETED_PRODUCT`
);
