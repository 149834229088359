import { useTranslation } from 'react-i18next';

import { getPartnerRoute } from 'utils';

import { Link, Table } from 'components';

import EditSrc from 'svg/edit.svg';

export const PartnersList = ({ list, getCustomRoute, isLoading }) => {
  const { t } = useTranslation();

  const Actions = ({ _id: id }) => (
    <Link href={getCustomRoute ? getCustomRoute(id) : getPartnerRoute(id)}>
      <img src={EditSrc} alt="edit" />
    </Link>
  );

  const cfg = [
    {
      valueFunc: (_, el) => `${el.firstName} ${el.lastName}`,
      key: `name`,
      header: t('name')
    },
    {
      key: `email`,
      header: t('email')
    },
    {
      key: `phone`,
      header: t('phone')
    },
    {
      key: `companyName`,
      header: t('organization')
    },
    {
      header: t('actions'),
      component: Actions,
      propsKeys: ['_id']
    }
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
