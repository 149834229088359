import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { getOrderPaymentRoute } from 'utils/index.js';

import { Link, LinkVariants } from 'components/index.js';

import AddSrc from 'svg/add.svg';

export const OrderActions = ({
  orderId,
  onCreateUser,
  axaError,
  isFormDisabled
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('flex flex-row-reverse justify-between', {
        'pointer-events-none': isFormDisabled
      })}>
      <img
        onClick={onCreateUser}
        src={AddSrc}
        alt="Create User"
        className="self-end h-8 w-8 cursor-pointer"
      />
      {!axaError && (
        <Link
          href={getOrderPaymentRoute(orderId)}
          variant={LinkVariants.SECONDARY}>
          {t('to payment')}
        </Link>
      )}
    </div>
  );
};
