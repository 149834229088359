import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'react-international-phone';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { PHONE_OPTIONS } from 'constants/index.js';
import { validatePhoneNumber } from 'constants/phone-field.js';

import { maxLen, num, phone } from 'normalizers';

import { validateEmail } from 'validators';

import {
  asyncCreatePartner,
  asyncGetPartner,
  asyncGetPaymentTypes,
  asyncGetSettings,
  asyncUpdatePartner,
  selectPartner,
  selectPaymentTypes
} from 'store';

import { Button, Input, Select, TextArea, Toggle } from 'components';
import { PhoneField } from 'components/PhoneField.jsx';

import { PartnerPayment } from 'modules/Partners/PartnerPayment.jsx';

export const PartnerInfoForm = ({ id }) => {
  const dispatch = useDispatch();

  const partner = useSelector(selectPartner);
  const settings = useSelector(selectPaymentTypes);

  const [negotiationOptions, setNegotiationOptions] = useState();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: async () => {
      const partner = {
        numberOfPartnership: '',
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        codeITN: '',
        address: '',
        bankAddress: '',
        phone: '',
        role: '',
        apiAuthKey: '',
        comment: '',
        negotiatorNumber: '',
        stripe: '',
        bank: '',
        office: '',
        deposit: '',
        allowedPaymentMethods: [],
        isTrustedPartner: false
      };

      const paymentTypes = await dispatch(asyncGetPaymentTypes()).unwrap();

      if (id) {
        const data = await dispatch(asyncGetPartner(id)).unwrap();

        paymentTypes.forEach((key) => {
          if (!paymentTypes[key]) return;

          partner[key] = paymentTypes[key];
        });
        Object.keys(partner).forEach((key) => {
          if (data[key]) partner[key] = data[key];

          if (data[key] && key === 'allowedPaymentMethods') {
            data[key].forEach((item) => (partner[item] = true));
          }
        });
      }

      return partner;
    }
  });

  const { t } = useTranslation();

  const onSubmit = (data) => {
    const { allowedPaymentMethods: _, ...rest } = data;

    const payments = {
      stripe: data.stripe,
      bank: data.bank,
      office: data.office,
      deposit: data.deposit
    };

    const allowedPaymentMethods = Object.keys(payments).filter(
      (key) => payments[key] === true
    );

    dispatch(
      id
        ? asyncUpdatePartner({ id, data: { allowedPaymentMethods, ...rest } })
        : asyncCreatePartner({ allowedPaymentMethods, ...rest })
    );
  };

  const handleGenerateApiKey = (e) => {
    const key = 'xxxxxxxxxxxx'.replace(/[xy]/g, (symbol, res) =>
      ('x' === symbol
        ? (res = (Math.random() * 16) | 0)
        : (res & 0x3) | 0x8
      ).toString(16)
    );

    setValue('apiAuthKey', key);
  };

  const handleChangeStatus = async () => {
    await dispatch(
      asyncUpdatePartner({
        id,
        data: { status: partner.status === 'active' ? 'stopped' : 'active' }
      })
    );
    await dispatch(asyncGetPartner(id));

    toast.success(
      t(
        partner.status === 'active'
          ? 'partner freezed successfully'
          : 'partner activated successfully'
      )
    );
  };

  useEffect(() => {
    dispatch(asyncGetSettings())
      .unwrap()
      .then((settings) =>
        setNegotiationOptions(
          settings.allowedNegotiationNumbers.map((num) => ({
            value: num,
            label: num
          }))
        )
      );
  }, [dispatch]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-2 gap-y-4 gap-x-16">
      <Input
        required
        normalize={[num, maxLen(9)]}
        error={errors['numberOfPartnership']?.message}
        label={t('contract number')}
        {...register('numberOfPartnership', {
          required: t('error - required'),
          minLength: {
            value: 8,
            message: t('error - min length', { value: 8 })
          }
        })}
      />
      <Input
        required
        error={errors['email']?.message}
        label={t('email')}
        {...register('email', {
          required: t('error - required'),
          pattern: validateEmail(t)
        })}
      />
      <Input
        error={errors['firstName']?.message}
        label={t('first name')}
        {...register('firstName')}
      />
      <Input
        error={errors['lastName']?.message}
        label={t('last name')}
        {...register('lastName')}
      />
      <Input
        error={errors['companyName']?.message}
        label={t('company name')}
        {...register('companyName')}
      />
      <Input
        normalize={[num, maxLen(10)]}
        error={errors['codeITN']?.message}
        label={t('itn code')}
        {...register('codeITN')}
      />
      <Input
        error={errors['address']?.message}
        label={t('address')}
        {...register('address')}
      />
      <Input
        error={errors['bankAddress']?.message}
        label={t('bank address')}
        {...register('bankAddress')}
      />
      <PhoneField
        control={control}
        name="phone"
        label={t('phone')}
        required
        validation={{
          required: t('error - required'),
          validate: validatePhoneNumber
        }}
      />
      <Controller
        control={control}
        name="role"
        rules={{ required: t('error - required') }}
        render={({ field: { onChange, value } }) => (
          <Select
            name="role"
            options={[
              { value: 'partner-member', label: t('partner member') },
              { value: 'partner-agent', label: t('partner agent') }
            ]}
            required
            error={errors['role']?.message}
            label={t('partner type')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Input label={t('api key')} {...register('apiAuthKey')} />
      <div className="flex items-end">
        <Button type="button" onClick={handleGenerateApiKey}>
          {t('generate api key')}
        </Button>
      </div>
      {negotiationOptions?.length > 0 && (
        <Controller
          name="negotiatorNumber"
          control={control}
          rules={{ required: t('error - required') }}
          render={({ field: { onChange, value } }) => (
            <Select
              options={negotiationOptions}
              required
              label={t('negotiator-number')}
              error={errors['negotiatorNumber']?.message}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      <Controller
        defaultValue=""
        control={control}
        name="isTrustedPartner"
        render={({ field: { value, onChange } }) => (
          <Toggle
            label={t('trusted-partner')}
            name="isTrustedPartner"
            checked={value}
            onChange={onChange}
          />
        )}
      />
      <PartnerPayment settings={settings} control={control} />
      <TextArea
        wrapperClassname="col-span-full"
        rows={8}
        name="comment"
        label={t('comment')}
        error={errors['comment']?.message}
        {...register('comment')}
      />
      <div className="flex flex-row gap-4">
        <Button>{t('save')}</Button>
        {id && (
          <Button type="button" onClick={handleChangeStatus}>
            {t(partner?.status === 'active' ? 'stop' : 'activate')}
          </Button>
        )}
      </div>
    </form>
  );
};
