export const LogsInfo = ({ log }) => {
  return (
    <div className="flex flex-col">
      <span>Payload</span>
      <div className="border-2 rounded-lg p-4 overflow-x-scroll">
        <pre>{JSON.stringify(log?.payload, undefined, 2)}</pre>
      </div>
    </div>
  );
};
