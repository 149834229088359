import { useParams } from 'react-router-dom';

import { NewsCreateForm } from 'modules';

export const NewsCreate = () => {
  const { id } = useParams();
  return (
    <div className="flex flex-col">
      <NewsCreateForm id={id} />
    </div>
  );
};
