import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import { getDashboardReducer, getReportsReducer } from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.REPORTS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getReportsReducer(builder);
    getDashboardReducer(builder);
  }
});

export const reportsReducer = slice.reducer;
