import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

export const Pagination = ({ onPageChange, count, page }) => {
  const { t } = useTranslation();

  const pageCount = Math.ceil(count / 10);

  if (pageCount <= 1) return null;
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <ReactPaginate
        onPageChange={onPageChange}
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        pageCount={pageCount}
        renderOnZeroPageCount={false}
        pageRangeDisplayed={2}
        initialPage={page}
        className="flex flex-row items-center gap-1"
        previousClassName="text-sm"
        nextClassName="text-sm"
        pageClassName="w-6 h-6 flex justify-center items-center text-sm"
        activeClassName="bg-green-1 flex justify-center items-center rounded text-white"
      />
      <div className="flex flex-col gap-2">
        <span className="text-sm text-grey-3">
          {t('showing', {
            pageStart: count ? page * 10 + 1 : count,
            pageEnd: Math.min((page + 1) * 10, count),
            count
          })}
        </span>
      </div>
    </div>
  );
};
