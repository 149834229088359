import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getCompanyRoute } from 'utils';

import { asyncDeleteCompany } from 'store';

import { useModal } from 'hooks';

import { Link, Table } from 'components';

import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

import { YesNoModal } from '../';

export const CompaniesList = ({ list, reload, isLoading }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const Actions = ({ _id: id }) => {
    const { isModalOpened, handleOnCloseModal, handleOnOpenModal } = useModal();

    const removeCompany = async () => {
      await dispatch(asyncDeleteCompany(id));
      reload();
    };

    return (
      <div className="flex flex-row gap-2">
        {isModalOpened && (
          <YesNoModal
            onApprove={removeCompany}
            question={t('modal template - yes-no - company - question')}
            approveLabel={t('yes')}
            declineLabel={t('no')}
            handleClose={handleOnCloseModal}
            isOpen={isModalOpened}
            onDecline={handleOnCloseModal}
          />
        )}
        <Link href={getCompanyRoute(id)}>
          <img src={EditSrc} alt="edit" />
        </Link>
        <span
          onClick={handleOnOpenModal}
          className="cursor-pointer hover:underline text-error">
          <img src={DeleteSrc} alt="delete" />
        </span>
      </div>
    );
  };

  const cfg = [
    {
      key: `organization`,
      header: t('company name'),
      hClass: 'w-1/4'
    },
    {
      valueFunc: (products) =>
        products.length ? (
          products.map((product) => (
            <p className="text-sm m-0" key={`${product._id}-${product.name}`}>
              {product?.name}
            </p>
          ))
        ) : (
          <p>-</p>
        ),
      key: `products`,
      header: t('company products'),
      hClass: 'w-1/2'
    },
    {
      header: t('actions'),
      hClass: 'w-1/4',
      component: Actions,
      propsKeys: ['_id']
    }
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
