import cs from 'classnames';
import { forwardRef } from 'react';

export const TextArea = forwardRef(
  (
    {
      name,
      label,
      required,
      disabled = false,
      hideError = false,
      error,
      wrapperClassname,
      rows,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
          {label}
          {required && '*'}
        </label>
        <textarea
          disabled={disabled}
          ref={ref}
          className={cs(
            'text-sm px-2 py-1 border border-grey-6 rounded',
            'resize-none',
            { 'border-0': disabled }
          )}
          name={name}
          rows={disabled ? 0 : rows}
          {...props}
        />
        {!hideError && error && (
          <span className="block text-error text-xs">{error}</span>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
