import cs from 'classnames';
import { forwardRef } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';

export const FlagSelect = forwardRef(
  (
    {
      onChange,
      placeholder,
      required,
      label,
      wrapperClassname,
      name,
      countries,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <div className={cs('flex flex-col', wrapperClassname)}>
        <label className={cs('text-grey-3 text-sm')} htmlFor={name}>
          {label}
          {required && '*'}
        </label>
        <ReactFlagsSelect
          fullWidth
          countries={countries ?? ['CZ', 'US', 'RU']}
          customLabels={{ US: t('english'), CZ: t('czech'), RU: t('russian') }}
          selectedSize={14}
          className="!p-0 bg-white"
          selectButtonClassName="!px-2 !py-1 border border-grey-6 rounded RFS-button"
          placeholder={placeholder}
          onSelect={onChange}
          name={name}
          {...props}
        />
      </div>
    );
  }
);

FlagSelect.displayName = 'FlagSelect';
