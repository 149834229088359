import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Logo } from 'components/index.js';

export const SuccessfulPayment = () => {
  const [searchParams] = useSearchParams();

  const isSuccessful = searchParams.get('success') === 'true';

  const { t } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col items-center justify-center gap-8">
      <Logo />
      <span className="text-8xl font-bold text-green-1">
        {t(isSuccessful ? 'payment successful' : 'payment unsuccessful')}
      </span>
      <span className="font-bold text-2xl">
        {t(
          isSuccessful
            ? 'payment successful with stripe'
            : 'payment unsuccessful with stripe',
          {
            price: searchParams.get('price') ?? '0'
          }
        )}
      </span>
      <span className="text-xl">
        {t(
          isSuccessful
            ? 'payment successful with stripe description'
            : 'payment unsuccessful with stripe description'
        )}
      </span>
    </div>
  );
};
