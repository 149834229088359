import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './Button.jsx';

export const FileUploader = ({ handleFile }) => {
  const hiddenFileInput = useRef(null);

  const { t } = useTranslation();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button type="button" className="button-upload" onClick={handleClick}>
        {t('import from file')}
      </Button>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
    </>
  );
};
