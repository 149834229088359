import { Button, ButtonVariants } from 'components/index.js';

import DeleteSrc from 'svg/delete.svg';

import viewSrc from 'png/view.png';

export const CompanyPdfListActions = ({
  handleOnOpenModal,
  setPdfName,
  el
}) => {
  return (
    <div className="flex gap-2 items-center">
      <a
        target="_blank"
        href={`${import.meta.env.VITE_BASE_URL}/${el}`}
        rel="noreferrer">
        <img className="w-4 h-4" src={viewSrc} alt="view" />
      </a>
      <Button
        onClick={() => {
          handleOnOpenModal();
          setPdfName(el);
        }}
        className="!p-0"
        variant={ButtonVariants.CLEAR}>
        <img className="cursor-pointer w-4 h-4" src={DeleteSrc} alt="delete" />
      </Button>
    </div>
  );
};
