import { useTranslation } from 'react-i18next';

import { PartnerForm } from 'modules';

export const PartnerInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 p-4 m-4">
      <h3 className="text-2xl font-bold first-login-title">
        {t('partner - info')}
      </h3>
      <PartnerForm />
    </div>
  );
};
