import { useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { asyncUpdateCompany } from 'store/index.js';

import { Button, ButtonVariants } from 'components/index.js';

import DeleteSrc from 'svg/delete.svg';
import FileIconSrc from 'svg/file.svg';
import UploadIconUrl from 'svg/upload.svg';

export const CompanyPdfForm = ({ id }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const hiddenFileInput = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'documents',
    keyName: 'documentId'
  });

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleAddDocuments(files);
  };

  const handleAddDocuments = (fileList) => {
    const maxSize = 16 * 1024 * 1024;

    const uploadedFiles = Array.from(fileList);

    const validFiles = uploadedFiles.filter((file) => {
      if (file.type !== 'application/pdf') {
        toast.error(t('error - pdf - type', { name: file.name }));
        return false;
      }

      if (file.size > maxSize) {
        toast.error(t('error - pdf - size', { name: file.name }));
        return false;
      }
      return true;
    });

    const files = validFiles.map((file) => ({
      file
    }));

    append(files);

    if (hiddenFileInput.current?.value) {
      hiddenFileInput.current.value = '';
    }
  };

  const onSubmit = ({ documents }) => {
    const formData = new FormData();

    if (documents?.length) {
      documents.forEach(({ file }) => formData.append(file.name, file));
    }

    dispatch(asyncUpdateCompany({ id, payload: formData }))
      .unwrap()
      .then(() => remove());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <label
        htmlFor="file"
        className="relative border-dashed border-2 border-gray-500 w-full rounded-md flex items-center justify-center min-h-[15vh] cursor-pointer"
        onDragOver={(event) => event.preventDefault()}
        onDrop={handleDrop}>
        <input
          ref={hiddenFileInput}
          id="file"
          type="file"
          className="opacity-0 absolute -z-10 overflow-hidden w-full h-full"
          accept="application/pdf"
          multiple
          onChange={(event) => handleAddDocuments(event.target.files)}
        />
        {fields?.length ? (
          <div className="flex flex-wrap gap-4 items-center">
            {fields.map(({ documentId, file }, index) => (
              <div key={documentId}>
                <Controller
                  control={control}
                  name={`documents.${index}`}
                  render={() => (
                    <div className="flex flex-col relative items-center gap-2 border-2 p-2 rounded-md">
                      <img className="w-12 h-12" src={FileIconSrc} alt="file" />
                      {file.name}
                      <Button
                        type="button"
                        className="absolute -top-2 -right-2 !p-2 !bg-white hover:opacity-40 rounded-full"
                        variant={ButtonVariants.DECLINE}
                        onClick={(event) => {
                          remove(index);
                          event.preventDefault();
                        }}>
                        <img src={DeleteSrc} alt="delete" className="w-4 h-4" />
                      </Button>
                    </div>
                  )}
                />
              </div>
            ))}
          </div>
        ) : (
          <img className="w-12 h-12" src={UploadIconUrl} alt="upload" />
        )}
      </label>
      {errors['validation'] && (
        <span className="text-red-500 text-sm">
          {errors['validation']?.message}
        </span>
      )}
      {fields?.length > 0 && (
        <Button type="submit" className="self-start">
          {t('companies - add - documents')}
        </Button>
      )}
    </form>
  );
};
