import moment from 'moment/moment.js';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectOrderIsLoading, selectUser } from 'store/index.js';

import { Button, Toggle } from 'components/index.js';

import { Calculator } from 'modules/Orders/FormParts/Calculator.jsx';
import { Insurance } from 'modules/Orders/FormParts/Insurance.jsx';
import { Insured } from 'modules/Orders/FormParts/Insured.jsx';
import { Insurer } from 'modules/Orders/FormParts/Insurer.jsx';
import { OrderAdminActions } from 'modules/Orders/FormParts/OrderAdminActions.jsx';

export const OrderFields = ({
  watch,
  editing,
  setVoucherOrder,
  index,
  control,
  orderId,
  axaError,
  setAxaError,
  errors,
  isFormDisabled
}) => {
  const user = useSelector(selectUser);

  const { t } = useTranslation();
  const isLoading = useSelector(selectOrderIsLoading);

  const [prices, setPrices] = useState({
    full: 0,
    withDiscount: 0,
    comission: 0
  });

  const watchStartDate = watch(`clients.${editing}.insurance.start`);
  const parsedStartDate = moment(watchStartDate).startOf('day');

  const today = moment().startOf('day');

  const isInsurerLegal = watch(`clients.${editing}.insurer.isLegalEntity`);

  const onOrderSave = (data) => {
    setVoucherOrder(data);
  };

  return (
    <>
      <Insurer index={index} isFormDisabled={isFormDisabled} />
      {!isInsurerLegal && (
        <Controller
          defaultValue=""
          control={control}
          name={`clients.${index}.isSamePersons`}
          render={({ field: { value, onChange } }) => (
            <Toggle
              disabled={isFormDisabled}
              wrapperClassname="-mt-10"
              label={t('insured and insurer same persons')}
              checked={value}
              onChange={onChange}
            />
          )}
        />
      )}
      <Insured index={index} isFormDisabled={isFormDisabled} />
      <Insurance index={index} isFormDisabled={isFormDisabled} />
      <Calculator
        index={index}
        orderId={orderId}
        prices={prices}
        setPrices={setPrices}
        axaError={axaError}
        setAxaError={setAxaError}
        onOrderSave={onOrderSave}
        isFormDisabled={isFormDisabled}
      />
      <div className="flex gap-4 self-start -mt-8">
        {user?.role !== 'admin' && (
          <Button
            className="disabled:opacity-50 "
            disabled={Object.keys(errors).length || isLoading}>
            {t('save')}
          </Button>
        )}
        {parsedStartDate?.isBefore(today) && user?.role === 'admin' && (
          <OrderAdminActions
            clientIndex={index}
            orderId={orderId}
            startDate={watchStartDate}
          />
        )}
      </div>
    </>
  );
};
