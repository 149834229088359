export const SliceNames = {
  AUTH: 'auth',
  CLIENTS: 'clients',
  COMMISSIONS: 'commissions',
  COMPANIES: 'companies',
  NEWS: 'news',
  ORDERS: 'orders',
  PARTNERS: 'partners',
  REPORTS: 'reports',
  PRODUCTS: 'products',
  SETTINGS: 'settings',
  USERS: 'users',
  VOUCHERS: 'vouchers',
  LOGS: 'logs'
};
