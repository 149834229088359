import { api } from 'config';

class VouchersApi {
  constructor(url) {
    this.url = url;
  }

  getVouchers(queryParams, config) {
    return api.get(
      `${this.url}/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }`,
      config
    );
  }

  getVoucher(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  createVoucher(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  updateVoucher(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  deleteVoucher(id, config) {
    return api.delete(`${this.url}/${id}`, config);
  }
}

export const vouchersApi = new VouchersApi('/vouchers');
