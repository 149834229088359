import axios from 'axios';

class GovApi {
  constructor(url) {
    this.url = url;
  }

  getGovUser(id, config) {
    return axios.get(
      `https://ares.gov.cz/ekonomicke-subjekty-v-be/rest/${this.url}/${id}`,
      config
    );
  }
}

export const govApi = new GovApi('ekonomicke-subjekty');
