import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProductRoute } from 'utils';

import {
  asyncDeleteProduct,
  selectIsDeletedProduct,
  unsetIsDeletedProductAction
} from 'store';

import { useModal } from 'hooks';

import { Link, Table } from 'components';

import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

import { YesNoModal } from '../';

export const ProductsList = ({ list, reload, isLoading, setPage }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isDeletedProduct = useSelector(selectIsDeletedProduct);

  const Actions = ({ _id: id }) => {
    const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();

    const removeCompany = async () => {
      dispatch(asyncDeleteProduct(id))
        .unwrap()
        .then(() => setPage(0));
    };

    useEffect(() => {
      if (isDeletedProduct) {
        dispatch(unsetIsDeletedProductAction());
        reload();
      }
    }, [isDeletedProduct]);

    return (
      <div className="flex flex-row gap-2">
        {isModalOpened && (
          <YesNoModal
            onApprove={removeCompany}
            question={t('modal template - yes-no - product - question')}
            approveLabel={t('yes')}
            declineLabel={t('no')}
            handleClose={handleOnCloseModal}
            isOpen={isModalOpened}
            onDecline={handleOnCloseModal}
          />
        )}
        <Link href={getProductRoute(id)}>
          <img src={EditSrc} />
        </Link>
        <span
          onClick={handleOnOpenModal}
          className="cursor-pointer hover:underline text-error">
          <img src={DeleteSrc} />
        </span>
      </div>
    );
  };

  const cfg = [
    {
      key: `name`,
      header: t('product'),
      hClass: 'w-1/4'
    },
    {
      key: `company`,
      header: t('company'),
      hClass: 'w-1/2'
    },
    {
      header: t('actions'),
      hClass: 'w-1/4',
      component: Actions,
      propsKeys: ['_id']
    }
  ];

  return <Table config={cfg} items={list} isLoading={isLoading} />;
};
