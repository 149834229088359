import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

export const GraphChart = ({ data }) => {
  const [formattedData, setNewData] = useState([]);

  useEffect(() => {
    if (!data?.length) return;

    const allKeys = Array.from(
      new Set(data.reduce((keys, obj) => keys.concat(Object.keys(obj)), []))
    ).filter((key) => key !== 'date');

    const allDates = data.map((item) => item.date);

    const newData = allDates.map((date) => {
      const existingData = data.find((item) => item.date === date) || {};
      const newDataPoint = { date };

      allKeys.forEach((key) => {
        newDataPoint[key] =
          existingData[key] !== undefined ? existingData[key] : 0;
      });

      return newDataPoint;
    });

    const uniqueKeys = Array.from(
      new Set(newData.reduce((keys, obj) => keys.concat(Object.keys(obj)), []))
    ).filter((key) => key !== 'date');

    setNewData(uniqueKeys);
  }, [data]);

  if (!formattedData?.length) return null;
  return (
    <BarChart width={800} height={400} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      {formattedData.map((key, index) => (
        <Bar
          key={index}
          dataKey={key}
          stackId="stack"
          fill={`#${((Math.random() * 0xffffff) << 0).toString(16)}`}
        />
      ))}
    </BarChart>
  );
};
