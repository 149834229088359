import { useTranslation } from 'react-i18next';

import { AppRoutes } from 'constants/index.js';

import { Link, LinkVariants, Logo } from 'components';

import { ResetPasswordForm } from 'modules';

export const ResetPassword = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-4 items-center gap-16">
      <div className="flex flex-row justify-end w-full gap-4">
        <Link href={AppRoutes.LOGIN} variant={LinkVariants.SECONDARY_INVERTED}>
          {t('login - login')}
        </Link>
        <Link
          href={AppRoutes.PARTNER_REGISTER}
          variant={LinkVariants.SECONDARY}
        >
          {t('login - become partner')}
        </Link>
      </div>
      <Logo />
      <ResetPasswordForm />
    </div>
  );
};
