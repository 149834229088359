import { createSelector } from '@reduxjs/toolkit';

const selectSettingsState = (state) => state.settings;

export const selectSettings = createSelector(
  selectSettingsState,
  (state) => state.settings
);

export const selectPaymentTypes = createSelector(
  selectSettingsState,
  (state) => state.paymentTypes
);
