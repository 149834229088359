import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { asyncGetSelf } from 'store/index.js';

import { OrderForm } from 'modules';

export const Order = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetSelf());
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-4">{id && <OrderForm id={id} />}</div>
  );
};
