import { api } from 'config';

class SettingsApi {
  constructor(url) {
    this.url = url;
  }

  getSettings(config) {
    return api.get(`${this.url}/`, config);
  }

  getPaymentTypes(config) {
    return api.get(`${this.url}/payment-types`, config);
  }

  sendTestEmail(payload, config) {
    return api.post(`${this.url}/test`, payload, config);
  }

  updateSettings(payload, config) {
    return api.patch(`${this.url}/`, payload, config);
  }
}

export const settingsApi = new SettingsApi('/settings');
