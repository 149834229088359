import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EMAIL_OPTIONS } from 'constants/index.js';

import { asyncForgotPassword } from 'store';

import { Button, Input } from 'components';

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const onSubmit = async (data) => {
    dispatch(asyncForgotPassword(data));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[375px] flex flex-col gap-4">
      <span className="text-2xl">{t('login - reset password')}</span>

      <Input
        label={t('email')}
        error={errors['email']?.message}
        required
        {...register('email', EMAIL_OPTIONS)}
      />

      <Button>{t('send')}</Button>
    </form>
  );
};
