import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { transformStringToDate } from 'utils';

import { selectUser } from 'store/index.js';

export const useExpiresSoon = () => {
  const [expires, setExpires] = useState(null);
  const user = useSelector(selectUser);
  const expiresSoon = user?.expiresSoon;

  useEffect(() => {
    setExpires(
      expiresSoon?.length ? transformStringToDate(expiresSoon) : moment()
    );
  }, [expiresSoon]);

  return expires;
};
