import cs from 'classnames';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from 'constants/index.js';

import { getCompanyRoute, getProductCreateRoute } from 'utils';

import {
  asyncCreateCompany,
  asyncDeleteCompany,
  asyncUpdateCompany,
  selectCompany
} from 'store';

import {
  Button,
  Input,
  Link,
  LinkVariants,
  Select,
  TextArea
} from 'components';

import AddSrc from 'svg/add.svg';
import DeleteSrc from 'svg/delete.svg';
import EditSrc from 'svg/edit.svg';

export const CompanyForm = ({ id }) => {
  const [isEditing, setIsEditing] = useState(false);
  const company = useSelector(selectCompany);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    register,
    getValues,
    setError,
    reset,
    formState: { errors, defaultValues }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      organization: '',
      comment: '',
      axaId: '',
      types: []
    }
  });

  const { append, remove } = useFieldArray({
    control,
    name: 'types',
    rules: {
      validate: (value) => {
        if (!value.length) {
          setError('types', t('error - required'));
          return false;
        }

        return true;
      }
    }
  });

  const onSubmit = async (data) => {
    if (id) {
      await dispatch(asyncUpdateCompany({ id, payload: data }));
      navigate(AppRoutes.COMPANIES);
    } else {
      await dispatch(asyncCreateCompany(data))
        .unwrap()
        .then((data) => {
          const { _id: id } = data?.company || {};
          navigate(getCompanyRoute(id));
        });
    }
  };

  const options = [
    {
      value: 'Standard Turist – Turistický + pracovní pobyt',
      label: 'Standard Turist – Turistický + pracovní pobyt'
    },
    {
      value: 'Standard Student – Studijní pobyt',
      label: 'Standard Student – Studijní pobyt'
    },
    { value: 'Standard Matka', label: 'Standard Matka' },
    {
      value: 'INCOMING TOURIST/STUDENT pro dospělé',
      label: 'INCOMING TOURIST/STUDENT pro dospělé'
    },
    {
      value: 'INCOMING TOURIST/STUDENT pro děti',
      label: 'INCOMING TOURIST/STUDENT pro děti'
    },
    {
      value: 'INCOMING TOURIST/STUDENT pro infanty',
      label: 'INCOMING TOURIST/STUDENT pro infanty'
    },
    {
      value: 'INCOMING TOURIST/STUDENT pro seniory',
      label: 'INCOMING TOURIST/STUDENT pro seniory'
    },
    {
      value: 'INCOMING TOURIST/STUDENT pro studenty',
      label: 'INCOMING TOURIST/STUDENT pro studenty'
    },
    {
      value: 'INCOMING WORK pro dospělé',
      label: 'INCOMING TOURIST/STUDENT pro studenty'
    },
    {
      value: 'INCOMING WORK pro seniory',
      label: 'INCOMING WORK pro seniory'
    }
  ];

  const handleEditType = (index) => () => {
    const values = getValues();

    if (isEditing === false || values?.types?.[isEditing]['name'])
      setIsEditing(index);
  };
  const handleDeleteType = (index) => () => {
    remove(index);
  };
  const handleSaveType = (index) => (e) => {
    const values = getValues();

    if (values?.types?.[index]['name']) setIsEditing(false);
  };

  const handleChangeStatus = async () => {
    const status = company?.status === 'stopped' ? 'active' : 'stopped';

    dispatch(asyncUpdateCompany({ id, payload: { status } }));
  };

  const handleDelete = async () => {
    await dispatch(asyncDeleteCompany(id));
    navigate(AppRoutes.COMPANIES);
  };

  useEffect(() => {
    if (pathname !== AppRoutes.COMPANY_CREATE && id === company?._id) {
      reset(
        Object.keys(defaultValues).reduce((acc, key) => {
          return { ...acc, [key]: company[key] };
        }, {})
      );
    }
  }, [id, company?._id]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-3 gap-y-4 gap-x-2">
      <Input
        name="organization"
        label={t('company name')}
        error={errors['organization']?.message}
        required
        {...register('organization', { required: t('error - required') })}
      />
      <Input
        name="axaId"
        label={t('axa id')}
        required
        error={errors['axaId']?.message}
        {...register('axaId', {
          required: { value: true, message: t('error - required') }
        })}
      />
      <TextArea
        wrapperClassname="col-span-full"
        rows={8}
        name="comment"
        label={t('comment')}
        error={errors['comment']?.message}
        {...register('comment')}
      />

      <div className="col-span-full">
        <label className="text-grey-3 text-sm">
          {t('insurance types table')}*
        </label>
        <table className="table-auto w-full">
          <thead>
            <tr className="border text-left text-sm">
              <th className="w-1/4 text-left text-sm p-2">
                {t('insurance type')}
              </th>
              <th className="w-1/4 p-2">{t('code')}</th>
              <th className="w-1/4 p-2">{t('company insurance type')}</th>
              <th className="w-1/4 p-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {getValues()?.types?.map((_, index) => (
              <tr className="border" key={Math.random()}>
                <td className="max-w-1/2 text-left text-sm p-2">
                  <input
                    disabled={isEditing !== index}
                    className={cs('text-sm px-2 py-1', {
                      'border rounded': isEditing === index,
                      'border-grey-6':
                        !errors['types']?.[index]?.['name']?.message,
                      'border-error':
                        errors['types']?.[index]?.['name']?.message,
                      'border-0': isEditing !== index
                    })}
                    {...register(`types.${index}.name`, {
                      required: t('error - required')
                    })}
                  />
                </td>
                <td className="max-w-1/2 text-left text-sm p-2">
                  <input
                    disabled={isEditing !== index}
                    className={cs('text-sm px-2 py-1', {
                      'border rounded': isEditing === index,
                      'border-grey-6':
                        !errors['types']?.[index]?.['code']?.message,
                      'border-error':
                        errors['types']?.[index]?.['code']?.message,
                      'border-0': isEditing !== index
                    })}
                    {...register(`types.${index}.code`, {
                      required: t('error - required')
                    })}
                  />
                </td>
                <td className="max-w-1/2 text-left text-sm p-2">
                  <Controller
                    control={control}
                    defaultValue={options.map((c) => c.value)}
                    name={`types.${index}.finexCode`}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        disabled={isEditing !== index}
                        options={options}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </td>
                <td className="text-left text-sm p-2 ">
                  {isEditing === index && (
                    <Button onClick={handleSaveType(index)}>{t('save')}</Button>
                  )}
                  {isEditing !== index && (
                    <div className="flex flex-row">
                      <img
                        onClick={handleEditType(index)}
                        className="cursor-pointer pr-2"
                        src={EditSrc}
                      />
                      <img
                        onClick={handleDeleteType(index)}
                        className="cursor-pointer pl-2"
                        src={DeleteSrc}
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-left text-sm p-2">
                {isEditing === false && (
                  <img
                    src={AddSrc}
                    onClick={() => {
                      append({ name: '' });

                      setIsEditing(getValues()?.types?.length - 1);
                    }}
                    className="float-right cursor-pointer h-[30px]"
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {errors['types']?.message && (
          <span className="text-xs text-error">{errors['types']?.message}</span>
        )}
      </div>

      <div className="flex flex-row gap-4 items-center whitespace-nowrap">
        <Button type="submit">{t(id ? 'save' : 'create')}</Button>
        {id && (
          <Button type="button" onClick={handleChangeStatus}>
            {t(company?.status === 'stopped' ? 'activate' : 'stop')}
          </Button>
        )}
        {id && company?.status === 'active' && (
          <Link
            variant={LinkVariants.SECONDARY}
            href={getProductCreateRoute(id)}>
            {t('add product')}
          </Link>
        )}

        {id && company?.products?.length === 0 && (
          <Button type="button" onClick={handleDelete}>
            {t('delete')}
          </Button>
        )}
      </div>
    </form>
  );
};
