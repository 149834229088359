export const downloadXmlFile = (xmlString, fileName) => {
  const blob = new Blob([xmlString], { type: 'application/xml' });

  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = fileName || 'download.xml';

  document.body.appendChild(downloadLink);

  downloadLink.click();

  document.body.removeChild(downloadLink);
};
