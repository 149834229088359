import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getNewsEditRoute } from 'utils';

import { asyncDeleteNews } from 'store';

import { useModal } from 'hooks';

import { Link, Table } from 'components';

import { YesNoModal } from '..';

export const AdminNews = ({ list }) => {
  const { i18n, t } = useTranslation();

  const lng = i18n.language.split('-')[0]?.toLocaleUpperCase();

  const dispatch = useDispatch();

  let _lng = '';
  switch (lng) {
    case 'EN':
    case 'UK':
    case 'US':
      _lng = 'US';
      break;
    case 'CZ':
      _lng = 'CZ';
      break;
    default:
      _lng = 'RU';
      break;
  }

  const Actions = ({ _id: id }) => {
    const { isModalOpened, handleOnOpenModal, handleOnCloseModal } = useModal();

    const removeNews = () => {
      dispatch(asyncDeleteNews(id));
    };

    return (
      <div className="flex flex-row gap-2">
        {isModalOpened && (
          <YesNoModal
            question={t('modal template - yes-no - news - question')}
            approveLabel={t('yes')}
            declineLabel={t('no')}
            handleClose={handleOnCloseModal}
            isOpen={isModalOpened}
            onApprove={removeNews}
            onDecline={handleOnCloseModal}
          />
        )}
        <Link href={getNewsEditRoute(id)}>{t('edit')}</Link> |{' '}
        <span
          onClick={handleOnOpenModal}
          className="cursor-pointer hover:underline text-error"
        >
          {t('delete')}
        </span>
      </div>
    );
  };

  const cfg = [
    {
      key: `title${_lng}`,
      header: t('newsletter title'),
      hClass: 'w-1/4'
    },
    {
      valueFunc: (_, el) =>
        `${el?.author?.firstName || ''} ${el?.author?.lastName || ''}`,
      key: `author`,
      header: t('newsletter author'),
      hClass: 'w-1/4'
    },
    {
      valueFunc: (date) => moment(date).format('DD.MM.yyyy'),
      key: `date`,
      header: t('creation date'),
      hClass: 'w-1/4'
    },
    {
      header: t('actions'),
      hClass: 'w-1/4',
      component: Actions,
      propsKeys: ['_id']
    }
  ];

  return <Table items={list} config={cfg} missingText={t('missing items')} />;
};
