import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams] = useSearchParams();
  const queryPage = +searchParams.get('page');

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(queryPage - (queryPage > 0 ? 1 : 0));

  return { count, page, setCount, setPage };
};
