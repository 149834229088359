import { api } from 'config';

class ProductsApi {
  constructor(url) {
    this.url = url;
  }

  getProducts(queryParams, config) {
    return api.get(
      `${this.url}/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }`,
      config
    );
  }

  getProduct(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  createProduct(payload, config) {
    return api.post(`${this.url}/create`, payload, config);
  }

  updateProduct(id, payload, config) {
    return api.patch(`${this.url}/${id}`, payload, config);
  }

  deleteProduct(id, config) {
    return api.delete(`${this.url}/${id}`, config);
  }

  importFromCsv(id, data, config) {
    return api.post(`${this.url}/import-from-xls/${id}`, data, config);
  }
}

export const productsApi = new ProductsApi('/products');
