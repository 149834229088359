import { withLoadingReducer } from 'utils';

import {
  asyncChangePassword,
  asyncCreateUser,
  asyncGetSelf,
  asyncGetUser,
  asyncGetUsers,
  asyncUpdateSelf,
  asyncUpdateUser,
  setSelf,
  unsetIsAuthorized,
  unsetIsFirstLogin,
  unsetSelf
} from './actions.js';

export const getUsersReducer = (builder) => {
  builder
    .addCase(asyncGetUsers.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload.list;
      state.usersCount = payload.count;
    })
    .addCase(asyncGetUsers.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getSelfReducer = (builder) => {
  builder
    .addCase(asyncGetSelf.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetSelf.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload.user;
      state.isAuthorized = true;
      state.isFirstLogin = payload.user.isFirstLogin;
      state.productsByCompanies = payload.productsByCompanies;
    })
    .addCase(asyncGetSelf.rejected, (state) => {
      state.isLoading = false;
      state.isAuthorized = false;
    });
};

export const setSelfReducer = (builder) => {
  builder.addCase(setSelf, (state, { payload }) => {
    state.user = payload.user;
    state.isAuthorized = true;
    state.isFirstLogin = payload.user.isFirstLogin;
    state.productsByCompanies = payload.productsByCompanies;
  });
};

export const unsetSelfReducer = (builder) => {
  builder.addCase(unsetSelf, (state) => {
    state.user = undefined;
    state.productsByCompanies = undefined;
  });
};

export const unsetIsFirstLoginReducer = (builder) => {
  builder.addCase(unsetIsFirstLogin, (state) => {
    state.isFirstLogin = false;
  });
};

export const updateSelfReducer = (builder) => {
  builder.addCase(asyncUpdateSelf.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncUpdateSelf.fulfilled, (state, { payload }) => {
    state.user = payload;
    state.isAuthorized = true;
    state.isFirstLogin = payload.isFirstLogin;
    state.productsByCompanies = payload.productsByCompanies;
  });

  builder.addCase(asyncUpdateSelf.rejected, (state) => {
    state.isLoading = false;
  });
};

export const updateUserReducer = (builder) => {
  builder
    .addCase(asyncUpdateUser.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncUpdateUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.roleUser = payload;
    })
    .addCase(asyncUpdateUser.rejected, (state) => {
      state.isLoading = false;
    });
};
export const createUserReducer = withLoadingReducer(asyncCreateUser);
export const getUserReducer = (builder) => {
  builder
    .addCase(asyncGetUser.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.roleUser = payload;
    })
    .addCase(asyncGetUser.rejected, (state) => {
      state.isLoading = false;
    });
};

export const changePasswordReducer = (builder) => {
  builder
    .addCase(asyncChangePassword.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncChangePassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
      state.isAuthorized = true;
      state.isFirstLogin = payload.isFirstLogin;
    })
    .addCase(asyncChangePassword.rejected, (state) => {
      state.isLoading = false;
    });
};

export const unsetIsAuthorizedReducer = (builder) => {
  builder.addCase(unsetIsAuthorized, (state) => {
    state.isAuthorized = false;
  });
};
