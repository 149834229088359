import { createSlice } from '@reduxjs/toolkit';

import { SliceNames } from 'constants/index.js';

import {
  approveCommissionPaymentReducer,
  cancelCommissionPaymentReducer,
  downloadApprovePaymentReducer,
  downloadCommissionListReducer,
  downloadFactureReducer,
  downloadStornoCommissionListReducer,
  getCommissionReducer,
  getCommissionsReducer,
  unsetCommissionReducer
} from './reducers.js';

const initialState = {
  isLoading: false
};

const slice = createSlice({
  name: SliceNames.COMMISSIONS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCommissionsReducer(builder);
    getCommissionReducer(builder);
    unsetCommissionReducer(builder);
    approveCommissionPaymentReducer(builder);
    cancelCommissionPaymentReducer(builder);
    downloadApprovePaymentReducer(builder);
    downloadCommissionListReducer(builder);
    downloadStornoCommissionListReducer(builder);
    downloadFactureReducer(builder);
  }
});

export const commissionsReducer = slice.reducer;
