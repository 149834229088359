export const getCountriesArr = (lang) => {
  let countries;
  const czCountries = [
    'Kazachstán',
    'Uzbekistán',
    'Ukrajina',
    'Afghánistán',
    'Albánie',
    'Alžírsko',
    'Andorra',
    'Angola',
    'Antigua a Barbuda',
    'Argentina',
    'Arménie',
    'Austrálie',
    'Ázerbájdžán',
    'Bahamy',
    'Bahrajn',
    'Bangladéš',
    'Barbados',
    'Belgie',
    'Belize',
    'Bělorusko',
    'Benin',
    'Bhútán',
    'Bolívie',
    'Bosna a Hercegovina',
    'Botswana',
    'Brazílie',
    'Brunej',
    'Bulharsko',
    'Burkina Faso',
    'Burundi',
    'Čad',
    'Černá Hora',
    'Česká republika',
    'Čína',
    'Chile',
    'Chorvatsko',
    'Cyprus',
    'Dánsko',
    'Demokratická republika Kongo',
    'Dominika',
    'Dominikánská republika',
    'Džibutsko',
    'Egypt',
    'Ekvádor',
    'Eritrea',
    'Estonsko',
    'Etiopie',
    'Fidži',
    'Filipíny',
    'Finsko',
    'Francie',
    'Gabon',
    'Gambie',
    'Ghana',
    'Grenada',
    'Gruzie',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Indie',
    'Indonésie',
    'Irák',
    'Irán',
    'Irsko',
    'Island',
    'Itálie',
    'Izrael',
    'Jamajka',
    'Japonsko',
    'Jemen',
    'Jordánsko',
    'Jihoafrická republika',
    'Jižní Korea',
    'Jižní Súdán',
    'Kambodža',
    'Kamerun',
    'Kanada',
    'Katar',
    'Keňa',
    'Kiribati',
    'Kolumbie',
    'Komory',
    'Kongo',
    'Kosovo',
    'Kostarika',
    'Kuba',
    'Kuvajt',
    'Kypr',
    'Kyrgyzstán',
    'Laos',
    'Lesotho',
    'Libanon',
    'Libérie',
    'Libye',
    'Lichtenštejnsko',
    'Litva',
    'Lotyšsko',
    'Lucembursko',
    'Madagaskar',
    'Maďarsko',
    'Makedonie',
    'Malajsie',
    'Malawi',
    'Maledivy',
    'Mali',
    'Malta',
    'Maroko',
    'Marshallovy ostrovy',
    'Mauricius',
    'Mauritánie',
    'Mexiko',
    'Mikronésie',
    'Moldavsko',
    'Monako',
    'Mongolsko',
    'Mosambik',
    'Myanmar',
    'Namibie',
    'Nauru',
    'Nepál',
    'Německo',
    'Niger',
    'Nigérie',
    'Nikaragua',
    'Niue',
    'Nizozemsko',
    'Norsko',
    'Nový Zéland',
    'Omán',
    'Pákistán',
    'Palau',
    'Panama',
    'Papua-Nová Guinea',
    'Paraguay',
    'Peru',
    'Polsko',
    'Portugalsko',
    'Rakousko',
    'Rovníková Guinea',
    'Rumunsko',
    'Rusko',
    'Rwanda',
    'Řecko',
    'Salvador',
    'Samoa',
    'San Marino',
    'Saúdská Arábie',
    'Senegal',
    'Severní Korea',
    'Seychely',
    'Sierra Leone',
    'Singapur',
    'Slovensko',
    'Slovinsko',
    'Somálsko',
    'Spojené arabské emiráty',
    'Spojené království',
    'Spojené státy americké',
    'Srbsko',
    'Srí Lanka',
    'Středoafrická republika',
    'Súdán',
    'Surinam',
    'Svatá Lucie',
    'Svatý Kryštof a Nevis',
    'Svatý Tomáš a Princův ostrov',
    'Svatý Vincenc a Grenadiny',
    'Svazijsko',
    'Sýrie',
    'Šalamounovy ostrovy',
    'Španělsko',
    'Švédsko',
    'Švýcarsko',
    'Tádžikistán',
    'Tanzanie',
    'Thajsko',
    'Togo',
    'Tonga',
    'Trinidad a Tobago',
    'Tunisko',
    'Turecko',
    'Turkmenistán',
    'Tuvalu',
    'Uganda',
    'Uruguay',
    'Vanuatu',
    'Vatikán',
    'Venezuela',
    'Vietnam',
    'Východní Timor',
    'Zambie',
    'Zimbabwe'
  ];

  switch (lang) {
    case 'CZ':
      countries = czCountries;
      break;
    default:
      countries = [
        'Kazakhstan',
        'Uzbekistan',
        'Ukraine',
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belgium',
        'Belize',
        'Belarus',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Chad',
        'Montenegro',
        'Czech Republic',
        'China',
        'Chile',
        'Croatia',
        'Cyprus',
        'Denmark',
        'Democratic Republic of the Congo',
        'Dominica',
        'Dominican Republic',
        'Djibouti',
        'Egypt',
        'Ecuador',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Philippines',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Ghana',
        'Grenada',
        'Georgia',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'India',
        'Indonesia',
        'Iraq',
        'Iran',
        'Ireland',
        'Iceland',
        'Italy',
        'Israel',
        'Jamaica',
        'Japan',
        'Yemen',
        'Jordan',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Qatar',
        'Kenya',
        'Kiribati',
        'Colombia',
        'Comoros',
        'Congo',
        'Kosovo',
        'Costa Rica',
        'Cuba',
        'Kuwait',
        'Cyprus',
        'Kyrgyzstan',
        'Laos',
        'Lesotho',
        'Lebanon',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Latvia',
        'Luxembourg',
        'Madagascar',
        'Hungary',
        'Macedonia',
        'Malaysia',
        'Malawi',
        'Maldives',
        'Mali',
        'Malta',
        'Morocco',
        'Marshall Islands',
        'Mauritius',
        'Mauritania',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Mozambique',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Germany',
        'Niger',
        'Nigeria',
        'Nicaragua',
        'Niue',
        'Netherlands',
        'Norway',
        'New Zealand',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Austria',
        'Equatorial Guinea',
        'Romania',
        'Russia',
        'Rwanda',
        'Greece',
        'El Salvador',
        'Samoa',
        'San Marino',
        'Saudi Arabia',
        'Senegal',
        'North Korea',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Somalia',
        'United Arab Emirates',
        'United Kingdom',
        'United States of America',
        'Serbia',
        'Sri Lanka',
        'Central African Republic',
        'Sudan',
        'Suriname',
        'Saint Lucia',
        'Saint Kitts and Nevis',
        'São Tomé and Príncipe',
        'Saint Vincent and the Grenadines',
        'Swaziland',
        'Syria',
        'Solomon Islands',
        'Spain',
        'Sweden',
        'Switzerland',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Uruguay',
        'Vanuatu',
        'Vatican',
        'Venezuela',
        'Vietnam',
        'East Timor',
        'Zambia',
        'Zimbabwe'
      ];
      break;
  }

  return countries.map((elem, index) => ({
    value: czCountries[index],
    label: elem
  }));
};
