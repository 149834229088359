import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { EMAIL_OPTIONS } from 'constants/index.js';

import { validateEmail } from 'validators';

import { asyncSendTestEmail, asyncUpdateSettings } from 'store';

import { Button, CreatableSelect, Input, Radio } from 'components';

export const EmailSettingsForm = ({ settings }) => {
  const [factureOptions, setFactureOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);

  const dispatch = useDispatch();

  const {
    getValues,
    register,
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: async () => {
      const settingsData = {
        mainEmail: '',
        crypt: 'none',
        senderName: '',
        smtpAuth: true,
        smtpPassword: '',
        smtpUsername: '',
        smtpHost: '',
        smtpPort: '',
        testEmail: '',
        factureEmail: [],
        orderEmail: []
      };

      if (settings) {
        Object.keys(settingsData).forEach((key) => {
          if (settings[key]) {
            settingsData[key] = settings[key];
            if (key === 'orderEmail' || key === 'factureEmail') {
              settingsData[key] = populateOptions(key, settings[key]);
            }
          }
        });
      }

      return settingsData;
    }
  });
  const { t } = useTranslation();

  const handleSendTestMail = () => {
    const testEmail = watch('testEmail');

    if (!testEmail) return;

    const val = { email: getValues().testEmail };

    if (!isValidNewOption(val.email)) return;

    dispatch(asyncSendTestEmail(getValues()));
  };

  const populateOptions = (key, data) => {
    const setOptions =
      key === 'factureEmail' ? setFactureOptions : setOrderOptions;

    const formattedData = data.map((value) => {
      if (value) {
        return {
          label: value,
          value
        };
      }
    });
    setOptions(formattedData);
    return formattedData;
  };

  const isValidNewOption = (inputValue) => {
    return validateEmail(t).value.test(inputValue);
  };

  const formatSelectData = (values, fieldName) => {
    if (values && values[0] !== null) {
      return values.map((email) => {
        if (email instanceof Object) {
          return email.value;
        }
        return email;
      });
    }
    setError(fieldName, {
      type: 'manual',
      message: t('error - minimal items')
    });
  };

  const onSubmit = (data) => {
    const { factureEmail, orderEmail, ...rest } = data;

    const formattedFactureEmail = formatSelectData(
      factureEmail,
      'factureEmail'
    );

    const formattedOrderEmail = formatSelectData(orderEmail, 'orderEmail');

    dispatch(
      asyncUpdateSettings({
        factureEmail: formattedFactureEmail,
        orderEmail: formattedOrderEmail,
        ...rest
      })
    );
  };

  const cryptOptions = [
    { value: 'off', label: t('no') },
    { value: 'SSL', label: t('ssl') },
    { value: 'TLS', label: t('tls') }
  ];

  const authOptions = [
    { value: 'No', label: t('no') },
    { value: 'Yes', label: t('yes') }
  ];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-2 gap-y-4 gap-x-16">
      <Input
        required
        name="mainEmail"
        label={t('main email')}
        error={errors['mainEmail']?.message}
        {...register('mainEmail', EMAIL_OPTIONS)}
      />
      <Radio
        options={cryptOptions}
        name="crypt"
        label={t('cipher')}
        error={errors['crypt']?.message}
        {...register('crypt')}
      />
      <Input
        required
        name="senderName"
        label={t('sender name')}
        error={errors['senderName']?.message}
        {...register('senderName', {
          required: t('error - required')
        })}
      />
      <Radio
        options={authOptions}
        name="smtpAuth"
        label={t('smtp authentication')}
        error={errors['smtpAuth']?.message}
        {...register('smtpAuth')}
      />
      <Input
        required
        name="smtpHost"
        label={t('smtp host')}
        error={errors['smtpHost']?.message}
        {...register('smtpHost', {
          required: t('error - required')
        })}
      />
      <Input
        required
        name="smtpUsername"
        label={t('smtp username')}
        error={errors['smtpUsername']?.message}
        {...register('smtpUsername', {
          required: t('error - required')
        })}
      />
      <Input
        required
        name="smtpPort"
        label={t('smtp port')}
        error={errors['smtpPort']?.message}
        {...register('smtpPort', {
          required: t('error - required')
        })}
      />
      <Input
        type="password"
        required
        name="smtpPassword"
        label={t('smtp password')}
        error={errors['smtpPassword']?.message}
        {...register('smtpPassword', {
          required: t('error - required')
        })}
      />
      <hr className="col-span-full" />
      <Controller
        name="orderEmail"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { value, onChange } }) => (
          <CreatableSelect
            isMulti
            name="orderEmail"
            options={orderOptions}
            required
            error={errors['orderEmail']?.message}
            label={t('orders email')}
            value={value?.length ? value : []}
            onChange={onChange}
            isValidNewOption={isValidNewOption}
          />
        )}
      />
      <Controller
        name="factureEmail"
        control={control}
        rules={{ required: t('error - required') }}
        render={({ field: { value, onChange } }) => (
          <CreatableSelect
            isMulti
            name="factureEmail"
            options={factureOptions}
            required
            error={errors['factureEmail']?.message}
            label={t('factures email')}
            value={value?.length ? value : []}
            onChange={onChange}
            isValidNewOption={isValidNewOption}
          />
        )}
      />
      <Input
        name="testEmail"
        label={t('test email')}
        error={errors['testEmail']?.message}
        {...register('testEmail', {
          pattern: validateEmail(t)
        })}
      />
      <Button
        className="justify-self-start self-end"
        onClick={handleSendTestMail}
        type="button">
        {t('send test mail')}
      </Button>
      <Button className="justify-self-start" type="submit">
        {t('save')}
      </Button>
    </form>
  );
};
