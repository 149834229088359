import { withLoadingReducer } from 'utils';

import {
  asyncCreatePartner,
  asyncGetDownloadCsv,
  asyncGetPartner,
  asyncGetPartnerProductsCompanies,
  asyncGetPartnerVouchers,
  asyncGetPartners,
  asyncUpdatePartner,
  unsetIsUpdatedPartnerAction
} from './actions.js';

export const getPartnersReducer = (builder) => {
  builder
    .addCase(asyncGetPartners.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetPartners.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.partners = payload.list;
      state.partnersCount = payload.count;
    })
    .addCase(asyncGetPartners.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getPartnerReducer = (builder) => {
  builder
    .addCase(asyncGetPartner.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetPartner.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.partner = payload;
    })
    .addCase(asyncGetPartner.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getPartnerProductsAndCompaniesReducer = (builder) => {
  builder
    .addCase(asyncGetPartnerProductsCompanies.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(
      asyncGetPartnerProductsCompanies.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.products = payload.products;
        state.companies = payload.companies;
      }
    )
    .addCase(asyncGetPartnerProductsCompanies.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getPartnerVouchersReducer = (builder) => {
  builder
    .addCase(asyncGetPartnerVouchers.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetPartnerVouchers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.vouchers = payload;
    })
    .addCase(asyncGetPartnerVouchers.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getDownloadCsvReducer = withLoadingReducer(asyncGetDownloadCsv);

export const createPartnerReducer = withLoadingReducer(asyncCreatePartner);

export const updatePartnerReducer = withLoadingReducer(asyncUpdatePartner);

export const unsetIsUpdatedPartnerReducer = (builder) => {
  builder.addCase(unsetIsUpdatedPartnerAction, (state) => {
    state.isUpdatedPartner = false;
  });
};
