import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';

import { useClickOutside } from 'hooks';

import { Card } from './Card';

export const Modal = ({ isOpen, handleClose, children }) => {
  const cardRef = useRef(null);
  useClickOutside(cardRef, handleClose);

  if (!isOpen) return null;
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            ease: 'linear',
            duration: 0.5
          }
        }}
        exit={{
          opacity: 0,
          transition: {
            ease: 'linear',
            duration: 0.5
          }
        }}
        className="fixed z-50 bg-overlay top-0 left-0 w-screen h-screen flex items-center justify-center"
      >
        <Card ref={cardRef} className="flex flex-col relative z-40 px-0 py-0">
          {children}
        </Card>
      </motion.div>
    </AnimatePresence>
  );
};
