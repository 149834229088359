import { api } from 'config';

class CommissionsApi {
  constructor(url) {
    this.url = url;
  }

  getCommissions(queryParams, config) {
    return api.get(
      `${this.url}/list/?page=${queryParams?.page ?? ''}&search=${
        queryParams?.search ?? ''
      }&date=${queryParams?.date ?? ''}`,
      config
    );
  }

  getCommission(id, config) {
    return api.get(`${this.url}/${id}`, config);
  }

  approveCommissionPayment(id, payload, config) {
    return api.post(
      `${this.url}/approve-facture-payment/${id}`,
      payload,
      config
    );
  }

  cancelCommissionPayment(id, config) {
    return api.post(`${this.url}/cancel-facture-payment/${id}`, {}, config);
  }

  downloadPaymentApprove(id, config) {
    return api.get(`${this.url}/download-payment-approve/${id}`, {
      responseType: 'blob',
      ...config
    });
  }

  downloadCommissionList(id, config) {
    return api.get(`${this.url}/download-provizni-list/${id}`, {
      responseType: 'blob',
      ...config
    });
  }
  downloadStornoCommissionList(id, config) {
    return api.get(`${this.url}/download-storno-provizni-list/${id}`, {
      responseType: 'blob',
      ...config
    });
  }

  downloadFacture(id, config) {
    return api.get(`${this.url}/download-facture/${id}`, {
      responseType: 'blob',
      ...config
    });
  }
}

export const commissionsApi = new CommissionsApi('/comissions');
