import { withLoadingReducer } from 'utils';

import {
  asyncCreateVoucher,
  asyncDeleteVoucher,
  asyncGetVoucher,
  asyncGetVouchers,
  asyncUpdateVoucher
} from './actions.js';

export const getVouchersReducer = (builder) => {
  builder
    .addCase(asyncGetVouchers.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetVouchers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.vouchers = payload.list;
      state.vouchersCount = payload.count;
    })
    .addCase(asyncGetVouchers.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getVoucherReducer = (builder) => {
  builder
    .addCase(asyncGetVoucher.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetVoucher.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.voucher = payload;
    })
    .addCase(asyncGetVoucher.rejected, (state) => {
      state.isLoading = false;
    });
};

export const createVoucherReducer = withLoadingReducer(asyncCreateVoucher);
export const updateVoucherReducer = withLoadingReducer(asyncUpdateVoucher);
export const deleteVoucherReducer = withLoadingReducer(asyncDeleteVoucher);
