import { AppRoutes } from 'constants/index.js';

import { getCashiersRoute } from 'utils';

import { RolesList } from 'modules';

export const Cashiers = () => {
  return (
    <RolesList
      roles={['cashier']}
      createRoute={AppRoutes.CASHIER_CREATE}
      getCustomRoute={getCashiersRoute}
    />
  );
};
