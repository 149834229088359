import { createSelector } from '@reduxjs/toolkit';

const selectProductsState = (state) => state.products;

export const selectProducts = createSelector(
  selectProductsState,
  (state) => state.products
);

export const selectProduct = createSelector(
  selectProductsState,
  (state) => state.product
);

export const selectProductsCount = createSelector(
  selectProductsState,
  (state) => state.productsCount
);

export const selectIsUpdatedProduct = createSelector(
  selectProductsState,
  (state) => state.isUpdatedProduct
);

export const selectIsDeletedProduct = createSelector(
  selectProductsState,
  (state) => state.isDeletedProduct
);

export const selectProductsIsLoading = createSelector(
  selectProductsState,
  (state) => state.isLoading
);
