import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilter, useRandomColor } from 'hooks';

import { RoundChart, Select } from 'components';

export const ReportsPieChart = ({
  selectedView,
  companyNames,
  companiesData
}) => {
  const { t } = useTranslation();

  const [filteredData, setFilteredData] = useState([]);

  const {
    filterOptions: companyOptions,
    setFilterOptions: setCompanyOptions,
    selectedFilter: selectedCompany,
    setSelectedFilter: setSelectedCompany
  } = useFilter([], { value: companyNames[0], index: 0 });

  const colors = useRandomColor(filteredData);

  const handleOnCompanyChange = (e) => {
    const indexOfCompany = companyNames.indexOf(e.target.value);

    setSelectedCompany({ value: e.target.value, index: indexOfCompany });
  };

  const calculateProductsByCompany = () => {
    let updatedProductCount = {};

    companiesData[selectedCompany.index].forEach((company) => {
      let product = company.product;

      if (updatedProductCount[product]) {
        updatedProductCount[product] +=
          selectedView === 'shareByMoney' ? +company.full : 1;
      } else {
        updatedProductCount[product] =
          selectedView === 'shareByMoney' ? +company.full : 1;
      }
    });

    return updatedProductCount;
  };

  useEffect(() => {
    if (!companiesData?.length) return;

    setCompanyOptions(
      companyNames.map((company) => ({ value: company, label: company }))
    );
  }, []);

  useEffect(() => {
    if (!companiesData?.length) return;

    let formattedData;
    setFilteredData(null);
    switch (selectedView) {
      case 'shareByCompany': {
        formattedData = companiesData.map((data, index) => ({
          name: companyNames[index],
          value: data.length
        }));
        break;
      }
      case 'shareOfMoney': {
        formattedData = companiesData.map((data, index) => ({
          name: companyNames[index],
          value: data.reduce((acc, curr) => acc + +curr.full, 0)
        }));
        break;
      }
      case 'shareWithinCompany': {
        const data = calculateProductsByCompany();
        formattedData = Object.keys(data).map((productName) => ({
          name: productName,
          value: data[productName]
        }));
        break;
      }
      case 'shareByMoney': {
        const data = calculateProductsByCompany();
        formattedData = Object.keys(data).map((productName) => ({
          name: productName,
          value: data[productName]
        }));
        break;
      }
      default: {
        formattedData = null;
        break;
      }
    }
    setFilteredData(formattedData);
  }, [selectedView, selectedCompany, companiesData]);

  if (!filteredData?.length || !colors?.length) {
    return (
      <p className="col-span-4 flex items-center justify-center">
        {t('missing items')}
      </p>
    );
  }
  return (
    <>
      {(selectedView === 'shareWithinCompany' ||
        selectedView === 'shareByMoney') &&
        companyOptions.length > 0 && (
          <Select
            defaultValue={companyOptions[0]}
            options={companyOptions}
            onChange={handleOnCompanyChange}
            wrapperClassname="col-start-3 row-start-1"
          />
        )}
      <RoundChart
        className="col-span-4 row-start-2"
        data={filteredData}
        colors={colors}
      />
    </>
  );
};
