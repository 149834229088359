import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useBeforeRouteChange = (beforeUnloadHandler) => {
  const { pathname } = useLocation();
  useEffect(() => {
    return () => {
      beforeUnloadHandler();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
