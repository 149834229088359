import { withLoadingReducer } from 'utils';

import {
  asyncApproveCommissionPayment,
  asyncCancelCommissionPayment,
  asyncDownloadApprovePayment,
  asyncDownloadCommissionList,
  asyncDownloadFacture,
  asyncDownloadStornoCommissionList,
  asyncGetCommission,
  asyncGetCommissions,
  unsetCommission
} from './actions.js';

export const getCommissionsReducer = (builder) => {
  builder
    .addCase(asyncGetCommissions.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetCommissions.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.commissions = payload.list;
      state.commissionsCount = payload.count;
    })
    .addCase(asyncGetCommissions.rejected, (state) => {
      state.isLoading = false;
    });
};

export const getCommissionReducer = (builder) => {
  builder
    .addCase(asyncGetCommission.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncGetCommission.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.commission = payload;
    })
    .addCase(asyncGetCommission.rejected, (state) => {
      state.isLoading = false;
    });
};

export const approveCommissionPaymentReducer = withLoadingReducer(
  asyncApproveCommissionPayment
);

export const cancelCommissionPaymentReducer = withLoadingReducer(
  asyncCancelCommissionPayment
);

export const downloadCommissionListReducer = withLoadingReducer(
  asyncDownloadCommissionList
);
export const downloadStornoCommissionListReducer = withLoadingReducer(
  asyncDownloadStornoCommissionList
);

export const downloadApprovePaymentReducer = withLoadingReducer(
  asyncDownloadApprovePayment
);

export const downloadFactureReducer = withLoadingReducer(asyncDownloadFacture);

export const unsetCommissionReducer = (builder) => {
  builder.addCase(unsetCommission, (state) => {
    state.commission = null;
  });
};
