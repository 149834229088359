export const withLoadingReducer = (asyncAction) => (builder) => {
  builder
    .addCase(asyncAction.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(asyncAction.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(asyncAction.rejected, (state) => {
      state.isLoading = false;
    });
};
